import { action, observable, runInAction } from "mobx";
import { DomainStore } from "./domainStore";
import _ from "lodash";
import { APIQueueFactory } from "domain/apiQueue";
import { ILazyObservable, lazyObservable } from "domain/helpers/lazyLoad";
import { toastError } from "domain/errorHandling/toaster";
import enchargeAPI from "./persistence/enchargeAPI";
import { handleMutationSuccess } from "./react-query/handleQueryResponse";
import redirect from "domain/helpers/redirect";

export class FormsStore {
  rootStore: DomainStore;
  constructor(rootStore: DomainStore) {
    this.rootStore = rootStore;
  }

  queue = APIQueueFactory({ name: "formsManagement", limit: 1 });

  @observable
  isLoading = false;

  @observable
  forms: ILazyObservable<
    (IForm & {
      metric?: IFormMetric;
    })[]
  > = lazyObservable<
    (IForm & {
      metric?: IFormMetric;
    })[]
  >((sink, onError) => {
    enchargeAPI
      .getForms()
      .then((res) => {
        sink(observable(res.forms));
      })
      .catch((e) => {
        toastError({
          message: "Error while loading forms.",
          extra: e,
        });
        onError(e);
        throw e;
      });
  });

  @action
  setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  @action
  async create(form: Partial<IForm>, folderId?: string) {
    try {
      this.setIsLoading(true);
      const { item: createdForm } = await enchargeAPI.createForm(form);
      runInAction(() =>
        this.forms.current().splice(0, 0, observable(createdForm))
      );
      // Add to current folder
      const currentFolderId = folderId
        ? this.rootStore.foldersStore.getFolderById(folderId)?.id
        : this.rootStore.foldersStore.getSelectedFolder("forms");
      if (currentFolderId) {
        this.rootStore.foldersStore.pushItemToFolder({
          folderId: currentFolderId,
          itemId: createdForm.id,
          type: "forms",
        });
      }
      handleMutationSuccess(`${createdForm.name} created successfully.`);
      redirect(`/forms/${createdForm.id}`);
    } catch (e) {
      toastError({
        message: "Error while creating form.",
        extra: e,
      });
      this.isLoading = false;
    }
    this.setIsLoading(false);
    return;
  }

  @action
  async duplicate(id: string) {
    try {
      this.setIsLoading(true);
      const { item: form } = await enchargeAPI.duplicateForm(id);

      runInAction(() => this.forms.current().splice(0, 0, observable(form)));
      // Add to current folder
      const currentFolderId = this.rootStore.foldersStore.getSelectedFolder(
        "forms"
      );
      if (currentFolderId) {
        this.rootStore.foldersStore.pushItemToFolder({
          folderId: currentFolderId,
          itemId: form.id,
          type: "forms",
        });
      }
    } catch (e) {
      toastError({
        message: "Error while duplicating form.",
        extra: e,
      });
      this.isLoading = false;
    }
    this.setIsLoading(false);
    return;
  }

  @action
  async archive(id: string) {
    this.queue.addConfirmableAction({
      state: () => this.forms.current(),
      performAction: () => {
        const forms = this.forms.current();
        const index = _.findIndex(forms, (item) => item.id === id);
        if (index === -1) return;
        forms.splice(index, 1);

        return async () => enchargeAPI.archiveForm(id);
      },
      confirmErrorMessage: "Couldn't archive form.",
    });
  }
}
