import * as coreHttp from "@azure/core-http";

export const Paths1No26BnAccountsInfoGetResponses200ContentApplicationJsonSchema: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "Paths1No26BnAccountsInfoGetResponses200ContentApplicationJsonSchema",
    modelProperties: {
      peopleCount: {
        serializedName: "peopleCount",
        required: true,
        type: {
          name: "Number",
        },
      },
      status: {
        serializedName: "status",
        required: true,
        type: {
          name: "Enum",
          allowedValues: ["active", "canceled", "trial", "trialExpired"],
        },
      },
      timezone: {
        serializedName: "timezone",
        required: true,
        type: {
          name: "String",
        },
      },
      activeServices: {
        serializedName: "activeServices",
        required: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String",
            },
          },
        },
      },
      site: {
        serializedName: "site",
        required: true,
        type: {
          name: "String",
        },
      },
      name: {
        serializedName: "name",
        required: true,
        type: {
          name: "String",
        },
      },
      accountId: {
        serializedName: "accountId",
        required: true,
        type: {
          name: "Number",
        },
      },
    },
  },
};

export const PathsYhuyfxAccountsFormFormidGetResponses200ContentApplicationJsonSchema: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "PathsYhuyfxAccountsFormFormidGetResponses200ContentApplicationJsonSchema",
    modelProperties: {
      accountInfo: {
        serializedName: "accountInfo",
        type: {
          name: "Composite",
          className:
            "Paths1Cbwmm1AccountsFormFormidGetResponses200ContentApplicationJsonSchemaPropertiesAccountinfo",
        },
      },
    },
  },
};

export const Paths1Cbwmm1AccountsFormFormidGetResponses200ContentApplicationJsonSchemaPropertiesAccountinfo: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "Paths1Cbwmm1AccountsFormFormidGetResponses200ContentApplicationJsonSchemaPropertiesAccountinfo",
    modelProperties: {
      site: {
        serializedName: "site",
        required: true,
        type: {
          name: "String",
        },
      },
      logo: {
        serializedName: "logo",
        required: true,
        type: {
          name: "String",
        },
      },
      name: {
        serializedName: "name",
        required: true,
        type: {
          name: "String",
        },
      },
      id: {
        serializedName: "id",
        required: true,
        type: {
          name: "Number",
        },
      },
    },
  },
};

export const Paths1O8Yf3GObjectsObjectnameGetResponses200ContentApplicationJsonSchema: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "Paths1O8Yf3GObjectsObjectnameGetResponses200ContentApplicationJsonSchema",
    modelProperties: {
      objects: {
        serializedName: "objects",
        required: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "any",
            },
          },
        },
      },
    },
  },
};

export const ICustomObjectCreatable: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ICustomObjectCreatable",
    additionalProperties: { type: { name: "Object" } },
    modelProperties: {
      externalId: {
        serializedName: "externalId",
        type: {
          name: "String",
        },
      },
      id: {
        serializedName: "id",
        type: {
          name: "Number",
        },
      },
    },
  },
};

export const Paths12Ynui3ObjectsObjectnamePostResponses200ContentApplicationJsonSchema: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "Paths12Ynui3ObjectsObjectnamePostResponses200ContentApplicationJsonSchema",
    modelProperties: {
      objects: {
        serializedName: "objects",
        required: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "any",
            },
          },
        },
      },
    },
  },
};

export const PathsOzwy89ObjectsObjectnamePutResponses200ContentApplicationJsonSchema: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "PathsOzwy89ObjectsObjectnamePutResponses200ContentApplicationJsonSchema",
    modelProperties: {
      created: {
        serializedName: "created",
        required: true,
        type: {
          name: "Boolean",
        },
      },
      object: {
        serializedName: "object",
        required: true,
        type: {
          name: "any",
        },
      },
    },
  },
};

export const Paths44QwncObjectsObjectnameSegmentsSegmentidGetResponses200ContentApplicationJsonSchema: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "Paths44QwncObjectsObjectnameSegmentsSegmentidGetResponses200ContentApplicationJsonSchema",
    modelProperties: {
      objects: {
        serializedName: "objects",
        required: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "any",
            },
          },
        },
      },
    },
  },
};

export const PathsT2XhptObjectsObjectnameCountGetResponses200ContentApplicationJsonSchema: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "PathsT2XhptObjectsObjectnameCountGetResponses200ContentApplicationJsonSchema",
    modelProperties: {
      count: {
        serializedName: "count",
        required: true,
        type: {
          name: "Number",
        },
      },
    },
  },
};

export const Paths977R58ObjectsObjectnameSegmentsSegmentidCountGetResponses200ContentApplicationJsonSchema: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "Paths977R58ObjectsObjectnameSegmentsSegmentidCountGetResponses200ContentApplicationJsonSchema",
    modelProperties: {
      count: {
        serializedName: "count",
        required: true,
        type: {
          name: "Number",
        },
      },
    },
  },
};

export const Paths1Yws1VzObjectsObjectnameSearchGetResponses200ContentApplicationJsonSchema: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "Paths1Yws1VzObjectsObjectnameSearchGetResponses200ContentApplicationJsonSchema",
    modelProperties: {
      objects: {
        serializedName: "objects",
        required: true,
        type: {
          name: "any",
        },
      },
    },
  },
};

export const Paths14Z9G5IObjectsObjectnameIdGetResponses200ContentApplicationJsonSchema: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "Paths14Z9G5IObjectsObjectnameIdGetResponses200ContentApplicationJsonSchema",
    modelProperties: {
      object: {
        serializedName: "object",
        required: true,
        type: {
          name: "any",
        },
      },
    },
  },
};

export const Paths4Q4Gg9ObjectsObjectnameIdPatchResponses200ContentApplicationJsonSchema: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "Paths4Q4Gg9ObjectsObjectnameIdPatchResponses200ContentApplicationJsonSchema",
    modelProperties: {
      object: {
        serializedName: "object",
        required: true,
        type: {
          name: "any",
        },
      },
    },
  },
};

export const Paths9B48PyObjectsObjectnameExternalidGetResponses200ContentApplicationJsonSchema: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "Paths9B48PyObjectsObjectnameExternalidGetResponses200ContentApplicationJsonSchema",
    modelProperties: {
      object: {
        serializedName: "object",
        required: true,
        type: {
          name: "any",
        },
      },
    },
  },
};

export const PathsXknmnkObjectsObjectnameExternalidPatchResponses200ContentApplicationJsonSchema: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "PathsXknmnkObjectsObjectnameExternalidPatchResponses200ContentApplicationJsonSchema",
    modelProperties: {
      object: {
        serializedName: "object",
        required: true,
        type: {
          name: "any",
        },
      },
    },
  },
};

export const PathsCn7LqdObjectsObjectnameIdAssociationsAssociationidGetResponses200ContentApplicationJsonSchema: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "PathsCn7LqdObjectsObjectnameIdAssociationsAssociationidGetResponses200ContentApplicationJsonSchema",
    modelProperties: {
      objects: {
        serializedName: "objects",
        required: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "any",
            },
          },
        },
      },
    },
  },
};

export const PathsWpz3OpObjectsObjectnameIdAssociationsGetResponses200ContentApplicationJsonSchema: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "PathsWpz3OpObjectsObjectnameIdAssociationsGetResponses200ContentApplicationJsonSchema",
    modelProperties: {
      objects: {
        serializedName: "objects",
        required: true,
        type: {
          name: "Dictionary",
          value: {
            type: { name: "Sequence", element: { type: { name: "any" } } },
          },
        },
      },
    },
  },
};

export const Paths146I63ISchemasGetResponses200ContentApplicationJsonSchema: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "Paths146I63ISchemasGetResponses200ContentApplicationJsonSchema",
    modelProperties: {
      objects: {
        serializedName: "objects",
        required: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CustomObjectSchema",
            },
          },
        },
      },
    },
  },
};

export const CustomObjectSchema: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "CustomObjectSchema",
    modelProperties: {
      associations: {
        serializedName: "associations",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CustomObjectSchemaAssociation",
            },
          },
        },
      },
      fields: {
        serializedName: "fields",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "IPersonField",
            },
          },
        },
      },
      secondaryFields: {
        serializedName: "secondaryFields",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String",
            },
          },
        },
      },
      primaryField: {
        serializedName: "primaryField",
        type: {
          name: "String",
        },
      },
      searchableFields: {
        serializedName: "searchableFields",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String",
            },
          },
        },
      },
      displayNamePlural: {
        serializedName: "displayNamePlural",
        required: true,
        type: {
          name: "String",
        },
      },
      displayNameSingular: {
        serializedName: "displayNameSingular",
        required: true,
        type: {
          name: "String",
        },
      },
      description: {
        serializedName: "description",
        type: {
          name: "String",
        },
      },
      name: {
        serializedName: "name",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const CustomObjectSchemaAssociation: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "CustomObjectSchemaAssociation",
    modelProperties: {
      type: {
        serializedName: "type",
        required: true,
        type: {
          name: "Enum",
          allowedValues: ["1:1", "1:M", "M:M"],
        },
      },
      name: {
        serializedName: "name",
        type: {
          name: "String",
        },
      },
      toObject: {
        serializedName: "toObject",
        required: true,
        type: {
          name: "String",
        },
      },
      fromObject: {
        serializedName: "fromObject",
        required: true,
        type: {
          name: "String",
        },
      },
      id: {
        serializedName: "id",
        required: true,
        type: {
          name: "Number",
        },
      },
    },
  },
};

export const IPersonField: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "IPersonField",
    modelProperties: {
      name: {
        serializedName: "name",
        required: true,
        type: {
          name: "String",
        },
      },
      title: {
        serializedName: "title",
        type: {
          name: "String",
        },
      },
      type: {
        serializedName: "type",
        required: true,
        type: {
          name: "Enum",
          allowedValues: ["string", "number", "boolean", "integer", "any"],
        },
      },
      format: {
        serializedName: "format",
        nullable: true,
        type: {
          name: "Enum",
          allowedValues: ["date", "date-time"],
        },
      },
      displayType: {
        serializedName: "displayType",
        type: {
          name: "Enum",
          allowedValues: [
            "text",
            "textarea",
            "date",
            "datetime",
            "number",
            "select",
            "multiselect",
            "checkbox",
            "radio",
            "email",
            "url",
            "phone",
            "integer",
            "decimal",
            "currency",
            "currencyCents",
          ],
        },
      },
      readOnly: {
        serializedName: "readOnly",
        type: {
          name: "Boolean",
        },
      },
      tooltip: {
        serializedName: "tooltip",
        type: {
          name: "String",
        },
      },
      icon: {
        serializedName: "icon",
        type: {
          name: "String",
        },
      },
      array: {
        serializedName: "array",
        type: {
          name: "Boolean",
        },
      },
      enum: {
        serializedName: "enum",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String",
            },
          },
        },
      },
      enumNames: {
        serializedName: "enumNames",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String",
            },
          },
        },
      },
      allowNewEnumValues: {
        serializedName: "allowNewEnumValues",
        type: {
          name: "Boolean",
        },
      },
      canMapFrom: {
        serializedName: "canMapFrom",
        type: {
          name: "Boolean",
        },
      },
      firstClassField: {
        serializedName: "firstClassField",
        type: {
          name: "Boolean",
        },
      },
      createdBy: {
        serializedName: "createdBy",
        type: {
          name: "String",
        },
      },
    },
  },
};

export const PickCustomObjectSchemaCreatableExcludeKeyofCustomObjectSchemaCreatableSearchableFields: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "PickCustomObjectSchemaCreatableExcludeKeyofCustomObjectSchemaCreatableSearchableFields",
    modelProperties: {
      name: {
        serializedName: "name",
        required: true,
        type: {
          name: "String",
        },
      },
      description: {
        serializedName: "description",
        type: {
          name: "String",
        },
      },
      displayNameSingular: {
        serializedName: "displayNameSingular",
        required: true,
        type: {
          name: "String",
        },
      },
      displayNamePlural: {
        serializedName: "displayNamePlural",
        required: true,
        type: {
          name: "String",
        },
      },
      primaryField: {
        serializedName: "primaryField",
        type: {
          name: "String",
        },
      },
      secondaryFields: {
        serializedName: "secondaryFields",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String",
            },
          },
        },
      },
    },
  },
};

export const PathsCuxwh4SchemasPostResponses200ContentApplicationJsonSchema: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "PathsCuxwh4SchemasPostResponses200ContentApplicationJsonSchema",
    modelProperties: {
      object: {
        serializedName: "object",
        type: {
          name: "Composite",
          className: "CustomObjectSchema",
        },
      },
    },
  },
};

export const Paths1Riv1ElSchemasObjectnameGetResponses200ContentApplicationJsonSchema: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "Paths1Riv1ElSchemasObjectnameGetResponses200ContentApplicationJsonSchema",
    modelProperties: {
      object: {
        serializedName: "object",
        type: {
          name: "Composite",
          className: "CustomObjectSchema",
        },
      },
    },
  },
};

export const PartialOmitCustomObjectSchemaFieldsOrAssociationsOrName: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "PartialOmitCustomObjectSchemaFieldsOrAssociationsOrName",
    modelProperties: {
      description: {
        serializedName: "description",
        type: {
          name: "String",
        },
      },
      displayNameSingular: {
        serializedName: "displayNameSingular",
        type: {
          name: "String",
        },
      },
      displayNamePlural: {
        serializedName: "displayNamePlural",
        type: {
          name: "String",
        },
      },
      searchableFields: {
        serializedName: "searchableFields",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String",
            },
          },
        },
      },
      primaryField: {
        serializedName: "primaryField",
        type: {
          name: "String",
        },
      },
      secondaryFields: {
        serializedName: "secondaryFields",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String",
            },
          },
        },
      },
    },
  },
};

export const PathsV1Bg0USchemasObjectnamePatchResponses200ContentApplicationJsonSchema: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "PathsV1Bg0USchemasObjectnamePatchResponses200ContentApplicationJsonSchema",
    modelProperties: {
      object: {
        serializedName: "object",
        type: {
          name: "Composite",
          className: "CustomObjectSchema",
        },
      },
    },
  },
};

export const PathsNnb827SchemasObjectnameFieldsPostResponses200ContentApplicationJsonSchema: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "PathsNnb827SchemasObjectnameFieldsPostResponses200ContentApplicationJsonSchema",
    modelProperties: {
      fields: {
        serializedName: "fields",
        required: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "PersonField",
            },
          },
        },
      },
    },
  },
};

export const PersonField: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "PersonField",
    modelProperties: {
      name: {
        serializedName: "name",
        required: true,
        type: {
          name: "String",
        },
      },
      title: {
        serializedName: "title",
        required: true,
        type: {
          name: "String",
        },
      },
      type: {
        serializedName: "type",
        required: true,
        type: {
          name: "Enum",
          allowedValues: ["string", "number", "boolean", "integer", "any"],
        },
      },
      format: {
        serializedName: "format",
        required: true,
        nullable: true,
        type: {
          name: "Enum",
          allowedValues: ["date", "date-time"],
        },
      },
      displayType: {
        serializedName: "displayType",
        type: {
          name: "Enum",
          allowedValues: [
            "text",
            "textarea",
            "date",
            "datetime",
            "number",
            "select",
            "multiselect",
            "checkbox",
            "radio",
            "email",
            "url",
            "phone",
            "integer",
            "decimal",
            "currency",
            "currencyCents",
          ],
        },
      },
      readOnly: {
        serializedName: "readOnly",
        required: true,
        type: {
          name: "Boolean",
        },
      },
      tooltip: {
        serializedName: "tooltip",
        type: {
          name: "String",
        },
      },
      icon: {
        serializedName: "icon",
        type: {
          name: "String",
        },
      },
      array: {
        serializedName: "array",
        required: true,
        type: {
          name: "Boolean",
        },
      },
      enum: {
        serializedName: "enum",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String",
            },
          },
        },
      },
      enumNames: {
        serializedName: "enumNames",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String",
            },
          },
        },
      },
      allowNewEnumValues: {
        serializedName: "allowNewEnumValues",
        type: {
          name: "Boolean",
        },
      },
      canMapFrom: {
        serializedName: "canMapFrom",
        required: true,
        type: {
          name: "Boolean",
        },
      },
      firstClassField: {
        serializedName: "firstClassField",
        required: true,
        type: {
          name: "Boolean",
        },
      },
      createdBy: {
        serializedName: "createdBy",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const PartialPickCustomObjectFieldTitleOrTooltipOrDisplayTypeOrIconOrEnumNamesOrEnumOrTypeOrFormat: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "PartialPickCustomObjectFieldTitleOrTooltipOrDisplayTypeOrIconOrEnumNamesOrEnumOrTypeOrFormat",
    modelProperties: {
      title: {
        serializedName: "title",
        type: {
          name: "String",
        },
      },
      tooltip: {
        serializedName: "tooltip",
        type: {
          name: "String",
        },
      },
      displayType: {
        serializedName: "displayType",
        type: {
          name: "Enum",
          allowedValues: [
            "text",
            "textarea",
            "date",
            "datetime",
            "number",
            "select",
            "multiselect",
            "checkbox",
            "radio",
            "email",
            "url",
            "phone",
            "integer",
            "decimal",
            "currency",
            "currencyCents",
          ],
        },
      },
      icon: {
        serializedName: "icon",
        type: {
          name: "String",
        },
      },
      enumNames: {
        serializedName: "enumNames",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String",
            },
          },
        },
      },
      enum: {
        serializedName: "enum",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String",
            },
          },
        },
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: ["string", "number", "boolean", "integer", "any"],
        },
      },
      format: {
        serializedName: "format",
        nullable: true,
        type: {
          name: "Enum",
          allowedValues: ["date", "date-time"],
        },
      },
    },
  },
};

export const Paths1M0HafgSchemasObjectnameFieldsFieldnamePatchResponses200ContentApplicationJsonSchema: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "Paths1M0HafgSchemasObjectnameFieldsFieldnamePatchResponses200ContentApplicationJsonSchema",
    modelProperties: {
      field: {
        serializedName: "field",
        type: {
          name: "Composite",
          className: "PersonField",
        },
      },
    },
  },
};

export const PickCustomObjectSchemaAssociationExcludeKeyofCustomObjectSchemaAssociationId: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "PickCustomObjectSchemaAssociationExcludeKeyofCustomObjectSchemaAssociationId",
    modelProperties: {
      name: {
        serializedName: "name",
        type: {
          name: "String",
        },
      },
      type: {
        serializedName: "type",
        required: true,
        type: {
          name: "Enum",
          allowedValues: ["1:1", "1:M", "M:M"],
        },
      },
      fromObject: {
        serializedName: "fromObject",
        required: true,
        type: {
          name: "String",
        },
      },
      toObject: {
        serializedName: "toObject",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const Paths1AqqxrsSchemasAssociationsPostResponses200ContentApplicationJsonSchema: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "Paths1AqqxrsSchemasAssociationsPostResponses200ContentApplicationJsonSchema",
    modelProperties: {
      association: {
        serializedName: "association",
        type: {
          name: "Composite",
          className: "CustomObjectSchemaAssociation",
        },
      },
    },
  },
};

export const Paths14Sy62FEmailsIdGetResponses200ContentApplicationJsonSchema: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "Paths14Sy62FEmailsIdGetResponses200ContentApplicationJsonSchema",
    modelProperties: {
      email: {
        serializedName: "email",
        required: true,
        type: {
          name: "any",
        },
      },
    },
  },
};

export const PartialIemailContent: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "PartialIemailContent",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Number",
        },
      },
      archived: {
        serializedName: "archived",
        type: {
          name: "Boolean",
        },
      },
      isStandalone: {
        serializedName: "isStandalone",
        type: {
          name: "Boolean",
        },
      },
      accountId: {
        serializedName: "accountId",
        type: {
          name: "Number",
        },
      },
      name: {
        serializedName: "name",
        type: {
          name: "String",
        },
      },
      subject: {
        serializedName: "subject",
        type: {
          name: "String",
        },
      },
      fromEmail: {
        serializedName: "fromEmail",
        type: {
          name: "String",
        },
      },
      fromName: {
        serializedName: "fromName",
        type: {
          name: "String",
        },
      },
      replyName: {
        serializedName: "replyName",
        type: {
          name: "String",
        },
      },
      replyEmail: {
        serializedName: "replyEmail",
        type: {
          name: "String",
        },
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: ["HTML", "text"],
        },
      },
      html: {
        serializedName: "html",
        type: {
          name: "String",
        },
      },
      editor: {
        serializedName: "editor",
        type: {
          name: "any",
        },
      },
      previewImage: {
        serializedName: "previewImage",
        type: {
          name: "String",
        },
      },
      preheader: {
        serializedName: "preheader",
        type: {
          name: "String",
        },
      },
      cc: {
        serializedName: "cc",
        type: {
          name: "String",
        },
      },
      bcc: {
        serializedName: "bcc",
        type: {
          name: "String",
        },
      },
      canSpamCompliance: {
        serializedName: "canSpamCompliance",
        type: {
          name: "Enum",
          allowedValues: [
            "approved",
            "pending",
            "rejected",
            "manually_approved",
          ],
        },
      },
      communicationCategoryId: {
        serializedName: "communicationCategoryId",
        type: {
          name: "Number",
        },
      },
      mergeTags: {
        serializedName: "mergeTags",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EmailMergeTag",
            },
          },
        },
      },
      aiGenerated: {
        serializedName: "aiGenerated",
        type: {
          name: "Boolean",
        },
      },
    },
  },
};

export const EmailMergeTag: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "EmailMergeTag",
    modelProperties: {
      value: {
        serializedName: "value",
        required: true,
        type: {
          name: "String",
        },
      },
      label: {
        serializedName: "label",
        type: {
          name: "String",
        },
      },
    },
  },
};

export const Paths110Q3SsEmailsIdPatchResponses200ContentApplicationJsonSchema: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "Paths110Q3SsEmailsIdPatchResponses200ContentApplicationJsonSchema",
    modelProperties: {
      email: {
        serializedName: "email",
        required: true,
        type: {
          name: "any",
        },
      },
    },
  },
};

export const EmailContentCreate: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "EmailContentCreate",
    modelProperties: {
      name: {
        serializedName: "name",
        required: true,
        type: {
          name: "String",
        },
      },
      subject: {
        serializedName: "subject",
        required: true,
        type: {
          name: "String",
        },
      },
      fromEmail: {
        serializedName: "fromEmail",
        required: true,
        type: {
          name: "String",
        },
      },
      fromName: {
        serializedName: "fromName",
        type: {
          name: "String",
        },
      },
      replyEmail: {
        serializedName: "replyEmail",
        type: {
          name: "String",
        },
      },
      replyName: {
        serializedName: "replyName",
        type: {
          name: "String",
        },
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: ["HTML", "text"],
        },
      },
      html: {
        serializedName: "html",
        type: {
          name: "String",
        },
      },
      editor: {
        serializedName: "editor",
        type: {
          name: "any",
        },
      },
      previewImage: {
        serializedName: "previewImage",
        type: {
          name: "String",
        },
      },
      preheader: {
        serializedName: "preheader",
        type: {
          name: "String",
        },
      },
      cc: {
        serializedName: "cc",
        type: {
          name: "String",
        },
      },
      bcc: {
        serializedName: "bcc",
        type: {
          name: "String",
        },
      },
      communicationCategoryId: {
        serializedName: "communicationCategoryId",
        type: {
          name: "Number",
        },
      },
      mergeTags: {
        serializedName: "mergeTags",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EmailContentCreateMergeTagsItem",
            },
          },
        },
      },
      isStandalone: {
        serializedName: "isStandalone",
        type: {
          name: "Boolean",
        },
      },
      aiGenerated: {
        serializedName: "aiGenerated",
        type: {
          name: "Boolean",
        },
      },
    },
  },
};

export const EmailContentCreateMergeTagsItem: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "EmailContentCreateMergeTagsItem",
    modelProperties: {
      label: {
        serializedName: "label",
        type: {
          name: "String",
        },
      },
      value: {
        serializedName: "value",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const Paths1P5RrhbEmailsPostResponses200ContentApplicationJsonSchema: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "Paths1P5RrhbEmailsPostResponses200ContentApplicationJsonSchema",
    modelProperties: {
      email: {
        serializedName: "email",
        required: true,
        type: {
          name: "any",
        },
      },
    },
  },
};

export const Paths1X3Kl1JEventSubscriptionsPostRequestbodyContentApplicationJsonSchema: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "Paths1X3Kl1JEventSubscriptionsPostRequestbodyContentApplicationJsonSchema",
    modelProperties: {
      eventType: {
        serializedName: "eventType",
        required: true,
        type: {
          name: "String",
        },
      },
      url: {
        serializedName: "url",
        type: {
          name: "String",
        },
      },
      eventName: {
        serializedName: "eventName",
        nullable: true,
        type: {
          name: "String",
        },
      },
      handlerData: {
        serializedName: "handlerData",
        type: {
          name: "any",
        },
      },
      service: {
        serializedName: "service",
        type: {
          name: "String",
        },
      },
      operation: {
        serializedName: "operation",
        type: {
          name: "String",
        },
      },
    },
  },
};

export const PathsS40Fw6EventSubscriptionsPostResponses201ContentApplicationJsonSchema: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "PathsS40Fw6EventSubscriptionsPostResponses201ContentApplicationJsonSchema",
    modelProperties: {
      subscription: {
        serializedName: "subscription",
        type: {
          name: "Composite",
          className: "Post201ApplicationJsonProperties",
        },
      },
    },
  },
};

export const Post201ApplicationJsonProperties: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "Post201ApplicationJsonProperties",
    modelProperties: {
      id: {
        serializedName: "id",
        required: true,
        type: {
          name: "Number",
        },
      },
    },
  },
};

export const Paths1Wwtb3VFieldsGetResponses200ContentApplicationJsonSchema: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "Paths1Wwtb3VFieldsGetResponses200ContentApplicationJsonSchema",
    modelProperties: {
      items: {
        serializedName: "items",
        required: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "IPersonField",
            },
          },
        },
      },
    },
  },
};

export const PathsGrbnpqFieldsPostResponses200ContentApplicationJsonSchema: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "PathsGrbnpqFieldsPostResponses200ContentApplicationJsonSchema",
    modelProperties: {
      items: {
        serializedName: "items",
        required: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "PersonField",
            },
          },
        },
      },
    },
  },
};

export const Paths19Z8IbcFieldsFieldnamePatchResponses200ContentApplicationJsonSchema: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "Paths19Z8IbcFieldsFieldnamePatchResponses200ContentApplicationJsonSchema",
    modelProperties: {
      field: {
        serializedName: "field",
        type: {
          name: "Composite",
          className: "PersonField",
        },
      },
    },
  },
};

export const Paths12Fu16GSegmentsGetResponses200ContentApplicationJsonSchema: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "Paths12Fu16GSegmentsGetResponses200ContentApplicationJsonSchema",
    modelProperties: {
      segments: {
        serializedName: "segments",
        required: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "Segment",
            },
          },
        },
      },
    },
  },
};

export const Segment: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "Segment",
    modelProperties: {
      version: {
        serializedName: "version",
        required: true,
        type: {
          name: "Enum",
          allowedValues: [1, 2],
        },
      },
      id: {
        serializedName: "id",
        required: true,
        type: {
          name: "Number",
        },
      },
      name: {
        serializedName: "name",
        required: true,
        type: {
          name: "String",
        },
      },
      objectName: {
        serializedName: "objectName",
        required: true,
        type: {
          name: "String",
        },
      },
      conditions: {
        serializedName: "conditions",
        type: {
          name: "Composite",
          className: "SegmentConditionGroup",
        },
      },
      accountId: {
        serializedName: "accountId",
        required: true,
        type: {
          name: "Number",
        },
      },
      readOnly: {
        serializedName: "readOnly",
        type: {
          name: "Boolean",
        },
      },
      autoSegment: {
        serializedName: "autoSegment",
        required: true,
        type: {
          name: "Boolean",
        },
      },
      color: {
        serializedName: "color",
        required: true,
        type: {
          name: "String",
        },
      },
      templateId: {
        serializedName: "templateId",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const SegmentConditionGroup: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "SegmentConditionGroup",
    modelProperties: {
      type: {
        defaultValue: "group",
        isConstant: true,
        serializedName: "type",
        type: {
          name: "String",
        },
      },
      operator: {
        serializedName: "operator",
        required: true,
        type: {
          name: "Enum",
          allowedValues: ["and", "or"],
        },
      },
      conditions: {
        serializedName: "conditions",
        required: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "SegmentCondition",
            },
          },
        },
      },
    },
  },
};

export const SegmentCondition: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "SegmentCondition",
    modelProperties: {
      uuid: {
        serializedName: "uuid",
        type: {
          name: "String",
        },
      },
    },
  },
};

export const SegmentEventCondition: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "SegmentEventCondition",
    modelProperties: {
      type: {
        defaultValue: "event",
        isConstant: true,
        serializedName: "type",
        type: {
          name: "String",
        },
      },
      condition: {
        serializedName: "condition",
        required: true,
        type: {
          name: "Enum",
          allowedValues: ["has", "has not"],
        },
      },
      systemConditions: {
        serializedName: "systemConditions",
        type: {
          name: "Composite",
          className: "SegmentEventConditionGroup",
        },
      },
      propertiesConditions: {
        serializedName: "propertiesConditions",
        type: {
          name: "Composite",
          className: "SegmentEventConditionGroup",
        },
      },
      frequencyCondition: {
        serializedName: "frequencyCondition",
        type: {
          name: "Composite",
          className: "SegmentEventFrequencyCondition",
        },
      },
      recencyCondition: {
        serializedName: "recencyCondition",
        type: {
          name: "Composite",
          className: "SegmentEventRecencyCondition",
        },
      },
    },
  },
};

export const SegmentEventConditionGroup: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "SegmentEventConditionGroup",
    modelProperties: {
      type: {
        defaultValue: "group",
        isConstant: true,
        serializedName: "type",
        type: {
          name: "String",
        },
      },
      operator: {
        serializedName: "operator",
        required: true,
        type: {
          name: "Enum",
          allowedValues: ["and", "or"],
        },
      },
      conditions: {
        serializedName: "conditions",
        required: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "SegmentEventConditionGroupConditionsItem",
            },
          },
        },
      },
      system: {
        serializedName: "system",
        type: {
          name: "Boolean",
        },
      },
      uuid: {
        serializedName: "uuid",
        type: {
          name: "String",
        },
      },
    },
  },
};

export const SegmentEventConditionGroupConditionsItem: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "SegmentEventConditionGroupConditionsItem",
  },
};

export const SegmentEventProperty: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "SegmentEventProperty",
    modelProperties: {
      uuid: {
        serializedName: "uuid",
        type: {
          name: "String",
        },
      },
      value: {
        serializedName: "value",
        type: {
          name: "any",
        },
      },
      condition: {
        serializedName: "condition",
        required: true,
        type: {
          name: "any",
        },
      },
      field: {
        serializedName: "field",
        required: true,
        type: {
          name: "String",
        },
      },
      type: {
        defaultValue: "field",
        isConstant: true,
        serializedName: "type",
        type: {
          name: "String",
        },
      },
    },
  },
};

export const SegmentEventFrequencyCondition: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "SegmentEventFrequencyCondition",
    modelProperties: {
      type: {
        defaultValue: "frequencyCondition",
        isConstant: true,
        serializedName: "type",
        type: {
          name: "String",
        },
      },
      value: {
        serializedName: "value",
        required: true,
        type: {
          name: "Number",
        },
      },
      condition: {
        serializedName: "condition",
        required: true,
        type: {
          name: "Enum",
          allowedValues: ["is more than", "is less than", "at least"],
        },
      },
    },
  },
};

export const SegmentEventRecencyCondition: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "SegmentEventRecencyCondition",
    modelProperties: {
      type: {
        defaultValue: "recencyCondition",
        isConstant: true,
        serializedName: "type",
        type: {
          name: "String",
        },
      },
      value: {
        serializedName: "value",
        required: true,
        type: {
          name: "any",
        },
      },
      condition: {
        serializedName: "condition",
        required: true,
        type: {
          name: "Enum",
          allowedValues: [
            "is empty",
            "is not empty",
            "is any",
            "after",
            "before",
            "on",
            "after period",
            "before period",
            "on period",
          ],
        },
      },
    },
  },
};

export const SegmentTagCondition: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "SegmentTagCondition",
    modelProperties: {
      type: {
        defaultValue: "tag",
        isConstant: true,
        serializedName: "type",
        type: {
          name: "String",
        },
      },
      value: {
        serializedName: "value",
        required: true,
        type: {
          name: "any",
        },
      },
      condition: {
        serializedName: "condition",
        required: true,
        type: {
          name: "Enum",
          allowedValues: ["has", "has not"],
        },
      },
    },
  },
};

export const SegmentPropertyCondition: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "SegmentPropertyCondition",
    modelProperties: {
      type: {
        defaultValue: "field",
        isConstant: true,
        serializedName: "type",
        type: {
          name: "String",
        },
      },
      field: {
        serializedName: "field",
        required: true,
        type: {
          name: "String",
        },
      },
      condition: {
        serializedName: "condition",
        required: true,
        type: {
          name: "any",
        },
      },
      value: {
        serializedName: "value",
        type: {
          name: "any",
        },
      },
    },
  },
};

export const SegmentNestedSegmentCondition: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "SegmentNestedSegmentCondition",
    modelProperties: {
      type: {
        defaultValue: "segment",
        isConstant: true,
        serializedName: "type",
        type: {
          name: "String",
        },
      },
      value: {
        serializedName: "value",
        required: true,
        type: {
          name: "Number",
        },
      },
      condition: {
        serializedName: "condition",
        required: true,
        type: {
          name: "Enum",
          allowedValues: ["in", "not in"],
        },
      },
    },
  },
};

export const SegmentAssociationCondition: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "SegmentAssociationCondition",
    modelProperties: {
      type: {
        defaultValue: "association",
        isConstant: true,
        serializedName: "type",
        type: {
          name: "String",
        },
      },
      condition: {
        serializedName: "condition",
        type: {
          name: "Composite",
          className: "AssociationCondition",
        },
      },
      value: {
        serializedName: "value",
        required: true,
        type: {
          name: "Number",
        },
      },
      associationConditions: {
        serializedName: "associationConditions",
        type: {
          name: "Composite",
          className: "SegmentConditionGroup",
        },
      },
    },
  },
};

export const AssociationCondition: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "AssociationCondition",
    modelProperties: {
      type: {
        serializedName: "type",
        required: true,
        type: {
          name: "any",
        },
      },
      value: {
        serializedName: "value",
        type: {
          name: "Number",
        },
      },
    },
  },
};

export const SegmentGoalCondition: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "SegmentGoalCondition",
    modelProperties: {
      type: {
        defaultValue: "goal",
        isConstant: true,
        serializedName: "type",
        type: {
          name: "String",
        },
      },
      condition: {
        serializedName: "condition",
        required: true,
        type: {
          name: "Enum",
          allowedValues: ["in", "not in"],
        },
      },
      value: {
        serializedName: "value",
        required: true,
        type: {
          name: "Number",
        },
      },
    },
  },
};

export const PartialOmitIsegmentIdOrAccountIdOrVersion: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "PartialOmitIsegmentIdOrAccountIdOrVersion",
    modelProperties: {
      name: {
        serializedName: "name",
        type: {
          name: "String",
        },
      },
      objectName: {
        serializedName: "objectName",
        type: {
          name: "String",
        },
      },
      conditions: {
        serializedName: "conditions",
        type: {
          name: "Composite",
          className: "SegmentConditionGroup",
        },
      },
      readOnly: {
        serializedName: "readOnly",
        type: {
          name: "Boolean",
        },
      },
      autoSegment: {
        serializedName: "autoSegment",
        type: {
          name: "Boolean",
        },
      },
      color: {
        serializedName: "color",
        type: {
          name: "String",
        },
      },
      templateId: {
        serializedName: "templateId",
        type: {
          name: "String",
        },
      },
    },
  },
};

export const Paths14Y7EzpSegmentsPostResponses200ContentApplicationJsonSchema: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "Paths14Y7EzpSegmentsPostResponses200ContentApplicationJsonSchema",
    modelProperties: {
      segment: {
        serializedName: "segment",
        type: {
          name: "Composite",
          className: "Segment",
        },
      },
    },
  },
};

export const Paths6Py3Y3SegmentsSegmentidPeopleGetResponses200ContentApplicationJsonSchema: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "Paths6Py3Y3SegmentsSegmentidPeopleGetResponses200ContentApplicationJsonSchema",
    modelProperties: {
      people: {
        serializedName: "people",
        required: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "any",
            },
          },
        },
      },
    },
  },
};

export const PathsXnkznrTagsPostRequestbodyContentApplicationJsonSchemaAllof0: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "PathsXnkznrTagsPostRequestbodyContentApplicationJsonSchemaAllof0",
    modelProperties: {
      tag: {
        serializedName: "tag",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const EndUserIdentifiers: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "EndUserIdentifiers",
    additionalProperties: { type: { name: "Object" } },
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "String",
        },
      },
      userId: {
        serializedName: "userId",
        type: {
          name: "String",
        },
      },
      email: {
        serializedName: "email",
        type: {
          name: "String",
        },
      },
    },
  },
};

export const Paths94X0UoTagsDeleteRequestbodyContentApplicationJsonSchemaAllof0: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "Paths94X0UoTagsDeleteRequestbodyContentApplicationJsonSchemaAllof0",
    modelProperties: {
      tag: {
        serializedName: "tag",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const PathsJh2Cb5TagsManagementGetResponses200ContentApplicationJsonSchema: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "PathsJh2Cb5TagsManagementGetResponses200ContentApplicationJsonSchema",
    modelProperties: {
      tags: {
        serializedName: "tags",
        required: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "Tag",
            },
          },
        },
      },
    },
  },
};

export const Tag: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "Tag",
    modelProperties: {
      count: {
        serializedName: "count",
        type: {
          name: "Number",
        },
      },
      createdAt: {
        serializedName: "createdAt",
        type: {
          name: "DateTime",
        },
      },
      description: {
        serializedName: "description",
        type: {
          name: "String",
        },
      },
      tag: {
        serializedName: "tag",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const Paths5Gr823TagsManagementPostRequestbodyContentApplicationJsonSchema: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "Paths5Gr823TagsManagementPostRequestbodyContentApplicationJsonSchema",
    modelProperties: {
      tags: {
        serializedName: "tags",
        required: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "Tag",
            },
          },
        },
      },
    },
  },
};

export const PathsQovxsvTagsManagementPostResponses200ContentApplicationJsonSchema: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "PathsQovxsvTagsManagementPostResponses200ContentApplicationJsonSchema",
    modelProperties: {
      tags: {
        serializedName: "tags",
        required: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "Tag",
            },
          },
        },
      },
    },
  },
};

export const PathsSnksa5TagsManagementCountsGetResponses200ContentApplicationJsonSchema: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "PathsSnksa5TagsManagementCountsGetResponses200ContentApplicationJsonSchema",
    modelProperties: {
      tagCounts: {
        serializedName: "tagCounts",
        required: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className:
                "Paths69Ldh4TagsManagementCountsGetResponses200ContentApplicationJsonSchemaPropertiesTagcountsItems",
            },
          },
        },
      },
    },
  },
};

export const Paths69Ldh4TagsManagementCountsGetResponses200ContentApplicationJsonSchemaPropertiesTagcountsItems: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "Paths69Ldh4TagsManagementCountsGetResponses200ContentApplicationJsonSchemaPropertiesTagcountsItems",
    modelProperties: {
      count: {
        serializedName: "count",
        required: true,
        type: {
          name: "Number",
        },
      },
      tag: {
        serializedName: "tag",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const PathsVpki7FTagsManagementTagPatchResponses200ContentApplicationJsonSchema: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "PathsVpki7FTagsManagementTagPatchResponses200ContentApplicationJsonSchema",
    modelProperties: {
      tag: {
        serializedName: "tag",
        type: {
          name: "Composite",
          className: "Tag",
        },
      },
    },
  },
};

export const PathsIn61X1PeopleAllGetResponses200ContentApplicationJsonSchema: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "PathsIn61X1PeopleAllGetResponses200ContentApplicationJsonSchema",
    modelProperties: {
      people: {
        serializedName: "people",
        required: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "any",
            },
          },
        },
      },
    },
  },
};

export const AIContentSuggestionGenerateParameters: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "AIContentSuggestionGenerateParameters",
  },
};

export const AIContentSuggestionWriteEmail: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "AIContentSuggestionWriteEmail",
    modelProperties: {
      parameters: {
        serializedName: "parameters",
        type: {
          name: "Composite",
          className: "AIContentSuggestionWriteEmailParameters",
        },
      },
      useCase: {
        defaultValue: "writeEmail",
        isConstant: true,
        serializedName: "useCase",
        type: {
          name: "String",
        },
      },
    },
  },
};

export const AIContentSuggestionCommonParameters: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "AIContentSuggestionCommonParameters",
    modelProperties: {
      companyName: {
        serializedName: "companyName",
        required: true,
        type: {
          name: "String",
        },
      },
      example: {
        serializedName: "example",
        type: {
          name: "String",
        },
      },
      tone: {
        serializedName: "tone",
        type: {
          name: "String",
        },
      },
    },
  },
};

export const ComponentsVoo65SSchemasAicontentsuggestionwriteemailPropertiesParametersAllof1: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "ComponentsVoo65SSchemasAicontentsuggestionwriteemailPropertiesParametersAllof1",
    modelProperties: {
      emailAbout: {
        serializedName: "emailAbout",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const AIContentSuggestionSubjectLine: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "AIContentSuggestionSubjectLine",
    modelProperties: {
      parameters: {
        serializedName: "parameters",
        type: {
          name: "Composite",
          className: "AIContentSuggestionSubjectLineParameters",
        },
      },
      useCase: {
        defaultValue: "subjectLine",
        isConstant: true,
        serializedName: "useCase",
        type: {
          name: "String",
        },
      },
    },
  },
};

export const Components19XglejSchemasAicontentsuggestionsubjectlinePropertiesParametersAllof1: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "Components19XglejSchemasAicontentsuggestionsubjectlinePropertiesParametersAllof1",
    modelProperties: {
      emailAbout: {
        serializedName: "emailAbout",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const AIContentSuggestionEmailOutline: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "AIContentSuggestionEmailOutline",
    modelProperties: {
      parameters: {
        serializedName: "parameters",
        type: {
          name: "Composite",
          className: "AIContentSuggestionEmailOutlineParameters",
        },
      },
      useCase: {
        defaultValue: "emailOutline",
        isConstant: true,
        serializedName: "useCase",
        type: {
          name: "String",
        },
      },
    },
  },
};

export const Components1E769BeSchemasAicontentsuggestionemailoutlinePropertiesParametersAllof1: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "Components1E769BeSchemasAicontentsuggestionemailoutlinePropertiesParametersAllof1",
    modelProperties: {
      emailAbout: {
        serializedName: "emailAbout",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const AIContentSuggestionRewriteSubjectLine: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "AIContentSuggestionRewriteSubjectLine",
    modelProperties: {
      parameters: {
        serializedName: "parameters",
        type: {
          name: "Composite",
          className: "AIContentSuggestionRewriteSubjectLineParameters",
        },
      },
      useCase: {
        defaultValue: "rewriteSubjectLine",
        isConstant: true,
        serializedName: "useCase",
        type: {
          name: "String",
        },
      },
    },
  },
};

export const Components15YhgfqSchemasAicontentsuggestionrewritesubjectlinePropertiesParametersAllof1: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "Components15YhgfqSchemasAicontentsuggestionrewritesubjectlinePropertiesParametersAllof1",
    modelProperties: {
      subject: {
        serializedName: "subject",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const AIContentSuggestionAutocomplete: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "AIContentSuggestionAutocomplete",
    modelProperties: {
      parameters: {
        serializedName: "parameters",
        type: {
          name: "Composite",
          className: "AIContentSuggestionAutocompleteParameters",
        },
      },
      useCase: {
        defaultValue: "autocompleteText",
        isConstant: true,
        serializedName: "useCase",
        type: {
          name: "String",
        },
      },
    },
  },
};

export const Components888N40SchemasAicontentsuggestionautocompletePropertiesParametersAllof1: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "Components888N40SchemasAicontentsuggestionautocompletePropertiesParametersAllof1",
    modelProperties: {
      emailName: {
        serializedName: "emailName",
        required: true,
        type: {
          name: "String",
        },
      },
      emailSubject: {
        serializedName: "emailSubject",
        required: true,
        type: {
          name: "String",
        },
      },
      text: {
        serializedName: "text",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const AIContentSuggestionWriteParagraph: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "AIContentSuggestionWriteParagraph",
    modelProperties: {
      parameters: {
        serializedName: "parameters",
        type: {
          name: "Composite",
          className: "AIContentSuggestionWriteParagraphParameters",
        },
      },
      useCase: {
        defaultValue: "writeParagraph",
        isConstant: true,
        serializedName: "useCase",
        type: {
          name: "String",
        },
      },
    },
  },
};

export const Components1N3M5C8SchemasAicontentsuggestionwriteparagraphPropertiesParametersAllof1: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "Components1N3M5C8SchemasAicontentsuggestionwriteparagraphPropertiesParametersAllof1",
    modelProperties: {
      emailName: {
        serializedName: "emailName",
        required: true,
        type: {
          name: "String",
        },
      },
      emailSubject: {
        serializedName: "emailSubject",
        required: true,
        type: {
          name: "String",
        },
      },
      text: {
        serializedName: "text",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const AIContentSuggestionImproveParagraph: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "AIContentSuggestionImproveParagraph",
    modelProperties: {
      parameters: {
        serializedName: "parameters",
        type: {
          name: "Composite",
          className: "AIContentSuggestionImproveParagraphParameters",
        },
      },
      useCase: {
        defaultValue: "improveParagraph",
        isConstant: true,
        serializedName: "useCase",
        type: {
          name: "String",
        },
      },
    },
  },
};

export const Components1Kqnk1XSchemasAicontentsuggestionimproveparagraphPropertiesParametersAllof1: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "Components1Kqnk1XSchemasAicontentsuggestionimproveparagraphPropertiesParametersAllof1",
    modelProperties: {
      emailName: {
        serializedName: "emailName",
        required: true,
        type: {
          name: "String",
        },
      },
      emailSubject: {
        serializedName: "emailSubject",
        required: true,
        type: {
          name: "String",
        },
      },
      text: {
        serializedName: "text",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const Paths18I7KqlAiWriterPostResponses200ContentApplicationJsonSchema: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "Paths18I7KqlAiWriterPostResponses200ContentApplicationJsonSchema",
    modelProperties: {
      suggestions: {
        serializedName: "suggestions",
        required: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "AIContentSuggestion",
            },
          },
        },
      },
    },
  },
};

export const AIContentSuggestion: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "AIContentSuggestion",
    modelProperties: {
      useCase: {
        serializedName: "useCase",
        required: true,
        type: {
          name: "Enum",
          allowedValues: [
            "writeEmail",
            "emailOutline",
            "subjectLine",
            "rewriteSubjectLine",
            "autocompleteText",
            "improveParagraph",
            "writeParagraph",
          ],
        },
      },
      content: {
        serializedName: "content",
        required: true,
        type: {
          name: "String",
        },
      },
      id: {
        serializedName: "id",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const Paths1Vqj9J9AiWriterPublicPostResponses200ContentApplicationJsonSchema: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "Paths1Vqj9J9AiWriterPublicPostResponses200ContentApplicationJsonSchema",
    modelProperties: {
      suggestions: {
        serializedName: "suggestions",
        required: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "AIContentSuggestion",
            },
          },
        },
      },
    },
  },
};

export const Paths1FongjsAiWriterFavoritesGetResponses200ContentApplicationJsonSchema: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "Paths1FongjsAiWriterFavoritesGetResponses200ContentApplicationJsonSchema",
    modelProperties: {
      suggestions: {
        serializedName: "suggestions",
        required: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "AIContentSuggestion",
            },
          },
        },
      },
    },
  },
};

export const PathsEq6JoaBroadcastsGetResponses200ContentApplicationJsonSchema: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "PathsEq6JoaBroadcastsGetResponses200ContentApplicationJsonSchema",
    modelProperties: {
      items: {
        serializedName: "items",
        required: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "Broadcast",
            },
          },
        },
      },
    },
  },
};

export const Broadcast: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "Broadcast",
    modelProperties: {
      id: {
        serializedName: "id",
        required: true,
        type: {
          name: "Number",
        },
      },
      name: {
        serializedName: "name",
        required: true,
        type: {
          name: "String",
        },
      },
      status: {
        serializedName: "status",
        required: true,
        type: {
          name: "Enum",
          allowedValues: [
            "draft",
            "scheduled",
            "sending",
            "sent",
            "ab-testing",
            "ab-test-sending-winner",
            "canceled",
            "failed",
          ],
        },
      },
      audience: {
        serializedName: "audience",
        type: {
          name: "Composite",
          className: "BroadcastAudience",
        },
      },
      emailId: {
        serializedName: "emailId",
        type: {
          name: "Number",
        },
      },
      time: {
        serializedName: "time",
        type: {
          name: "Composite",
          className: "BroadcastTime",
        },
      },
      sendAt: {
        serializedName: "sendAt",
        type: {
          name: "DateTime",
        },
      },
      followUp: {
        serializedName: "followUp",
        type: {
          name: "Composite",
          className: "BroadcastFollowUp",
        },
      },
      abTest: {
        serializedName: "abTest",
        type: {
          name: "Composite",
          className: "ABTestConfig",
        },
      },
      createdAt: {
        serializedName: "createdAt",
        type: {
          name: "String",
        },
      },
      updatedAt: {
        serializedName: "updatedAt",
        type: {
          name: "String",
        },
      },
      accountId: {
        serializedName: "accountId",
        required: true,
        type: {
          name: "Number",
        },
      },
      createdBy: {
        serializedName: "createdBy",
        type: {
          name: "Number",
        },
      },
      integrationId: {
        serializedName: "integrationId",
        nullable: true,
        type: {
          name: "Number",
        },
      },
      cachedMetric: {
        serializedName: "cachedMetric",
        type: {
          name: "Composite",
          className: "CachedMetric",
        },
      },
      peopleEntered: {
        serializedName: "peopleEntered",
        required: true,
        type: {
          name: "Number",
        },
      },
      peopleExited: {
        serializedName: "peopleExited",
        required: true,
        type: {
          name: "Number",
        },
      },
    },
  },
};

export const BroadcastAudience: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "BroadcastAudience",
    modelProperties: {
      segmentId: {
        serializedName: "segmentId",
        required: true,
        type: {
          name: "Number",
        },
      },
      type: {
        defaultValue: "segment",
        isConstant: true,
        serializedName: "type",
        type: {
          name: "String",
        },
      },
    },
  },
};

export const BroadcastTime: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "BroadcastTime",
    modelProperties: {
      useContactsTimezones: {
        serializedName: "useContactsTimezones",
        type: {
          name: "Boolean",
        },
      },
    },
  },
};

export const BroadcastFollowUp: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "BroadcastFollowUp",
    modelProperties: {
      newSubjectLine: {
        serializedName: "newSubjectLine",
        type: {
          name: "String",
        },
      },
      emailId: {
        serializedName: "emailId",
        type: {
          name: "Number",
        },
      },
      time: {
        serializedName: "time",
        type: {
          name: "Composite",
          className: "BroadcastFollowUpTime",
        },
      },
      audience: {
        serializedName: "audience",
        required: true,
        type: {
          name: "Enum",
          allowedValues: ["not-opened", "not-clicked"],
        },
      },
    },
  },
};

export const BroadcastFollowUpTime: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "BroadcastFollowUpTime",
    modelProperties: {
      period: {
        serializedName: "period",
        required: true,
        type: {
          name: "Number",
        },
      },
    },
  },
};

export const ABTestConfig: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ABTestConfig",
    modelProperties: {
      enabled: {
        serializedName: "enabled",
        required: true,
        type: {
          name: "Boolean",
        },
      },
      variants: {
        serializedName: "variants",
        required: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ABEmailTestVariant",
            },
          },
        },
      },
      pickWinner: {
        serializedName: "pickWinner",
        required: true,
        type: {
          name: "Boolean",
        },
      },
      pickWinnerBy: {
        serializedName: "pickWinnerBy",
        type: {
          name: "Enum",
          allowedValues: ["open", "click"],
        },
      },
      waitBeforeWinnerPeriod: {
        serializedName: "waitBeforeWinnerPeriod",
        type: {
          name: "Number",
        },
      },
      waitBeforeWinnerPeriodType: {
        serializedName: "waitBeforeWinnerPeriodType",
        type: {
          name: "Enum",
          allowedValues: ["hours", "days"],
        },
      },
      winner: {
        serializedName: "winner",
        type: {
          name: "Composite",
          className: "ABEmailTestVariant",
        },
      },
      distribution: {
        serializedName: "distribution",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Number",
            },
          },
        },
      },
      winnerPickedAt: {
        serializedName: "winnerPickedAt",
        type: {
          name: "String",
        },
      },
    },
  },
};

export const ABEmailTestVariant: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ABEmailTestVariant",
  },
};

export const ABEmailTestVariantByEmail: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ABEmailTestVariantByEmail",
    modelProperties: {
      emailId: {
        serializedName: "emailId",
        type: {
          name: "Number",
        },
      },
      type: {
        defaultValue: "email",
        isConstant: true,
        serializedName: "type",
        type: {
          name: "String",
        },
      },
      variantId: {
        serializedName: "variantId",
        type: {
          name: "String",
        },
      },
    },
  },
};

export const ABEmailTestVariantByEmailDetails: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ABEmailTestVariantByEmailDetails",
    modelProperties: {
      preheader: {
        serializedName: "preheader",
        type: {
          name: "String",
        },
      },
      fromName: {
        serializedName: "fromName",
        type: {
          name: "String",
        },
      },
      fromEmail: {
        serializedName: "fromEmail",
        type: {
          name: "String",
        },
      },
      subject: {
        serializedName: "subject",
        type: {
          name: "String",
        },
      },
      type: {
        defaultValue: "emailDetails",
        isConstant: true,
        serializedName: "type",
        type: {
          name: "String",
        },
      },
      variantId: {
        serializedName: "variantId",
        type: {
          name: "String",
        },
      },
    },
  },
};

export const CachedMetric: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "CachedMetric",
    modelProperties: {
      updatedAt: {
        serializedName: "updatedAt",
        type: {
          name: "DateTime",
        },
      },
      data: {
        serializedName: "data",
        type: {
          name: "Composite",
          className: "MetricCachedData",
        },
      },
      isStale: {
        serializedName: "isStale",
        type: {
          name: "Boolean",
        },
      },
    },
  },
};

export const MetricCachedData: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "MetricCachedData",
    modelProperties: {
      delivered: {
        serializedName: "delivered",
        required: true,
        type: {
          name: "Number",
        },
      },
      click: {
        serializedName: "click",
        required: true,
        type: {
          name: "Number",
        },
      },
      open: {
        serializedName: "open",
        required: true,
        type: {
          name: "Number",
        },
      },
    },
  },
};

export const PartialBroadcast: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "PartialBroadcast",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Number",
        },
      },
      name: {
        serializedName: "name",
        type: {
          name: "String",
        },
      },
      status: {
        serializedName: "status",
        type: {
          name: "Enum",
          allowedValues: [
            "draft",
            "scheduled",
            "sending",
            "sent",
            "ab-testing",
            "ab-test-sending-winner",
            "canceled",
            "failed",
          ],
        },
      },
      audience: {
        serializedName: "audience",
        type: {
          name: "Composite",
          className: "PartialBroadcastAudience",
        },
      },
      emailId: {
        serializedName: "emailId",
        type: {
          name: "Number",
        },
      },
      time: {
        serializedName: "time",
        type: {
          name: "Composite",
          className: "PartialBroadcastTime",
        },
      },
      sendAt: {
        serializedName: "sendAt",
        type: {
          name: "DateTime",
        },
      },
      followUp: {
        serializedName: "followUp",
        type: {
          name: "Composite",
          className: "PartialBroadcastFollowUp",
        },
      },
      abTest: {
        serializedName: "abTest",
        type: {
          name: "Composite",
          className: "ABTestConfig",
        },
      },
      createdAt: {
        serializedName: "createdAt",
        type: {
          name: "String",
        },
      },
      updatedAt: {
        serializedName: "updatedAt",
        type: {
          name: "String",
        },
      },
      accountId: {
        serializedName: "accountId",
        type: {
          name: "Number",
        },
      },
      createdBy: {
        serializedName: "createdBy",
        type: {
          name: "Number",
        },
      },
      integrationId: {
        serializedName: "integrationId",
        nullable: true,
        type: {
          name: "Number",
        },
      },
      cachedMetric: {
        serializedName: "cachedMetric",
        type: {
          name: "Composite",
          className: "CachedMetric",
        },
      },
      peopleEntered: {
        serializedName: "peopleEntered",
        type: {
          name: "Number",
        },
      },
      peopleExited: {
        serializedName: "peopleExited",
        type: {
          name: "Number",
        },
      },
    },
  },
};

export const PartialBroadcastAudience: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "PartialBroadcastAudience",
    modelProperties: {
      segmentId: {
        serializedName: "segmentId",
        required: true,
        type: {
          name: "Number",
        },
      },
      type: {
        defaultValue: "segment",
        isConstant: true,
        serializedName: "type",
        type: {
          name: "String",
        },
      },
    },
  },
};

export const PartialBroadcastTime: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "PartialBroadcastTime",
    modelProperties: {
      useContactsTimezones: {
        serializedName: "useContactsTimezones",
        type: {
          name: "Boolean",
        },
      },
    },
  },
};

export const PartialBroadcastFollowUp: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "PartialBroadcastFollowUp",
    modelProperties: {
      newSubjectLine: {
        serializedName: "newSubjectLine",
        type: {
          name: "String",
        },
      },
      emailId: {
        serializedName: "emailId",
        type: {
          name: "Number",
        },
      },
      time: {
        serializedName: "time",
        type: {
          name: "Composite",
          className: "PartialBroadcastFollowUpTime",
        },
      },
      audience: {
        serializedName: "audience",
        required: true,
        type: {
          name: "Enum",
          allowedValues: ["not-opened", "not-clicked"],
        },
      },
    },
  },
};

export const PartialBroadcastFollowUpTime: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "PartialBroadcastFollowUpTime",
    modelProperties: {
      period: {
        serializedName: "period",
        required: true,
        type: {
          name: "Number",
        },
      },
    },
  },
};

export const Paths148Z6QBroadcastsPostResponses200ContentApplicationJsonSchema: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "Paths148Z6QBroadcastsPostResponses200ContentApplicationJsonSchema",
    modelProperties: {
      item: {
        serializedName: "item",
        type: {
          name: "Composite",
          className: "Broadcast",
        },
      },
    },
  },
};

export const Paths1Nxrm9ZBroadcastsIdPatchResponses200ContentApplicationJsonSchema: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "Paths1Nxrm9ZBroadcastsIdPatchResponses200ContentApplicationJsonSchema",
    modelProperties: {
      item: {
        serializedName: "item",
        type: {
          name: "Composite",
          className: "Broadcast",
        },
      },
    },
  },
};

export const Paths189N016BroadcastsIdSendPostResponses200ContentApplicationJsonSchema: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "Paths189N016BroadcastsIdSendPostResponses200ContentApplicationJsonSchema",
    modelProperties: {
      item: {
        serializedName: "item",
        type: {
          name: "Composite",
          className: "Broadcast",
        },
      },
    },
  },
};

export const Paths6Sw4P7BroadcastsIdCancelPostResponses200ContentApplicationJsonSchema: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "Paths6Sw4P7BroadcastsIdCancelPostResponses200ContentApplicationJsonSchema",
    modelProperties: {
      item: {
        serializedName: "item",
        type: {
          name: "Composite",
          className: "Broadcast",
        },
      },
    },
  },
};

export const PathsRinvztBroadcastsIdStatusPostResponses200ContentApplicationJsonSchema: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "PathsRinvztBroadcastsIdStatusPostResponses200ContentApplicationJsonSchema",
    modelProperties: {
      item: {
        serializedName: "item",
        type: {
          name: "Composite",
          className:
            "Paths1D35Ra6BroadcastsIdStatusPostResponses200ContentApplicationJsonSchemaPropertiesItem",
        },
      },
    },
  },
};

export const Paths1D35Ra6BroadcastsIdStatusPostResponses200ContentApplicationJsonSchemaPropertiesItem: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "Paths1D35Ra6BroadcastsIdStatusPostResponses200ContentApplicationJsonSchemaPropertiesItem",
    modelProperties: {
      status: {
        serializedName: "status",
        required: true,
        type: {
          name: "Enum",
          allowedValues: [
            "draft",
            "scheduled",
            "sending",
            "sent",
            "ab-testing",
            "ab-test-sending-winner",
            "canceled",
            "failed",
          ],
        },
      },
      id: {
        serializedName: "id",
        required: true,
        type: {
          name: "Number",
        },
      },
    },
  },
};

export const PathsQjciwlBroadcastsIdEnteredGetResponses200ContentApplicationJsonSchema: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "PathsQjciwlBroadcastsIdEnteredGetResponses200ContentApplicationJsonSchema",
    modelProperties: {
      count: {
        serializedName: "count",
        required: true,
        type: {
          name: "Number",
        },
      },
    },
  },
};

export const Paths7Kf2KySettingsCommunicationCategoriesGetResponses200ContentApplicationJsonSchema: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "Paths7Kf2KySettingsCommunicationCategoriesGetResponses200ContentApplicationJsonSchema",
    modelProperties: {
      items: {
        serializedName: "items",
        required: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ICommunicationCategory",
            },
          },
        },
      },
    },
  },
};

export const ICommunicationCategory: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ICommunicationCategory",
    modelProperties: {
      id: {
        serializedName: "id",
        required: true,
        type: {
          name: "Number",
        },
      },
      name: {
        serializedName: "name",
        required: true,
        type: {
          name: "String",
        },
      },
      description: {
        serializedName: "description",
        required: true,
        type: {
          name: "String",
        },
      },
      type: {
        serializedName: "type",
        required: true,
        type: {
          name: "Enum",
          allowedValues: ["marketing", "transactional"],
        },
      },
      accountId: {
        serializedName: "accountId",
        required: true,
        type: {
          name: "Number",
        },
      },
      isDefault: {
        serializedName: "isDefault",
        type: {
          name: "Boolean",
        },
      },
      isArchived: {
        serializedName: "isArchived",
        type: {
          name: "Boolean",
        },
      },
    },
  },
};

export const PickIcommunicationCategoryNameOrDescription: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "PickIcommunicationCategoryNameOrDescription",
    modelProperties: {
      name: {
        serializedName: "name",
        required: true,
        type: {
          name: "String",
        },
      },
      description: {
        serializedName: "description",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const PathsZb8RjpSettingsCommunicationCategoriesPostResponses200ContentApplicationJsonSchema: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "PathsZb8RjpSettingsCommunicationCategoriesPostResponses200ContentApplicationJsonSchema",
    modelProperties: {
      item: {
        serializedName: "item",
        type: {
          name: "Composite",
          className: "ICommunicationCategory",
        },
      },
    },
  },
};

export const PickIcommunicationCategoryNameOrDescriptionOrIsArchived: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "PickIcommunicationCategoryNameOrDescriptionOrIsArchived",
    modelProperties: {
      name: {
        serializedName: "name",
        required: true,
        type: {
          name: "String",
        },
      },
      description: {
        serializedName: "description",
        required: true,
        type: {
          name: "String",
        },
      },
      isArchived: {
        serializedName: "isArchived",
        type: {
          name: "Boolean",
        },
      },
    },
  },
};

export const Paths17Coml5SettingsCommunicationCategoriesIdPatchResponses200ContentApplicationJsonSchema: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "Paths17Coml5SettingsCommunicationCategoriesIdPatchResponses200ContentApplicationJsonSchema",
    modelProperties: {
      item: {
        serializedName: "item",
        type: {
          name: "Composite",
          className: "ICommunicationCategory",
        },
      },
    },
  },
};

export const Paths7A3UwnCommunicationPreferencesGetResponses200ContentApplicationJsonSchema: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "Paths7A3UwnCommunicationPreferencesGetResponses200ContentApplicationJsonSchema",
    modelProperties: {
      communicationPreferencesSnippets: {
        serializedName: "communicationPreferencesSnippets",
        required: true,
        type: {
          name: "Dictionary",
          value: { type: { name: "String" } },
        },
      },
      accountLogo: {
        serializedName: "accountLogo",
        required: true,
        type: {
          name: "String",
        },
      },
      accountName: {
        serializedName: "accountName",
        required: true,
        type: {
          name: "String",
        },
      },
      accountSite: {
        serializedName: "accountSite",
        required: true,
        type: {
          name: "String",
        },
      },
      personUnsubscribed: {
        serializedName: "personUnsubscribed",
        required: true,
        type: {
          name: "any",
        },
      },
      categoryId: {
        serializedName: "categoryId",
        required: true,
        type: {
          name: "Number",
        },
      },
      categoriesForPerson: {
        serializedName: "categoriesForPerson",
        required: true,
        type: {
          name: "Dictionary",
          value: { type: { name: "String" } },
        },
      },
      categories: {
        serializedName: "categories",
        required: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ICommunicationCategory",
            },
          },
        },
      },
      person: {
        serializedName: "person",
        type: {
          name: "Composite",
          className:
            "PathsRwag2PCommunicationPreferencesGetResponses200ContentApplicationJsonSchemaPropertiesPerson",
        },
      },
      accountId: {
        serializedName: "accountId",
        required: true,
        type: {
          name: "Number",
        },
      },
    },
  },
};

export const PathsRwag2PCommunicationPreferencesGetResponses200ContentApplicationJsonSchemaPropertiesPerson: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "PathsRwag2PCommunicationPreferencesGetResponses200ContentApplicationJsonSchemaPropertiesPerson",
    modelProperties: {
      email: {
        serializedName: "email",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const PathsVn8UerSettingsEmailAddressesGetResponses200ContentApplicationJsonSchema: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "PathsVn8UerSettingsEmailAddressesGetResponses200ContentApplicationJsonSchema",
    modelProperties: {
      emails: {
        serializedName: "emails",
        required: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "IEmailAddressVerification",
            },
          },
        },
      },
    },
  },
};

export const IEmailAddressVerification: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "IEmailAddressVerification",
    modelProperties: {
      email: {
        serializedName: "email",
        required: true,
        type: {
          name: "String",
        },
      },
      status: {
        serializedName: "status",
        required: true,
        type: {
          name: "Enum",
          allowedValues: ["verified", "pending"],
        },
      },
      accountId: {
        serializedName: "accountId",
        required: true,
        type: {
          name: "Number",
        },
      },
      data: {
        serializedName: "data",
        required: true,
        type: {
          name: "any",
        },
      },
      createdAt: {
        serializedName: "createdAt",
        type: {
          name: "DateTime",
        },
      },
      updatedAt: {
        serializedName: "updatedAt",
        type: {
          name: "DateTime",
        },
      },
    },
  },
};

export const Paths1Aso2SlSettingsEmailAddressesPostRequestbodyContentApplicationJsonSchema: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "Paths1Aso2SlSettingsEmailAddressesPostRequestbodyContentApplicationJsonSchema",
    modelProperties: {
      email: {
        serializedName: "email",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const Paths1Ehqlq6SettingsEmailAddressesPostResponses200ContentApplicationJsonSchema: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "Paths1Ehqlq6SettingsEmailAddressesPostResponses200ContentApplicationJsonSchema",
    modelProperties: {
      email: {
        serializedName: "email",
        type: {
          name: "Composite",
          className: "IEmailAddressVerification",
        },
      },
    },
  },
};

export const Paths1Ytfn4OEventSchemasMetricsGetResponses200ContentApplicationJsonSchema: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "Paths1Ytfn4OEventSchemasMetricsGetResponses200ContentApplicationJsonSchema",
    modelProperties: {
      metrics: {
        serializedName: "metrics",
        required: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className:
                "PathsWxmm9MEventSchemasMetricsGetResponses200ContentApplicationJsonSchemaPropertiesMetricsItems",
            },
          },
        },
      },
    },
  },
};

export const PathsWxmm9MEventSchemasMetricsGetResponses200ContentApplicationJsonSchemaPropertiesMetricsItems: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "PathsWxmm9MEventSchemasMetricsGetResponses200ContentApplicationJsonSchemaPropertiesMetricsItems",
    modelProperties: {
      lastSeen: {
        serializedName: "lastSeen",
        required: true,
        type: {
          name: "any",
        },
      },
      metrics: {
        serializedName: "metrics",
        required: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "Get200ApplicationJsonPropertiesItemsMetricsItem",
            },
          },
        },
      },
      id: {
        serializedName: "id",
        required: true,
        type: {
          name: "Number",
        },
      },
    },
  },
};

export const Get200ApplicationJsonPropertiesItemsMetricsItem: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "Get200ApplicationJsonPropertiesItemsMetricsItem",
    modelProperties: {
      count: {
        serializedName: "count",
        required: true,
        type: {
          name: "Number",
        },
      },
      date: {
        serializedName: "date",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const PathsXkjs0WEventSchemasMetricGetResponses200ContentApplicationJsonSchema: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "PathsXkjs0WEventSchemasMetricGetResponses200ContentApplicationJsonSchema",
    modelProperties: {
      metrics: {
        serializedName: "metrics",
        type: {
          name: "Composite",
          className:
            "Paths7Whg7ZEventSchemasMetricGetResponses200ContentApplicationJsonSchemaPropertiesMetrics",
        },
      },
      totalPeople: {
        serializedName: "totalPeople",
        type: {
          name: "Composite",
          className:
            "Paths1J7Ajo8EventSchemasMetricGetResponses200ContentApplicationJsonSchemaPropertiesTotalpeople",
        },
      },
      totalEvents: {
        serializedName: "totalEvents",
        type: {
          name: "Composite",
          className:
            "PathsE2CqqnEventSchemasMetricGetResponses200ContentApplicationJsonSchemaPropertiesTotalevents",
        },
      },
    },
  },
};

export const Paths7Whg7ZEventSchemasMetricGetResponses200ContentApplicationJsonSchemaPropertiesMetrics: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "Paths7Whg7ZEventSchemasMetricGetResponses200ContentApplicationJsonSchemaPropertiesMetrics",
    modelProperties: {
      lastSeen: {
        serializedName: "lastSeen",
        required: true,
        type: {
          name: "any",
        },
      },
      metrics: {
        serializedName: "metrics",
        required: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "Get200ApplicationJsonPropertiesItemsItem",
            },
          },
        },
      },
      id: {
        serializedName: "id",
        required: true,
        type: {
          name: "Number",
        },
      },
    },
  },
};

export const Get200ApplicationJsonPropertiesItemsItem: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "Get200ApplicationJsonPropertiesItemsItem",
    modelProperties: {
      count: {
        serializedName: "count",
        required: true,
        type: {
          name: "Number",
        },
      },
      date: {
        serializedName: "date",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const Paths1J7Ajo8EventSchemasMetricGetResponses200ContentApplicationJsonSchemaPropertiesTotalpeople: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "Paths1J7Ajo8EventSchemasMetricGetResponses200ContentApplicationJsonSchemaPropertiesTotalpeople",
    modelProperties: {
      count: {
        serializedName: "count",
        required: true,
        type: {
          name: "Number",
        },
      },
      id: {
        serializedName: "id",
        required: true,
        type: {
          name: "Number",
        },
      },
    },
  },
};

export const PathsE2CqqnEventSchemasMetricGetResponses200ContentApplicationJsonSchemaPropertiesTotalevents: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "PathsE2CqqnEventSchemasMetricGetResponses200ContentApplicationJsonSchemaPropertiesTotalevents",
    modelProperties: {
      count: {
        serializedName: "count",
        required: true,
        type: {
          name: "Number",
        },
      },
      id: {
        serializedName: "id",
        required: true,
        type: {
          name: "Number",
        },
      },
    },
  },
};

export const Paths1Iie06EventSchemasGetResponses200ContentApplicationJsonSchema: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "Paths1Iie06EventSchemasGetResponses200ContentApplicationJsonSchema",
    modelProperties: {
      count: {
        serializedName: "count",
        required: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className:
                "Paths16Xax55EventSchemasGetResponses200ContentApplicationJsonSchemaPropertiesCountItems",
            },
          },
        },
      },
      hasMore: {
        serializedName: "hasMore",
        required: true,
        type: {
          name: "Boolean",
        },
      },
      eventSchemas: {
        serializedName: "eventSchemas",
        required: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "IEventSchema",
            },
          },
        },
      },
    },
  },
};

export const Paths16Xax55EventSchemasGetResponses200ContentApplicationJsonSchemaPropertiesCountItems: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "Paths16Xax55EventSchemasGetResponses200ContentApplicationJsonSchemaPropertiesCountItems",
    modelProperties: {
      count: {
        serializedName: "count",
        required: true,
        type: {
          name: "any",
        },
      },
      source: {
        serializedName: "source",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const IEventSchema: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "IEventSchema",
    modelProperties: {
      id: {
        serializedName: "id",
        required: true,
        type: {
          name: "Number",
        },
      },
      name: {
        serializedName: "name",
        required: true,
        type: {
          name: "String",
        },
      },
      accountId: {
        serializedName: "accountId",
        required: true,
        type: {
          name: "Number",
        },
      },
      label: {
        serializedName: "label",
        required: true,
        type: {
          name: "String",
        },
      },
      source: {
        serializedName: "source",
        required: true,
        type: {
          name: "String",
        },
      },
      description: {
        serializedName: "description",
        required: true,
        type: {
          name: "String",
        },
      },
      properties: {
        serializedName: "properties",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "IEventSchemaProperty",
            },
          },
        },
      },
      createdAt: {
        serializedName: "createdAt",
        required: true,
        type: {
          name: "DateTime",
        },
      },
      updatedAt: {
        serializedName: "updatedAt",
        required: true,
        type: {
          name: "DateTime",
        },
      },
      labelTemplate: {
        serializedName: "labelTemplate",
        type: {
          name: "String",
        },
      },
      immutable: {
        serializedName: "immutable",
        type: {
          name: "Boolean",
        },
      },
    },
  },
};

export const IEventSchemaProperty: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "IEventSchemaProperty",
    modelProperties: {
      id: {
        serializedName: "id",
        required: true,
        type: {
          name: "Number",
        },
      },
      type: {
        serializedName: "type",
        required: true,
        type: {
          name: "Enum",
          allowedValues: [
            "string",
            "number",
            "boolean",
            "array",
            "object",
            "date-time",
            "date",
          ],
        },
      },
      eventSchemaId: {
        serializedName: "eventSchemaId",
        required: true,
        type: {
          name: "Number",
        },
      },
      name: {
        serializedName: "name",
        required: true,
        type: {
          name: "String",
        },
      },
      path: {
        serializedName: "path",
        required: true,
        type: {
          name: "String",
        },
      },
      nameTemplate: {
        serializedName: "nameTemplate",
        type: {
          name: "String",
        },
      },
      description: {
        serializedName: "description",
        type: {
          name: "String",
        },
      },
      displayType: {
        serializedName: "displayType",
        type: {
          name: "Enum",
          allowedValues: [
            "text",
            "textarea",
            "date",
            "datetime",
            "number",
            "select",
            "multiselect",
            "checkbox",
            "radio",
            "email",
            "url",
            "phone",
            "integer",
            "decimal",
            "currency",
            "currencyCents",
          ],
        },
      },
      enum: {
        serializedName: "enum",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String",
            },
          },
        },
      },
      enumNames: {
        serializedName: "enumNames",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String",
            },
          },
        },
      },
      createdAt: {
        serializedName: "createdAt",
        required: true,
        type: {
          name: "DateTime",
        },
      },
      updatedAt: {
        serializedName: "updatedAt",
        required: true,
        type: {
          name: "DateTime",
        },
      },
      example: {
        serializedName: "example",
        type: {
          name: "any",
        },
      },
      data: {
        serializedName: "data",
        type: {
          name: "any",
        },
      },
    },
  },
};

export const PartialIeventSchema: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "PartialIeventSchema",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Number",
        },
      },
      name: {
        serializedName: "name",
        type: {
          name: "String",
        },
      },
      accountId: {
        serializedName: "accountId",
        type: {
          name: "Number",
        },
      },
      label: {
        serializedName: "label",
        type: {
          name: "String",
        },
      },
      source: {
        serializedName: "source",
        type: {
          name: "String",
        },
      },
      description: {
        serializedName: "description",
        type: {
          name: "String",
        },
      },
      properties: {
        serializedName: "properties",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "IEventSchemaProperty",
            },
          },
        },
      },
      createdAt: {
        serializedName: "createdAt",
        type: {
          name: "DateTime",
        },
      },
      updatedAt: {
        serializedName: "updatedAt",
        type: {
          name: "DateTime",
        },
      },
      labelTemplate: {
        serializedName: "labelTemplate",
        type: {
          name: "String",
        },
      },
      immutable: {
        serializedName: "immutable",
        type: {
          name: "Boolean",
        },
      },
    },
  },
};

export const PathsTatrw3EventSchemasPostResponses200ContentApplicationJsonSchema: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "PathsTatrw3EventSchemasPostResponses200ContentApplicationJsonSchema",
    modelProperties: {
      eventSchema: {
        serializedName: "eventSchema",
        type: {
          name: "Composite",
          className: "IEventSchema",
        },
      },
    },
  },
};

export const PartialIeventSchemaProperty: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "PartialIeventSchemaProperty",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Number",
        },
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "string",
            "number",
            "boolean",
            "array",
            "object",
            "date-time",
            "date",
          ],
        },
      },
      eventSchemaId: {
        serializedName: "eventSchemaId",
        type: {
          name: "Number",
        },
      },
      name: {
        serializedName: "name",
        type: {
          name: "String",
        },
      },
      path: {
        serializedName: "path",
        type: {
          name: "String",
        },
      },
      nameTemplate: {
        serializedName: "nameTemplate",
        type: {
          name: "String",
        },
      },
      description: {
        serializedName: "description",
        type: {
          name: "String",
        },
      },
      displayType: {
        serializedName: "displayType",
        type: {
          name: "Enum",
          allowedValues: [
            "text",
            "textarea",
            "date",
            "datetime",
            "number",
            "select",
            "multiselect",
            "checkbox",
            "radio",
            "email",
            "url",
            "phone",
            "integer",
            "decimal",
            "currency",
            "currencyCents",
          ],
        },
      },
      enum: {
        serializedName: "enum",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String",
            },
          },
        },
      },
      enumNames: {
        serializedName: "enumNames",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String",
            },
          },
        },
      },
      createdAt: {
        serializedName: "createdAt",
        type: {
          name: "DateTime",
        },
      },
      updatedAt: {
        serializedName: "updatedAt",
        type: {
          name: "DateTime",
        },
      },
      example: {
        serializedName: "example",
        type: {
          name: "any",
        },
      },
      data: {
        serializedName: "data",
        type: {
          name: "any",
        },
      },
    },
  },
};

export const Paths14Yt020EventSchemasPropertyIdPutResponses200ContentApplicationJsonSchema: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "Paths14Yt020EventSchemasPropertyIdPutResponses200ContentApplicationJsonSchema",
    modelProperties: {
      eventSchemaProperty: {
        serializedName: "eventSchemaProperty",
        type: {
          name: "Composite",
          className: "IEventSchemaProperty",
        },
      },
    },
  },
};

export const Paths1Fnr0Q8EventSchemasPropertyIdDeleteResponses200ContentApplicationJsonSchema: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "Paths1Fnr0Q8EventSchemasPropertyIdDeleteResponses200ContentApplicationJsonSchema",
    modelProperties: {
      success: {
        serializedName: "success",
        required: true,
        type: {
          name: "Boolean",
        },
      },
    },
  },
};

export const PathsLxuiteEventSchemasPropertyPostResponses200ContentApplicationJsonSchema: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "PathsLxuiteEventSchemasPropertyPostResponses200ContentApplicationJsonSchema",
    modelProperties: {
      eventSchemaProperty: {
        serializedName: "eventSchemaProperty",
        type: {
          name: "Composite",
          className: "IEventSchemaProperty",
        },
      },
    },
  },
};

export const Paths11Sw186EventSchemasEventschemaidEndUserEventsGetResponses200ContentApplicationJsonSchema: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "Paths11Sw186EventSchemasEventschemaidEndUserEventsGetResponses200ContentApplicationJsonSchema",
    modelProperties: {
      events: {
        serializedName: "events",
        required: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Dictionary",
              value: { type: { name: "any" } },
            },
          },
        },
      },
    },
  },
};

export const Paths1SrtqjlEventSchemasIdPutResponses200ContentApplicationJsonSchema: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "Paths1SrtqjlEventSchemasIdPutResponses200ContentApplicationJsonSchema",
    modelProperties: {
      eventSchema: {
        serializedName: "eventSchema",
        type: {
          name: "Composite",
          className: "IEventSchema",
        },
      },
    },
  },
};

export const Paths17LvrntEventSchemasEventschemaidGetResponses200ContentApplicationJsonSchema: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "Paths17LvrntEventSchemasEventschemaidGetResponses200ContentApplicationJsonSchema",
    modelProperties: {
      eventSchema: {
        serializedName: "eventSchema",
        type: {
          name: "Composite",
          className: "IEventSchema",
        },
      },
    },
  },
};

export const PathsK1JqbfFormsIdGetResponses200ContentApplicationJsonSchema: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "PathsK1JqbfFormsIdGetResponses200ContentApplicationJsonSchema",
    modelProperties: {
      form: {
        serializedName: "form",
        type: {
          name: "Composite",
          className:
            "Paths14BzineFormsIdGetResponses200ContentApplicationJsonSchemaPropertiesForm",
        },
      },
    },
  },
};

export const IForm: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "IForm",
    modelProperties: {
      id: {
        serializedName: "id",
        required: true,
        type: {
          name: "String",
        },
      },
      name: {
        serializedName: "name",
        required: true,
        type: {
          name: "String",
        },
      },
      isActive: {
        serializedName: "isActive",
        required: true,
        type: {
          name: "Boolean",
        },
      },
      data: {
        serializedName: "data",
        type: {
          name: "Composite",
          className: "IFormData",
        },
      },
      draftForm: {
        serializedName: "draftForm",
        type: {
          name: "Composite",
          className: "IForm",
        },
      },
      accountId: {
        serializedName: "accountId",
        required: true,
        type: {
          name: "Number",
        },
      },
      archived: {
        serializedName: "archived",
        required: true,
        type: {
          name: "Boolean",
        },
      },
      createdBy: {
        serializedName: "createdBy",
        type: {
          name: "Number",
        },
      },
      createdAt: {
        serializedName: "createdAt",
        required: true,
        type: {
          name: "DateTime",
        },
      },
      formObjects: {
        serializedName: "formObjects",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "IFormObjectBase",
            },
          },
        },
      },
      updatedAt: {
        serializedName: "updatedAt",
        required: true,
        type: {
          name: "DateTime",
        },
      },
    },
  },
};

export const IFormData: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "IFormData",
    modelProperties: {
      formStyle: {
        serializedName: "formStyle",
        type: {
          name: "any",
        },
      },
      customCss: {
        serializedName: "customCss",
        type: {
          name: "String",
        },
      },
      emailNotifications: {
        serializedName: "emailNotifications",
        type: {
          name: "Composite",
          className: "IFormDataEmailNotifications",
        },
      },
      removePoweredBy: {
        serializedName: "removePoweredBy",
        type: {
          name: "Boolean",
        },
      },
      submitMessage: {
        serializedName: "submitMessage",
        type: {
          name: "Composite",
          className: "IFormDataSubmitMessage",
        },
      },
      tags: {
        serializedName: "tags",
        type: {
          name: "Composite",
          className: "IFormDataTags",
        },
      },
      redirectPage: {
        serializedName: "redirectPage",
        type: {
          name: "Composite",
          className: "IFormDataRedirectPage",
        },
      },
      successMessage: {
        serializedName: "successMessage",
        type: {
          name: "Composite",
          className: "IFormDataSuccessMessage",
        },
      },
      html: {
        serializedName: "html",
        type: {
          name: "String",
        },
      },
      successPageExport: {
        serializedName: "successPageExport",
        type: {
          name: "Composite",
          className: "IFormDataSuccessPageExport",
        },
      },
      formExport: {
        serializedName: "formExport",
        type: {
          name: "Composite",
          className: "IFormDataFormExport",
        },
      },
    },
  },
};

export const IFormDataEmailNotifications: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "IFormDataEmailNotifications",
    modelProperties: {
      user: {
        serializedName: "user",
        type: {
          name: "Composite",
          className: "IFormDataEmailNotificationsUser",
        },
      },
      teamMember: {
        serializedName: "teamMember",
        type: {
          name: "Composite",
          className: "IFormDataEmailNotificationsTeamMember",
        },
      },
    },
  },
};

export const IFormDataEmailNotificationsUser: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "IFormDataEmailNotificationsUser",
    modelProperties: {
      message: {
        serializedName: "message",
        type: {
          name: "String",
        },
      },
      subject: {
        serializedName: "subject",
        type: {
          name: "String",
        },
      },
      isActive: {
        serializedName: "isActive",
        type: {
          name: "Boolean",
        },
      },
    },
  },
};

export const IFormDataEmailNotificationsTeamMember: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "IFormDataEmailNotificationsTeamMember",
    modelProperties: {
      message: {
        serializedName: "message",
        type: {
          name: "String",
        },
      },
      subject: {
        serializedName: "subject",
        type: {
          name: "String",
        },
      },
      recipients: {
        serializedName: "recipients",
        type: {
          name: "String",
        },
      },
      isActive: {
        serializedName: "isActive",
        type: {
          name: "Boolean",
        },
      },
    },
  },
};

export const IFormDataSubmitMessage: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "IFormDataSubmitMessage",
    modelProperties: {
      text: {
        serializedName: "text",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const IFormDataTags: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "IFormDataTags",
    modelProperties: {
      isActive: {
        serializedName: "isActive",
        required: true,
        type: {
          name: "Boolean",
        },
      },
      values: {
        serializedName: "values",
        required: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String",
            },
          },
        },
      },
    },
  },
};

export const IFormDataRedirectPage: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "IFormDataRedirectPage",
    modelProperties: {
      isActive: {
        serializedName: "isActive",
        required: true,
        type: {
          name: "Boolean",
        },
      },
      text: {
        serializedName: "text",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const IFormDataSuccessMessage: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "IFormDataSuccessMessage",
    modelProperties: {
      isActive: {
        serializedName: "isActive",
        required: true,
        type: {
          name: "Boolean",
        },
      },
      text: {
        serializedName: "text",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const IFormDataSuccessPageExport: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "IFormDataSuccessPageExport",
    modelProperties: {
      css: {
        serializedName: "css",
        required: true,
        type: {
          name: "String",
        },
      },
      html: {
        serializedName: "html",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const IFormDataFormExport: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "IFormDataFormExport",
    modelProperties: {
      css: {
        serializedName: "css",
        required: true,
        type: {
          name: "String",
        },
      },
      html: {
        serializedName: "html",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const IFormObjectBase: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "IFormObjectBase",
    modelProperties: {
      id: {
        serializedName: "id",
        required: true,
        type: {
          name: "String",
        },
      },
      formId: {
        serializedName: "formId",
        required: true,
        type: {
          name: "String",
        },
      },
      name: {
        serializedName: "name",
        type: {
          name: "String",
        },
      },
      archived: {
        serializedName: "archived",
        required: true,
        type: {
          name: "Boolean",
        },
      },
      order: {
        serializedName: "order",
        required: true,
        type: {
          name: "Number",
        },
      },
      createdAt: {
        serializedName: "createdAt",
        required: true,
        type: {
          name: "DateTime",
        },
      },
      updatedAt: {
        serializedName: "updatedAt",
        required: true,
        type: {
          name: "DateTime",
        },
      },
      personField: {
        serializedName: "personField",
        required: true,
        type: {
          name: "String",
        },
      },
      inputType: {
        serializedName: "inputType",
        required: true,
        type: {
          name: "Enum",
          allowedValues: [
            "text",
            "textarea",
            "number",
            "checkbox",
            "date",
            "select",
            "email",
            "multi-select",
          ],
        },
      },
      draftFormObject: {
        serializedName: "draftFormObject",
        type: {
          name: "Composite",
          className: "IFormObjectBase",
        },
      },
      data: {
        serializedName: "data",
        type: {
          name: "any",
        },
      },
      formObjectType: {
        serializedName: "formObjectType",
        required: true,
        type: {
          name: "Enum",
          allowedValues: ["input", "text", "marketing-consent"],
        },
      },
    },
  },
};

export const Paths53H43BFormsIdGetResponses200ContentApplicationJsonSchemaPropertiesFormAllof1: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "Paths53H43BFormsIdGetResponses200ContentApplicationJsonSchemaPropertiesFormAllof1",
    modelProperties: {
      metric: {
        serializedName: "metric",
        type: {
          name: "Composite",
          className: "IFormMetric",
        },
      },
    },
  },
};

export const IFormMetric: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "IFormMetric",
    modelProperties: {
      view: {
        serializedName: "view",
        required: true,
        type: {
          name: "Number",
        },
      },
      submission: {
        serializedName: "submission",
        required: true,
        type: {
          name: "Number",
        },
      },
    },
  },
};

export const PartialIform: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "PartialIform",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "String",
        },
      },
      name: {
        serializedName: "name",
        type: {
          name: "String",
        },
      },
      isActive: {
        serializedName: "isActive",
        type: {
          name: "Boolean",
        },
      },
      data: {
        serializedName: "data",
        type: {
          name: "Composite",
          className: "PartialIformData",
        },
      },
      draftForm: {
        serializedName: "draftForm",
        type: {
          name: "Composite",
          className: "IForm",
        },
      },
      accountId: {
        serializedName: "accountId",
        type: {
          name: "Number",
        },
      },
      archived: {
        serializedName: "archived",
        type: {
          name: "Boolean",
        },
      },
      createdBy: {
        serializedName: "createdBy",
        type: {
          name: "Number",
        },
      },
      createdAt: {
        serializedName: "createdAt",
        type: {
          name: "DateTime",
        },
      },
      formObjects: {
        serializedName: "formObjects",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "IFormObjectBase",
            },
          },
        },
      },
      updatedAt: {
        serializedName: "updatedAt",
        type: {
          name: "DateTime",
        },
      },
    },
  },
};

export const PartialIformData: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "PartialIformData",
    modelProperties: {
      formStyle: {
        serializedName: "formStyle",
        type: {
          name: "any",
        },
      },
      customCss: {
        serializedName: "customCss",
        type: {
          name: "String",
        },
      },
      emailNotifications: {
        serializedName: "emailNotifications",
        type: {
          name: "Composite",
          className: "PartialIformDataEmailNotifications",
        },
      },
      removePoweredBy: {
        serializedName: "removePoweredBy",
        type: {
          name: "Boolean",
        },
      },
      submitMessage: {
        serializedName: "submitMessage",
        type: {
          name: "Composite",
          className: "PartialIformDataSubmitMessage",
        },
      },
      tags: {
        serializedName: "tags",
        type: {
          name: "Composite",
          className: "PartialIformDataTags",
        },
      },
      redirectPage: {
        serializedName: "redirectPage",
        type: {
          name: "Composite",
          className: "PartialIformDataRedirectPage",
        },
      },
      successMessage: {
        serializedName: "successMessage",
        type: {
          name: "Composite",
          className: "PartialIformDataSuccessMessage",
        },
      },
      html: {
        serializedName: "html",
        type: {
          name: "String",
        },
      },
      successPageExport: {
        serializedName: "successPageExport",
        type: {
          name: "Composite",
          className: "PartialIformDataSuccessPageExport",
        },
      },
      formExport: {
        serializedName: "formExport",
        type: {
          name: "Composite",
          className: "PartialIformDataFormExport",
        },
      },
    },
  },
};

export const PartialIformDataEmailNotifications: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "PartialIformDataEmailNotifications",
    modelProperties: {
      user: {
        serializedName: "user",
        type: {
          name: "Composite",
          className: "PartialIformDataEmailNotificationsUser",
        },
      },
      teamMember: {
        serializedName: "teamMember",
        type: {
          name: "Composite",
          className: "PartialIformDataEmailNotificationsTeamMember",
        },
      },
    },
  },
};

export const PartialIformDataEmailNotificationsUser: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "PartialIformDataEmailNotificationsUser",
    modelProperties: {
      message: {
        serializedName: "message",
        type: {
          name: "String",
        },
      },
      subject: {
        serializedName: "subject",
        type: {
          name: "String",
        },
      },
      isActive: {
        serializedName: "isActive",
        type: {
          name: "Boolean",
        },
      },
    },
  },
};

export const PartialIformDataEmailNotificationsTeamMember: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "PartialIformDataEmailNotificationsTeamMember",
    modelProperties: {
      message: {
        serializedName: "message",
        type: {
          name: "String",
        },
      },
      subject: {
        serializedName: "subject",
        type: {
          name: "String",
        },
      },
      recipients: {
        serializedName: "recipients",
        type: {
          name: "String",
        },
      },
      isActive: {
        serializedName: "isActive",
        type: {
          name: "Boolean",
        },
      },
    },
  },
};

export const PartialIformDataSubmitMessage: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "PartialIformDataSubmitMessage",
    modelProperties: {
      text: {
        serializedName: "text",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const PartialIformDataTags: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "PartialIformDataTags",
    modelProperties: {
      isActive: {
        serializedName: "isActive",
        required: true,
        type: {
          name: "Boolean",
        },
      },
      values: {
        serializedName: "values",
        required: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String",
            },
          },
        },
      },
    },
  },
};

export const PartialIformDataRedirectPage: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "PartialIformDataRedirectPage",
    modelProperties: {
      isActive: {
        serializedName: "isActive",
        required: true,
        type: {
          name: "Boolean",
        },
      },
      text: {
        serializedName: "text",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const PartialIformDataSuccessMessage: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "PartialIformDataSuccessMessage",
    modelProperties: {
      isActive: {
        serializedName: "isActive",
        required: true,
        type: {
          name: "Boolean",
        },
      },
      text: {
        serializedName: "text",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const PartialIformDataSuccessPageExport: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "PartialIformDataSuccessPageExport",
    modelProperties: {
      css: {
        serializedName: "css",
        required: true,
        type: {
          name: "String",
        },
      },
      html: {
        serializedName: "html",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const PartialIformDataFormExport: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "PartialIformDataFormExport",
    modelProperties: {
      css: {
        serializedName: "css",
        required: true,
        type: {
          name: "String",
        },
      },
      html: {
        serializedName: "html",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const PathsYjrkutFormsIdPutResponses200ContentApplicationJsonSchema: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "PathsYjrkutFormsIdPutResponses200ContentApplicationJsonSchema",
    modelProperties: {
      item: {
        serializedName: "item",
        type: {
          name: "Composite",
          className: "Put200ApplicationJsonProperties",
        },
      },
    },
  },
};

export const Paths1Vvx8HpFormsIdPutResponses200ContentApplicationJsonSchemaPropertiesItemAllof1: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "Paths1Vvx8HpFormsIdPutResponses200ContentApplicationJsonSchemaPropertiesItemAllof1",
    modelProperties: {
      metric: {
        serializedName: "metric",
        type: {
          name: "Composite",
          className: "IFormMetric",
        },
      },
    },
  },
};

export const Paths1Or4EltFormsIdDeleteResponses200ContentApplicationJsonSchema: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "Paths1Or4EltFormsIdDeleteResponses200ContentApplicationJsonSchema",
    modelProperties: {
      item: {
        serializedName: "item",
        required: true,
        type: {
          name: "Boolean",
        },
      },
    },
  },
};

export const PathsFnybiwFormsIdSharedGetResponses200ContentApplicationJsonSchema: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "PathsFnybiwFormsIdSharedGetResponses200ContentApplicationJsonSchema",
    modelProperties: {
      formStyle: {
        serializedName: "formStyle",
        required: true,
        type: {
          name: "any",
        },
      },
      customCss: {
        serializedName: "customCss",
        required: true,
        type: {
          name: "String",
        },
      },
      name: {
        serializedName: "name",
        required: true,
        type: {
          name: "String",
        },
      },
      successMessage: {
        serializedName: "successMessage",
        type: {
          name: "Composite",
          className:
            "PathsR1N9CeFormsIdSharedGetResponses200ContentApplicationJsonSchemaPropertiesSuccessmessage",
        },
      },
      removePoweredBy: {
        serializedName: "removePoweredBy",
        required: true,
        type: {
          name: "Boolean",
        },
      },
    },
  },
};

export const PathsR1N9CeFormsIdSharedGetResponses200ContentApplicationJsonSchemaPropertiesSuccessmessage: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "PathsR1N9CeFormsIdSharedGetResponses200ContentApplicationJsonSchemaPropertiesSuccessmessage",
    modelProperties: {
      isActive: {
        serializedName: "isActive",
        required: true,
        type: {
          name: "Boolean",
        },
      },
      text: {
        serializedName: "text",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const PathsH03OvyFormsGetResponses200ContentApplicationJsonSchema: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "PathsH03OvyFormsGetResponses200ContentApplicationJsonSchema",
    modelProperties: {
      forms: {
        serializedName: "forms",
        required: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className:
                "Paths7Am3U7FormsGetResponses200ContentApplicationJsonSchemaPropertiesFormsItems",
            },
          },
        },
      },
    },
  },
};

export const Paths2PqlpgFormsGetResponses200ContentApplicationJsonSchemaPropertiesFormsItemsAllof1: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "Paths2PqlpgFormsGetResponses200ContentApplicationJsonSchemaPropertiesFormsItemsAllof1",
    modelProperties: {
      metric: {
        serializedName: "metric",
        type: {
          name: "Composite",
          className: "IFormMetric",
        },
      },
    },
  },
};

export const PathsEbkoi1FormsPostResponses200ContentApplicationJsonSchema: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "PathsEbkoi1FormsPostResponses200ContentApplicationJsonSchema",
    modelProperties: {
      item: {
        serializedName: "item",
        type: {
          name: "Composite",
          className:
            "Paths1Bhz2A5FormsPostResponses200ContentApplicationJsonSchemaPropertiesItem",
        },
      },
    },
  },
};

export const PathsZl288GFormsPostResponses200ContentApplicationJsonSchemaPropertiesItemAllof1: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "PathsZl288GFormsPostResponses200ContentApplicationJsonSchemaPropertiesItemAllof1",
    modelProperties: {
      metric: {
        serializedName: "metric",
        type: {
          name: "Composite",
          className: "IFormMetric",
        },
      },
    },
  },
};

export const Paths1Spl9TcFormsIdSubmissionPostResponses200ContentApplicationJsonSchema: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "Paths1Spl9TcFormsIdSubmissionPostResponses200ContentApplicationJsonSchema",
  },
};

export const PathsPjr78QFormsIdSubmissionPostResponses200ContentApplicationJsonSchemaAnyof0: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "PathsPjr78QFormsIdSubmissionPostResponses200ContentApplicationJsonSchemaAnyof0",
    modelProperties: {
      stack: {
        serializedName: "stack",
        type: {
          name: "any",
        },
      },
      message: {
        serializedName: "message",
        type: {
          name: "any",
        },
      },
      body: {
        serializedName: "body",
        type: {
          name: "any",
        },
      },
      result: {
        serializedName: "result",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const PathsTbk1ZnFormsIdSubmissionPostResponses200ContentApplicationJsonSchemaAnyof1: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "PathsTbk1ZnFormsIdSubmissionPostResponses200ContentApplicationJsonSchemaAnyof1",
    modelProperties: {
      stack: {
        serializedName: "stack",
        type: {
          name: "any",
        },
      },
      message: {
        serializedName: "message",
        type: {
          name: "any",
        },
      },
      body: {
        serializedName: "body",
        required: true,
        type: {
          name: "any",
        },
      },
      result: {
        serializedName: "result",
        required: true,
        type: {
          name: "any",
        },
      },
    },
  },
};

export const PathsRvxc4JFormsIdSubmissionPostResponses200ContentApplicationJsonSchemaAnyof2: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "PathsRvxc4JFormsIdSubmissionPostResponses200ContentApplicationJsonSchemaAnyof2",
    modelProperties: {
      body: {
        serializedName: "body",
        type: {
          name: "any",
        },
      },
      result: {
        serializedName: "result",
        type: {
          name: "any",
        },
      },
      stack: {
        serializedName: "stack",
        required: true,
        type: {
          name: "String",
        },
      },
      message: {
        serializedName: "message",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const Paths11Ffj3RFormsIdSubmissionPlainPostResponses200ContentApplicationJsonSchema: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "Paths11Ffj3RFormsIdSubmissionPlainPostResponses200ContentApplicationJsonSchema",
  },
};

export const PathsE12Yc7FormsIdSubmissionPlainPostResponses200ContentApplicationJsonSchemaAnyof1: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "PathsE12Yc7FormsIdSubmissionPlainPostResponses200ContentApplicationJsonSchemaAnyof1",
    modelProperties: {
      stack: {
        serializedName: "stack",
        required: true,
        type: {
          name: "String",
        },
      },
      message: {
        serializedName: "message",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const PathsH1Oy21FormsIdDuplicatePostResponses200ContentApplicationJsonSchema: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "PathsH1Oy21FormsIdDuplicatePostResponses200ContentApplicationJsonSchema",
    modelProperties: {
      item: {
        serializedName: "item",
        type: {
          name: "Composite",
          className:
            "Paths1E3Jq5WFormsIdDuplicatePostResponses200ContentApplicationJsonSchemaPropertiesItem",
        },
      },
    },
  },
};

export const Paths1E3Jq5WFormsIdDuplicatePostResponses200ContentApplicationJsonSchemaPropertiesItem: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "Paths1E3Jq5WFormsIdDuplicatePostResponses200ContentApplicationJsonSchemaPropertiesItem",
    modelProperties: {
      metric: {
        serializedName: "metric",
        type: {
          name: "Composite",
          className: "IFormMetric",
        },
      },
      updatedAt: {
        serializedName: "updatedAt",
        required: true,
        type: {
          name: "DateTime",
        },
      },
      createdAt: {
        serializedName: "createdAt",
        required: true,
        type: {
          name: "DateTime",
        },
      },
      createdBy: {
        serializedName: "createdBy",
        type: {
          name: "Number",
        },
      },
      archived: {
        serializedName: "archived",
        required: true,
        type: {
          name: "Boolean",
        },
      },
      accountId: {
        serializedName: "accountId",
        required: true,
        type: {
          name: "Number",
        },
      },
      draftForm: {
        serializedName: "draftForm",
        type: {
          name: "Composite",
          className: "IForm",
        },
      },
      data: {
        serializedName: "data",
        type: {
          name: "Composite",
          className:
            "Paths1Vaw806FormsIdDuplicatePostResponses200ContentApplicationJsonSchemaPropertiesItemPropertiesData",
        },
      },
      isActive: {
        serializedName: "isActive",
        required: true,
        type: {
          name: "Boolean",
        },
      },
      name: {
        serializedName: "name",
        required: true,
        type: {
          name: "String",
        },
      },
      id: {
        serializedName: "id",
        required: true,
        type: {
          name: "String",
        },
      },
      formObjects: {
        serializedName: "formObjects",
        required: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "IFormObjectBase",
            },
          },
        },
      },
    },
  },
};

export const Paths1Vaw806FormsIdDuplicatePostResponses200ContentApplicationJsonSchemaPropertiesItemPropertiesData: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "Paths1Vaw806FormsIdDuplicatePostResponses200ContentApplicationJsonSchemaPropertiesItemPropertiesData",
    modelProperties: {
      formStyle: {
        serializedName: "formStyle",
        type: {
          name: "any",
        },
      },
      customCss: {
        serializedName: "customCss",
        type: {
          name: "String",
        },
      },
      emailNotifications: {
        serializedName: "emailNotifications",
        type: {
          name: "Composite",
          className:
            "PathsZf4E80FormsIdDuplicatePostResponses200ContentApplicationJsonSchemaPropertiesItemPropertiesDataPropertiesEmailnotifications",
        },
      },
      removePoweredBy: {
        serializedName: "removePoweredBy",
        type: {
          name: "Boolean",
        },
      },
      submitMessage: {
        serializedName: "submitMessage",
        type: {
          name: "Composite",
          className:
            "Paths1444I7IFormsIdDuplicatePostResponses200ContentApplicationJsonSchemaPropertiesItemPropertiesDataPropertiesSubmitmessage",
        },
      },
      tags: {
        serializedName: "tags",
        type: {
          name: "Composite",
          className:
            "Paths1Smabf8FormsIdDuplicatePostResponses200ContentApplicationJsonSchemaPropertiesItemPropertiesDataPropertiesTags",
        },
      },
      redirectPage: {
        serializedName: "redirectPage",
        type: {
          name: "Composite",
          className:
            "Paths4Q9DwuFormsIdDuplicatePostResponses200ContentApplicationJsonSchemaPropertiesItemPropertiesDataPropertiesRedirectpage",
        },
      },
      successMessage: {
        serializedName: "successMessage",
        type: {
          name: "Composite",
          className:
            "PathsRonq26FormsIdDuplicatePostResponses200ContentApplicationJsonSchemaPropertiesItemPropertiesDataPropertiesSuccessmessage",
        },
      },
      html: {
        serializedName: "html",
        type: {
          name: "String",
        },
      },
      successPageExport: {
        serializedName: "successPageExport",
        type: {
          name: "Composite",
          className:
            "PathsZk1E8OFormsIdDuplicatePostResponses200ContentApplicationJsonSchemaPropertiesItemPropertiesDataPropertiesSuccesspageexport",
        },
      },
      formExport: {
        serializedName: "formExport",
        type: {
          name: "Composite",
          className:
            "Paths1Q8LiikFormsIdDuplicatePostResponses200ContentApplicationJsonSchemaPropertiesItemPropertiesDataPropertiesFormexport",
        },
      },
    },
  },
};

export const PathsZf4E80FormsIdDuplicatePostResponses200ContentApplicationJsonSchemaPropertiesItemPropertiesDataPropertiesEmailnotifications: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "PathsZf4E80FormsIdDuplicatePostResponses200ContentApplicationJsonSchemaPropertiesItemPropertiesDataPropertiesEmailnotifications",
    modelProperties: {
      user: {
        serializedName: "user",
        type: {
          name: "Composite",
          className: "Post200ApplicationJsonProperties",
        },
      },
      teamMember: {
        serializedName: "teamMember",
        type: {
          name: "Composite",
          className:
            "Paths1Ie0DjmFormsIdDuplicatePostResponses200ContentApplicationJsonSchemaPropertiesItemPropertiesDataPropertiesEmailnotificationsPropertiesTeammember",
        },
      },
    },
  },
};

export const Post200ApplicationJsonProperties: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "Post200ApplicationJsonProperties",
    modelProperties: {
      message: {
        serializedName: "message",
        type: {
          name: "String",
        },
      },
      subject: {
        serializedName: "subject",
        type: {
          name: "String",
        },
      },
      isActive: {
        serializedName: "isActive",
        type: {
          name: "Boolean",
        },
      },
    },
  },
};

export const Paths1Ie0DjmFormsIdDuplicatePostResponses200ContentApplicationJsonSchemaPropertiesItemPropertiesDataPropertiesEmailnotificationsPropertiesTeammember: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "Paths1Ie0DjmFormsIdDuplicatePostResponses200ContentApplicationJsonSchemaPropertiesItemPropertiesDataPropertiesEmailnotificationsPropertiesTeammember",
    modelProperties: {
      message: {
        serializedName: "message",
        type: {
          name: "String",
        },
      },
      subject: {
        serializedName: "subject",
        type: {
          name: "String",
        },
      },
      recipients: {
        serializedName: "recipients",
        type: {
          name: "String",
        },
      },
      isActive: {
        serializedName: "isActive",
        type: {
          name: "Boolean",
        },
      },
    },
  },
};

export const Paths1444I7IFormsIdDuplicatePostResponses200ContentApplicationJsonSchemaPropertiesItemPropertiesDataPropertiesSubmitmessage: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "Paths1444I7IFormsIdDuplicatePostResponses200ContentApplicationJsonSchemaPropertiesItemPropertiesDataPropertiesSubmitmessage",
    modelProperties: {
      text: {
        serializedName: "text",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const Paths1Smabf8FormsIdDuplicatePostResponses200ContentApplicationJsonSchemaPropertiesItemPropertiesDataPropertiesTags: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "Paths1Smabf8FormsIdDuplicatePostResponses200ContentApplicationJsonSchemaPropertiesItemPropertiesDataPropertiesTags",
    modelProperties: {
      isActive: {
        serializedName: "isActive",
        required: true,
        type: {
          name: "Boolean",
        },
      },
      values: {
        serializedName: "values",
        required: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String",
            },
          },
        },
      },
    },
  },
};

export const Paths4Q9DwuFormsIdDuplicatePostResponses200ContentApplicationJsonSchemaPropertiesItemPropertiesDataPropertiesRedirectpage: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "Paths4Q9DwuFormsIdDuplicatePostResponses200ContentApplicationJsonSchemaPropertiesItemPropertiesDataPropertiesRedirectpage",
    modelProperties: {
      isActive: {
        serializedName: "isActive",
        required: true,
        type: {
          name: "Boolean",
        },
      },
      text: {
        serializedName: "text",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const PathsRonq26FormsIdDuplicatePostResponses200ContentApplicationJsonSchemaPropertiesItemPropertiesDataPropertiesSuccessmessage: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "PathsRonq26FormsIdDuplicatePostResponses200ContentApplicationJsonSchemaPropertiesItemPropertiesDataPropertiesSuccessmessage",
    modelProperties: {
      isActive: {
        serializedName: "isActive",
        required: true,
        type: {
          name: "Boolean",
        },
      },
      text: {
        serializedName: "text",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const PathsZk1E8OFormsIdDuplicatePostResponses200ContentApplicationJsonSchemaPropertiesItemPropertiesDataPropertiesSuccesspageexport: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "PathsZk1E8OFormsIdDuplicatePostResponses200ContentApplicationJsonSchemaPropertiesItemPropertiesDataPropertiesSuccesspageexport",
    modelProperties: {
      css: {
        serializedName: "css",
        required: true,
        type: {
          name: "String",
        },
      },
      html: {
        serializedName: "html",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const Paths1Q8LiikFormsIdDuplicatePostResponses200ContentApplicationJsonSchemaPropertiesItemPropertiesDataPropertiesFormexport: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "Paths1Q8LiikFormsIdDuplicatePostResponses200ContentApplicationJsonSchemaPropertiesItemPropertiesDataPropertiesFormexport",
    modelProperties: {
      css: {
        serializedName: "css",
        required: true,
        type: {
          name: "String",
        },
      },
      html: {
        serializedName: "html",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const Paths1Y3M2N2FormsIdResetOrderPutResponses200ContentApplicationJsonSchema: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "Paths1Y3M2N2FormsIdResetOrderPutResponses200ContentApplicationJsonSchema",
    modelProperties: {
      form: {
        serializedName: "form",
        type: {
          name: "Composite",
          className:
            "PathsOx3MmjFormsIdResetOrderPutResponses200ContentApplicationJsonSchemaPropertiesForm",
        },
      },
    },
  },
};

export const Paths174R2QuFormsIdResetOrderPutResponses200ContentApplicationJsonSchemaPropertiesFormAllof1: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "Paths174R2QuFormsIdResetOrderPutResponses200ContentApplicationJsonSchemaPropertiesFormAllof1",
    modelProperties: {
      metric: {
        serializedName: "metric",
        type: {
          name: "Composite",
          className: "IFormMetric",
        },
      },
    },
  },
};

export const Paths1Ylu4AdFormsIdDraftPutResponses200ContentApplicationJsonSchema: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "Paths1Ylu4AdFormsIdDraftPutResponses200ContentApplicationJsonSchema",
    modelProperties: {
      item: {
        serializedName: "item",
        type: {
          name: "Composite",
          className:
            "Paths177Hj9TFormsIdDraftPutResponses200ContentApplicationJsonSchemaPropertiesItem",
        },
      },
    },
  },
};

export const Paths10Rnv2RFormsIdDraftPutResponses200ContentApplicationJsonSchemaPropertiesItemAllof1: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "Paths10Rnv2RFormsIdDraftPutResponses200ContentApplicationJsonSchemaPropertiesItemAllof1",
    modelProperties: {
      metric: {
        serializedName: "metric",
        type: {
          name: "Composite",
          className: "IFormMetric",
        },
      },
    },
  },
};

export const PartialIformObject: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "PartialIformObject",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "String",
        },
      },
      formId: {
        serializedName: "formId",
        type: {
          name: "String",
        },
      },
      name: {
        serializedName: "name",
        type: {
          name: "String",
        },
      },
      archived: {
        serializedName: "archived",
        type: {
          name: "Boolean",
        },
      },
      order: {
        serializedName: "order",
        type: {
          name: "Number",
        },
      },
      createdAt: {
        serializedName: "createdAt",
        type: {
          name: "DateTime",
        },
      },
      updatedAt: {
        serializedName: "updatedAt",
        type: {
          name: "DateTime",
        },
      },
      personField: {
        serializedName: "personField",
        type: {
          name: "String",
        },
      },
      inputType: {
        serializedName: "inputType",
        type: {
          name: "Enum",
          allowedValues: [
            "text",
            "textarea",
            "number",
            "checkbox",
            "date",
            "select",
            "email",
            "multi-select",
          ],
        },
      },
      draftFormObject: {
        serializedName: "draftFormObject",
        type: {
          name: "Composite",
          className: "IFormObjectBase",
        },
      },
      data: {
        serializedName: "data",
        type: {
          name: "any",
        },
      },
      formObjectType: {
        serializedName: "formObjectType",
        type: {
          name: "Enum",
          allowedValues: ["input", "text", "marketing-consent"],
        },
      },
    },
  },
};

export const Paths17Tbz5UFormsFormObjectPostResponses200ContentApplicationJsonSchema: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "Paths17Tbz5UFormsFormObjectPostResponses200ContentApplicationJsonSchema",
    modelProperties: {
      item: {
        serializedName: "item",
        type: {
          name: "Composite",
          className: "IFormObjectBase",
        },
      },
    },
  },
};

export const Paths1Lsex58FormsFormObjectIdPutResponses200ContentApplicationJsonSchema: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "Paths1Lsex58FormsFormObjectIdPutResponses200ContentApplicationJsonSchema",
    modelProperties: {
      item: {
        serializedName: "item",
        type: {
          name: "Composite",
          className: "IFormObjectBase",
        },
      },
    },
  },
};

export const PathsV04CouFormsFormObjectIdDeleteResponses200ContentApplicationJsonSchema: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "PathsV04CouFormsFormObjectIdDeleteResponses200ContentApplicationJsonSchema",
    modelProperties: {
      status: {
        serializedName: "status",
        required: true,
        type: {
          name: "Boolean",
        },
      },
    },
  },
};

export const Paths5G35VrFormsFormObjectIdDraftPutResponses200ContentApplicationJsonSchema: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "Paths5G35VrFormsFormObjectIdDraftPutResponses200ContentApplicationJsonSchema",
    modelProperties: {
      item: {
        serializedName: "item",
        type: {
          name: "Composite",
          className: "IFormObjectBase",
        },
      },
    },
  },
};

export const PathsFvkyr8FormsIdSubmissionsGetResponses200ContentApplicationJsonSchema: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "PathsFvkyr8FormsIdSubmissionsGetResponses200ContentApplicationJsonSchema",
    modelProperties: {
      items: {
        serializedName: "items",
        required: true,
        type: {
          name: "any",
        },
      },
    },
  },
};

export const Paths1HhuezpFormsIdDraftPublishPutResponses200ContentApplicationJsonSchema: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "Paths1HhuezpFormsIdDraftPublishPutResponses200ContentApplicationJsonSchema",
    modelProperties: {
      form: {
        serializedName: "form",
        type: {
          name: "Composite",
          className:
            "Paths1Trgd5WFormsIdDraftPublishPutResponses200ContentApplicationJsonSchemaPropertiesForm",
        },
      },
    },
  },
};

export const Paths9Z3B9TFormsIdDraftPublishPutResponses200ContentApplicationJsonSchemaPropertiesFormAllof1: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "Paths9Z3B9TFormsIdDraftPublishPutResponses200ContentApplicationJsonSchemaPropertiesFormAllof1",
    modelProperties: {
      metric: {
        serializedName: "metric",
        type: {
          name: "Composite",
          className: "IFormMetric",
        },
      },
    },
  },
};

export const Paths14KddvwFormsIdDraftCancelPutResponses200ContentApplicationJsonSchema: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "Paths14KddvwFormsIdDraftCancelPutResponses200ContentApplicationJsonSchema",
    modelProperties: {
      form: {
        serializedName: "form",
        type: {
          name: "Composite",
          className:
            "Paths1GzwcdzFormsIdDraftCancelPutResponses200ContentApplicationJsonSchemaPropertiesForm",
        },
      },
    },
  },
};

export const PathsWspb0MFormsIdDraftCancelPutResponses200ContentApplicationJsonSchemaPropertiesFormAllof1: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "PathsWspb0MFormsIdDraftCancelPutResponses200ContentApplicationJsonSchemaPropertiesFormAllof1",
    modelProperties: {
      metric: {
        serializedName: "metric",
        type: {
          name: "Composite",
          className: "IFormMetric",
        },
      },
    },
  },
};

export const IntegrationDecoration: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "IntegrationDecoration",
    modelProperties: {
      id: {
        serializedName: "id",
        required: true,
        type: {
          name: "String",
        },
      },
      coordinates: {
        serializedName: "coordinates",
        type: {
          name: "Composite",
          className: "IntegrationDecorationCoordinates",
        },
      },
      dimensions: {
        serializedName: "dimensions",
        type: {
          name: "Composite",
          className: "IntegrationDecorationDimensions",
        },
      },
      integrationId: {
        serializedName: "integrationId",
        required: true,
        type: {
          name: "Number",
        },
      },
      type: {
        serializedName: "type",
        required: true,
        type: {
          name: "Enum",
          allowedValues: ["annotation", "note"],
        },
      },
      text: {
        serializedName: "text",
        required: true,
        type: {
          name: "String",
        },
      },
      fontSize: {
        serializedName: "fontSize",
        type: {
          name: "Number",
        },
      },
    },
  },
};

export const IntegrationDecorationCoordinates: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "IntegrationDecorationCoordinates",
    modelProperties: {
      y: {
        serializedName: "y",
        required: true,
        type: {
          name: "Number",
        },
      },
      x: {
        serializedName: "x",
        required: true,
        type: {
          name: "Number",
        },
      },
    },
  },
};

export const IntegrationDecorationDimensions: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "IntegrationDecorationDimensions",
    modelProperties: {
      height: {
        serializedName: "height",
        required: true,
        type: {
          name: "Number",
        },
      },
      width: {
        serializedName: "width",
        required: true,
        type: {
          name: "Number",
        },
      },
    },
  },
};

export const Paths1Vmkpn7DecorationsPostResponses200ContentApplicationJsonSchema: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "Paths1Vmkpn7DecorationsPostResponses200ContentApplicationJsonSchema",
    modelProperties: {
      decoration: {
        serializedName: "decoration",
        type: {
          name: "Composite",
          className: "IntegrationDecoration",
        },
      },
    },
  },
};

export const PathsHufrllDecorationsDecorationidPatchResponses200ContentApplicationJsonSchema: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "PathsHufrllDecorationsDecorationidPatchResponses200ContentApplicationJsonSchema",
    modelProperties: {
      decoration: {
        serializedName: "decoration",
        type: {
          name: "Composite",
          className: "IntegrationDecoration",
        },
      },
    },
  },
};

export const PartialIsegment: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "PartialIsegment",
    modelProperties: {
      version: {
        serializedName: "version",
        type: {
          name: "Enum",
          allowedValues: [1, 2],
        },
      },
      id: {
        serializedName: "id",
        type: {
          name: "Number",
        },
      },
      name: {
        serializedName: "name",
        type: {
          name: "String",
        },
      },
      objectName: {
        serializedName: "objectName",
        type: {
          name: "String",
        },
      },
      conditions: {
        serializedName: "conditions",
        type: {
          name: "Composite",
          className: "SegmentConditionGroup",
        },
      },
      accountId: {
        serializedName: "accountId",
        type: {
          name: "Number",
        },
      },
      readOnly: {
        serializedName: "readOnly",
        type: {
          name: "Boolean",
        },
      },
      autoSegment: {
        serializedName: "autoSegment",
        type: {
          name: "Boolean",
        },
      },
      color: {
        serializedName: "color",
        type: {
          name: "String",
        },
      },
      templateId: {
        serializedName: "templateId",
        type: {
          name: "String",
        },
      },
    },
  },
};

export const Paths1KmgxujSegmentsIdPatchResponses200ContentApplicationJsonSchema: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "Paths1KmgxujSegmentsIdPatchResponses200ContentApplicationJsonSchema",
    modelProperties: {
      segment: {
        serializedName: "segment",
        type: {
          name: "Composite",
          className: "Segment",
        },
      },
    },
  },
};

export const Paths16DnuduSyncStatusServiceidGetResponses200ContentApplicationJsonSchema: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "Paths16DnuduSyncStatusServiceidGetResponses200ContentApplicationJsonSchema",
    modelProperties: {
      syncObjects: {
        serializedName: "syncObjects",
        required: true,
        type: {
          name: "Dictionary",
          value: {
            type: { name: "Composite", className: "SyncObjectDetails" },
          },
        },
      },
      enabled: {
        serializedName: "enabled",
        required: true,
        type: {
          name: "Boolean",
        },
      },
    },
  },
};

export const SyncObjectDetails: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "SyncObjectDetails",
    modelProperties: {
      count: {
        serializedName: "count",
        type: {
          name: "Number",
        },
      },
      enabled: {
        serializedName: "enabled",
        required: true,
        type: {
          name: "Boolean",
        },
      },
      serviceObject: {
        serializedName: "serviceObject",
        required: true,
        type: {
          name: "String",
        },
      },
      lastSyncedChangeDate: {
        serializedName: "lastSyncedChangeDate",
        required: true,
        nullable: true,
        type: {
          name: "DateTime",
        },
      },
      createdAt: {
        serializedName: "createdAt",
        required: true,
        nullable: true,
        type: {
          name: "DateTime",
        },
      },
      lastRanAt: {
        serializedName: "lastRanAt",
        required: true,
        nullable: true,
        type: {
          name: "DateTime",
        },
      },
      initialSync: {
        serializedName: "initialSync",
        required: true,
        type: {
          name: "Boolean",
        },
      },
      hasMore: {
        serializedName: "hasMore",
        required: true,
        type: {
          name: "Boolean",
        },
      },
    },
  },
};

export const PathsCnkspaSyncSetupServiceidPostResponses200ContentApplicationJsonSchema: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "PathsCnkspaSyncSetupServiceidPostResponses200ContentApplicationJsonSchema",
    modelProperties: {
      status: {
        serializedName: "status",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const Paths18BftvjSyncTeardownServiceidPostResponses200ContentApplicationJsonSchema: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "Paths18BftvjSyncTeardownServiceidPostResponses200ContentApplicationJsonSchema",
    modelProperties: {
      status: {
        serializedName: "status",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const PathsH2Wj9HTeamMembersGetResponses200ContentApplicationJsonSchema: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "PathsH2Wj9HTeamMembersGetResponses200ContentApplicationJsonSchema",
    modelProperties: {
      teamMembers: {
        serializedName: "teamMembers",
        required: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Dictionary",
              value: { type: { name: "any" } },
            },
          },
        },
      },
    },
  },
};

export const Paths1Yfp89NTeamMembersPostRequestbodyContentApplicationJsonSchema: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "Paths1Yfp89NTeamMembersPostRequestbodyContentApplicationJsonSchema",
    modelProperties: {
      scopes: {
        serializedName: "scopes",
        required: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "TeamMemberScope",
            },
          },
        },
      },
      name: {
        serializedName: "name",
        required: true,
        type: {
          name: "String",
        },
      },
      email: {
        serializedName: "email",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const TeamMemberScope: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "TeamMemberScope",
    modelProperties: {
      id: {
        serializedName: "id",
        required: true,
        type: {
          name: "Enum",
          allowedValues: [
            "emails:read",
            "emails:write",
            "people:read",
            "people:write",
            "people:export",
            "flows:read",
            "flows:write",
            "broadcasts:read",
            "broadcasts:write",
            "emailMetrics:read",
            "team:read",
            "team:write",
            "emailSettings:write",
            "apps:write",
            "personFields:write",
            "account:write",
            "apiKeys:read",
            "apiKeys:write",
            "billing:read",
            "billing:write",
            "subaccounts:write",
            "forms:write",
            "forms:read",
            "eventManagement:read",
            "eventManagement:write",
          ],
        },
      },
      enabled: {
        serializedName: "enabled",
        required: true,
        type: {
          name: "Boolean",
        },
      },
    },
  },
};

export const Paths1P98FvkTeamMembersPostResponses200ContentApplicationJsonSchema: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "Paths1P98FvkTeamMembersPostResponses200ContentApplicationJsonSchema",
    modelProperties: {
      teamMember: {
        serializedName: "teamMember",
        type: {
          name: "Composite",
          className: "ITeamMember",
        },
      },
    },
  },
};

export const ITeamMember: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ITeamMember",
    modelProperties: {
      id: {
        serializedName: "id",
        required: true,
        type: {
          name: "Number",
        },
      },
      name: {
        serializedName: "name",
        required: true,
        type: {
          name: "String",
        },
      },
      email: {
        serializedName: "email",
        required: true,
        type: {
          name: "String",
        },
      },
      accountId: {
        serializedName: "accountId",
        required: true,
        type: {
          name: "Number",
        },
      },
      data: {
        serializedName: "data",
        type: {
          name: "Composite",
          className: "ITeamMemberData",
        },
      },
      accountData: {
        serializedName: "accountData",
        type: {
          name: "Composite",
          className: "ITeamMemberAccountData",
        },
      },
    },
  },
};

export const ITeamMemberData: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ITeamMemberData",
    modelProperties: {
      disabledLoginConfirmation: {
        serializedName: "disabledLoginConfirmation",
        type: {
          name: "Boolean",
        },
      },
      createdWithGoogleSignin: {
        serializedName: "createdWithGoogleSignin",
        type: {
          name: "Boolean",
        },
      },
    },
  },
};

export const ITeamMemberAccountData: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ITeamMemberAccountData",
    modelProperties: {
      isOwner: {
        serializedName: "isOwner",
        type: {
          name: "Boolean",
        },
      },
      scopes: {
        serializedName: "scopes",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "TeamMemberScope",
            },
          },
        },
      },
    },
  },
};

export const Paths1P1Ub3PTeamMembersIdPatchRequestbodyContentApplicationJsonSchema: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "Paths1P1Ub3PTeamMembersIdPatchRequestbodyContentApplicationJsonSchema",
    modelProperties: {
      scopes: {
        serializedName: "scopes",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "TeamMemberScope",
            },
          },
        },
      },
      name: {
        serializedName: "name",
        type: {
          name: "String",
        },
      },
    },
  },
};

export const Paths1809Np1TeamMembersIdPatchResponses200ContentApplicationJsonSchema: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "Paths1809Np1TeamMembersIdPatchResponses200ContentApplicationJsonSchema",
    modelProperties: {
      teamMember: {
        serializedName: "teamMember",
        type: {
          name: "Composite",
          className: "ITeamMember",
        },
      },
    },
  },
};

export const Paths1XngsapTeamMembersAccountsGetResponses200ContentApplicationJsonSchema: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "Paths1XngsapTeamMembersAccountsGetResponses200ContentApplicationJsonSchema",
    modelProperties: {
      accounts: {
        serializedName: "accounts",
        required: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Dictionary",
              value: { type: { name: "any" } },
            },
          },
        },
      },
    },
  },
};

export const PathsNkvme4TeamMembersAccountsPostRequestbodyContentApplicationJsonSchema: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "PathsNkvme4TeamMembersAccountsPostRequestbodyContentApplicationJsonSchema",
    modelProperties: {
      timezone: {
        serializedName: "timezone",
        type: {
          name: "String",
        },
      },
      industry: {
        serializedName: "industry",
        type: {
          name: "String",
        },
      },
      site: {
        serializedName: "site",
        type: {
          name: "String",
        },
      },
      name: {
        serializedName: "name",
        type: {
          name: "String",
        },
      },
    },
  },
};

export const Paths13Rl502TeamMembersAccountsPostResponses200ContentApplicationJsonSchema: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "Paths13Rl502TeamMembersAccountsPostResponses200ContentApplicationJsonSchema",
    modelProperties: {
      subaccount: {
        serializedName: "subaccount",
        required: true,
        type: {
          name: "Dictionary",
          value: { type: { name: "any" } },
        },
      },
    },
  },
};

export const Paths1L82MhObjectsObjectnameIdPatchRequestbodyContentApplicationJsonSchema: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "Paths1L82MhObjectsObjectnameIdPatchRequestbodyContentApplicationJsonSchema",
    additionalProperties: { type: { name: "Object" } },
    modelProperties: {
      ...ICustomObjectCreatable.type.modelProperties,
    },
  },
};

export const Paths3I1ZetTagsPostRequestbodyContentApplicationJsonSchema: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "Paths3I1ZetTagsPostRequestbodyContentApplicationJsonSchema",
    additionalProperties: { type: { name: "Object" } },
    modelProperties: {
      ...PathsXnkznrTagsPostRequestbodyContentApplicationJsonSchemaAllof0.type
        .modelProperties,
      ...EndUserIdentifiers.type.modelProperties,
    },
  },
};

export const Paths1488F72TagsDeleteRequestbodyContentApplicationJsonSchema: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "Paths1488F72TagsDeleteRequestbodyContentApplicationJsonSchema",
    additionalProperties: { type: { name: "Object" } },
    modelProperties: {
      ...Paths94X0UoTagsDeleteRequestbodyContentApplicationJsonSchemaAllof0.type
        .modelProperties,
      ...EndUserIdentifiers.type.modelProperties,
    },
  },
};

export const AIContentSuggestionWriteEmailParameters: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "AIContentSuggestionWriteEmailParameters",
    modelProperties: {
      ...AIContentSuggestionCommonParameters.type.modelProperties,
      ...ComponentsVoo65SSchemasAicontentsuggestionwriteemailPropertiesParametersAllof1
        .type.modelProperties,
    },
  },
};

export const AIContentSuggestionSubjectLineParameters: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "AIContentSuggestionSubjectLineParameters",
    modelProperties: {
      ...AIContentSuggestionCommonParameters.type.modelProperties,
      ...Components19XglejSchemasAicontentsuggestionsubjectlinePropertiesParametersAllof1
        .type.modelProperties,
    },
  },
};

export const AIContentSuggestionEmailOutlineParameters: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "AIContentSuggestionEmailOutlineParameters",
    modelProperties: {
      ...AIContentSuggestionCommonParameters.type.modelProperties,
      ...Components1E769BeSchemasAicontentsuggestionemailoutlinePropertiesParametersAllof1
        .type.modelProperties,
    },
  },
};

export const AIContentSuggestionRewriteSubjectLineParameters: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "AIContentSuggestionRewriteSubjectLineParameters",
    modelProperties: {
      ...AIContentSuggestionCommonParameters.type.modelProperties,
      ...Components15YhgfqSchemasAicontentsuggestionrewritesubjectlinePropertiesParametersAllof1
        .type.modelProperties,
    },
  },
};

export const AIContentSuggestionAutocompleteParameters: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "AIContentSuggestionAutocompleteParameters",
    modelProperties: {
      ...AIContentSuggestionCommonParameters.type.modelProperties,
      ...Components888N40SchemasAicontentsuggestionautocompletePropertiesParametersAllof1
        .type.modelProperties,
    },
  },
};

export const AIContentSuggestionWriteParagraphParameters: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "AIContentSuggestionWriteParagraphParameters",
    modelProperties: {
      ...AIContentSuggestionCommonParameters.type.modelProperties,
      ...Components1N3M5C8SchemasAicontentsuggestionwriteparagraphPropertiesParametersAllof1
        .type.modelProperties,
    },
  },
};

export const AIContentSuggestionImproveParagraphParameters: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "AIContentSuggestionImproveParagraphParameters",
    modelProperties: {
      ...AIContentSuggestionCommonParameters.type.modelProperties,
      ...Components1Kqnk1XSchemasAicontentsuggestionimproveparagraphPropertiesParametersAllof1
        .type.modelProperties,
    },
  },
};

export const Paths14BzineFormsIdGetResponses200ContentApplicationJsonSchemaPropertiesForm: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "Paths14BzineFormsIdGetResponses200ContentApplicationJsonSchemaPropertiesForm",
    modelProperties: {
      ...IForm.type.modelProperties,
      ...Paths53H43BFormsIdGetResponses200ContentApplicationJsonSchemaPropertiesFormAllof1
        .type.modelProperties,
    },
  },
};

export const Put200ApplicationJsonProperties: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "Put200ApplicationJsonProperties",
    modelProperties: {
      ...IForm.type.modelProperties,
      ...Paths1Vvx8HpFormsIdPutResponses200ContentApplicationJsonSchemaPropertiesItemAllof1
        .type.modelProperties,
    },
  },
};

export const Paths7Am3U7FormsGetResponses200ContentApplicationJsonSchemaPropertiesFormsItems: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "Paths7Am3U7FormsGetResponses200ContentApplicationJsonSchemaPropertiesFormsItems",
    modelProperties: {
      ...IForm.type.modelProperties,
      ...Paths2PqlpgFormsGetResponses200ContentApplicationJsonSchemaPropertiesFormsItemsAllof1
        .type.modelProperties,
    },
  },
};

export const Paths1Bhz2A5FormsPostResponses200ContentApplicationJsonSchemaPropertiesItem: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "Paths1Bhz2A5FormsPostResponses200ContentApplicationJsonSchemaPropertiesItem",
    modelProperties: {
      ...IForm.type.modelProperties,
      ...PathsZl288GFormsPostResponses200ContentApplicationJsonSchemaPropertiesItemAllof1
        .type.modelProperties,
    },
  },
};

export const PathsOx3MmjFormsIdResetOrderPutResponses200ContentApplicationJsonSchemaPropertiesForm: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "PathsOx3MmjFormsIdResetOrderPutResponses200ContentApplicationJsonSchemaPropertiesForm",
    modelProperties: {
      ...IForm.type.modelProperties,
      ...Paths174R2QuFormsIdResetOrderPutResponses200ContentApplicationJsonSchemaPropertiesFormAllof1
        .type.modelProperties,
    },
  },
};

export const Paths177Hj9TFormsIdDraftPutResponses200ContentApplicationJsonSchemaPropertiesItem: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "Paths177Hj9TFormsIdDraftPutResponses200ContentApplicationJsonSchemaPropertiesItem",
    modelProperties: {
      ...IForm.type.modelProperties,
      ...Paths10Rnv2RFormsIdDraftPutResponses200ContentApplicationJsonSchemaPropertiesItemAllof1
        .type.modelProperties,
    },
  },
};

export const Paths1Trgd5WFormsIdDraftPublishPutResponses200ContentApplicationJsonSchemaPropertiesForm: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "Paths1Trgd5WFormsIdDraftPublishPutResponses200ContentApplicationJsonSchemaPropertiesForm",
    modelProperties: {
      ...IForm.type.modelProperties,
      ...Paths9Z3B9TFormsIdDraftPublishPutResponses200ContentApplicationJsonSchemaPropertiesFormAllof1
        .type.modelProperties,
    },
  },
};

export const Paths1GzwcdzFormsIdDraftCancelPutResponses200ContentApplicationJsonSchemaPropertiesForm: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "Paths1GzwcdzFormsIdDraftCancelPutResponses200ContentApplicationJsonSchemaPropertiesForm",
    modelProperties: {
      ...IForm.type.modelProperties,
      ...PathsWspb0MFormsIdDraftCancelPutResponses200ContentApplicationJsonSchemaPropertiesFormAllof1
        .type.modelProperties,
    },
  },
};
