import * as coreHttp from "@azure/core-http";
import * as Parameters from "./models/parameters";
import * as Mappers from "./models/mappers";
import {
  EnchargeAPIOptionalParams,
  GetInfoOptionalParams,
  GetInfoResponse,
  GetAccountInfoByFormIdOptionalParams,
  GetAccountInfoByFormIdResponse,
  GetCustomObjectsOptionalParams,
  GetCustomObjectsResponse,
  ICustomObjectCreatable,
  CreateCustomObjectsOptionalParams,
  CreateCustomObjectsResponse,
  CreateOrUpdateCustomObjectsOptionalParams,
  CreateOrUpdateCustomObjectsResponse,
  GetCustomObjectsInSegmentOptionalParams,
  GetCustomObjectsInSegmentResponse,
  GetCustomObjectsCountOptionalParams,
  GetCustomObjectsCountResponse,
  GetCustomObjectsInSegmentCountOptionalParams,
  GetCustomObjectsInSegmentCountResponse,
  SearchCustomObjectsOptionalParams,
  SearchCustomObjectsResponse,
  GetCustomObjectByIdOptionalParams,
  GetCustomObjectByIdResponse,
  Paths1L82MhObjectsObjectnameIdPatchRequestbodyContentApplicationJsonSchema,
  UpdateCustomObjectOptionalParams,
  UpdateCustomObjectResponse,
  DeleteCustomObjectOptionalParams,
  GetCustomObjectByExternalIdOptionalParams,
  GetCustomObjectByExternalIdResponse,
  UpdateCustomObjectByExternalIdOptionalParams,
  UpdateCustomObjectByExternalIdResponse,
  DeleteCustomObjectByExternalIdOptionalParams,
  GetObjectsByAssociationOptionalParams,
  GetObjectsByAssociationResponse,
  GetAllAssociatedObjectsOptionalParams,
  GetAllAssociatedObjectsResponse,
  AssociateObjectsByDefaultAssociationOptionalParams,
  AssociateCustomObjectsOptionalParams,
  RemoveCustomObjectsAssociationOptionalParams,
  GetCustomObjectsSchemaOptionalParams,
  GetCustomObjectsSchemaResponse,
  PickCustomObjectSchemaCreatableExcludeKeyofCustomObjectSchemaCreatableSearchableFields,
  CreateCustomObjectSchemaOptionalParams,
  CreateCustomObjectSchemaResponse,
  GetCustomObjectSchemaOptionalParams,
  GetCustomObjectSchemaResponse,
  PartialOmitCustomObjectSchemaFieldsOrAssociationsOrName,
  UpdateCustomObjectSchemaOptionalParams,
  UpdateCustomObjectSchemaResponse,
  DeleteCustomObjectSchemaOptionalParams,
  IPersonField,
  CreateObjectFieldsOptionalParams,
  CreateObjectFieldsResponse,
  PartialPickCustomObjectFieldTitleOrTooltipOrDisplayTypeOrIconOrEnumNamesOrEnumOrTypeOrFormat,
  EditObjectFieldOptionalParams,
  EditObjectFieldResponse,
  DeleteObjectFieldOptionalParams,
  PickCustomObjectSchemaAssociationExcludeKeyofCustomObjectSchemaAssociationId,
  DefineCustomObjectsAssociationSchemaOptionalParams,
  DefineCustomObjectsAssociationSchemaResponse,
  RemoveCustomObjectsAssociationSchemaOptionalParams,
  GetEmailOptionalParams,
  GetEmailResponse,
  PartialIemailContent,
  ModifyEmailOptionalParams,
  ModifyEmailResponse,
  DeleteEmailOptionalParams,
  EmailContentCreate,
  CreateEmailOptionalParams,
  CreateEmailResponse,
  Paths1X3Kl1JEventSubscriptionsPostRequestbodyContentApplicationJsonSchema,
  CreateWebhookOptionalParams,
  CreateWebhookResponse,
  DeleteWebhookOptionalParams,
  GetFieldsOptionalParams,
  GetFieldsResponse,
  CreateFieldsOptionalParams,
  CreateFieldsResponse,
  EditFieldOptionalParams,
  EditFieldResponse,
  DeleteFieldOptionalParams,
  GetSegmentsOptionalParams,
  GetSegmentsResponse,
  PartialOmitIsegmentIdOrAccountIdOrVersion,
  CreateSegmentOptionalParams,
  CreateSegmentResponse,
  GetPeopleInSegmentOptionalParams,
  GetPeopleInSegmentResponse,
  Paths3I1ZetTagsPostRequestbodyContentApplicationJsonSchema,
  AddTagOptionalParams,
  Paths1488F72TagsDeleteRequestbodyContentApplicationJsonSchema,
  RemoveTagOptionalParams,
  GetAccountTagsOptionalParams,
  GetAccountTagsResponse,
  Paths5Gr823TagsManagementPostRequestbodyContentApplicationJsonSchema,
  CreateAccountTagsOptionalParams,
  CreateAccountTagsResponse,
  GetTagCountsOptionalParams,
  GetTagCountsResponse,
  Tag,
  UpdateAccountTagOptionalParams,
  UpdateAccountTagResponse,
  DeleteAccountTagOptionalParams,
  GetAllPeopleOptionalParams,
  GetAllPeopleResponse,
  UnsubscribePersonOptionalParams,
  AIContentSuggestionGenerateParameters,
  GenerateContentSuggestionsOptionalParams,
  GenerateContentSuggestionsResponse,
  GenerateContentSuggestionsPublicOptionalParams,
  GenerateContentSuggestionsPublicResponse,
  GetFavoritedContentSuggestionsOptionalParams,
  GetFavoritedContentSuggestionsResponse,
  FavoriteContentSuggestionsOptionalParams,
  GetBroadcastsOptionalParams,
  GetBroadcastsResponse,
  PartialBroadcast,
  CreateBroadcastOptionalParams,
  CreateBroadcastResponse,
  Broadcast,
  EditBroadcastOptionalParams,
  EditBroadcastResponse,
  DeleteBroadcastOptionalParams,
  SendBroadcastOptionalParams,
  SendBroadcastResponse,
  CancelBroadcastOptionalParams,
  CancelBroadcastResponse,
  UpdateBroadcastStatusOptionalParams,
  UpdateBroadcastStatusResponse,
  GetBroadcastEnteredCountOptionalParams,
  GetBroadcastEnteredCountResponse,
  GetCommunicationCategoriesOptionalParams,
  GetCommunicationCategoriesResponse,
  PickIcommunicationCategoryNameOrDescription,
  CreateCommunicationCategoryOptionalParams,
  CreateCommunicationCategoryResponse,
  PickIcommunicationCategoryNameOrDescriptionOrIsArchived,
  EditCommunicationCategoryOptionalParams,
  EditCommunicationCategoryResponse,
  GetCommunicationPreferencesDataOptionalParams,
  GetCommunicationPreferencesDataResponse,
  CommunicationCategoryOptInValues,
  SaveCommunicationPreferencesOptionalParams,
  UnsubscribeFromAllCommunicationsOptionalParams,
  Schema5,
  GetCommunicationPreferencesPagePreviewOptionalParams,
  GetEmailVerificationsOptionalParams,
  GetEmailVerificationsResponse,
  Paths1Aso2SlSettingsEmailAddressesPostRequestbodyContentApplicationJsonSchema,
  CreateEmailVerificationOptionalParams,
  CreateEmailVerificationResponse,
  DeleteEmailVerificationOptionalParams,
  GetListEventSchemaMetricOptionalParams,
  GetListEventSchemaMetricResponse,
  GetEventSchemaMetricOptionalParams,
  GetEventSchemaMetricResponse,
  GetListEventSchemaOptionalParams,
  GetListEventSchemaResponse,
  PartialIeventSchema,
  CreateEventSchemaOptionalParams,
  CreateEventSchemaResponse,
  PartialIeventSchemaProperty,
  EditEventSchemaPropertyOptionalParams,
  EditEventSchemaPropertyResponse,
  DeleteEventSchemaPropertyOptionalParams,
  DeleteEventSchemaPropertyResponse,
  CreateEventPropertyOptionalParams,
  CreateEventPropertyResponse,
  GetEndUserEventOptionalParams,
  GetEndUserEventResponse,
  DeleteEventSchemaOptionalParams,
  UpdateEventSchemaOptionalParams,
  UpdateEventSchemaResponse,
  GetEventSchemaOptionalParams,
  GetEventSchemaResponse,
  GetFormOptionalParams,
  GetFormResponse,
  PartialIform,
  UpdateFormOptionalParams,
  UpdateFormResponse,
  ArchiveFormOptionalParams,
  ArchiveFormResponse,
  GetFormSharedOptionalParams,
  GetFormSharedResponse,
  GetFormsOptionalParams,
  GetFormsResponse,
  CreateFormOptionalParams,
  CreateFormResponse,
  SubmitFormOptionalParams,
  SubmitFormResponse,
  PlainHtmlSubmissionOptionalParams,
  PlainHtmlSubmissionResponse,
  DuplicateFormOptionalParams,
  DuplicateFormResponse,
  ResetOrderOptionalParams,
  ResetOrderResponse,
  UpdateFormDraftOptionalParams,
  UpdateFormDraftResponse,
  PartialIformObject,
  CreateFormObjectOptionalParams,
  CreateFormObjectResponse,
  UpdateFormObjectOptionalParams,
  UpdateFormObjectResponse,
  ArchiveFormObjectOptionalParams,
  ArchiveFormObjectResponse,
  UpdateDraftFormObjectOptionalParams,
  UpdateDraftFormObjectResponse,
  GetFormSubmissionsOptionalParams,
  GetFormSubmissionsResponse,
  PublishDraftFormOptionalParams,
  PublishDraftFormResponse,
  CancelDraftFormOptionalParams,
  CancelDraftFormResponse,
  IntegrationDecoration,
  CreateDecorationOptionalParams,
  CreateDecorationResponse,
  UpdateDecorationOptionalParams,
  UpdateDecorationResponse,
  DeleteDecorationOptionalParams,
  DeleteSegmentOptionalParams,
  PartialIsegment,
  ModifySegmentOptionalParams,
  ModifySegmentResponse,
  GetSyncHealthOptionalParams,
  GetSyncHealthResponse,
  SetupSyncOptionalParams,
  SetupSyncResponse,
  TeardownSyncOptionalParams,
  TeardownSyncResponse,
  GetTeamMembersOptionalParams,
  GetTeamMembersResponse,
  Paths1Yfp89NTeamMembersPostRequestbodyContentApplicationJsonSchema,
  CreateTeamMemberOptionalParams,
  CreateTeamMemberResponse,
  Paths1P1Ub3PTeamMembersIdPatchRequestbodyContentApplicationJsonSchema,
  UpdateTeamMemberOptionalParams,
  UpdateTeamMemberResponse,
  DeleteTeamMemberOptionalParams,
  GetTeamMemberAccountsOptionalParams,
  GetTeamMemberAccountsResponse,
  PathsNkvme4TeamMembersAccountsPostRequestbodyContentApplicationJsonSchema,
  CreateAccountForTeamMemberOptionalParams,
  CreateAccountForTeamMemberResponse,
} from "./models";

const packageName = "enchargeAPI";
const packageVersion = "1.0.0-beta.1";

export class EnchargeAPI extends coreHttp.ServiceClient {
  $host: string;

  /**
   * Initializes a new instance of the EnchargeAPI class.
   * @param credentials Subscription credentials which uniquely identify client subscription.
   * @param options The parameter options
   */
  constructor(
    credentials: coreHttp.TokenCredential | coreHttp.ServiceClientCredentials,
    options?: EnchargeAPIOptionalParams
  ) {
    if (credentials === undefined) {
      throw new Error("'credentials' cannot be null");
    }

    // Initializing default values for options
    if (!options) {
      options = {};
    }

    const defaultUserAgent = `azsdk-js-${packageName.replace(
      /@.*\//,
      ""
    )}/${packageVersion} ${coreHttp.getDefaultUserAgentValue()}`;

    if (!options.credentialScopes) {
      options.credentialScopes = ["https://management.azure.com/.default"];
    }

    super(credentials, {
      ...options,
      userAgent: options.userAgent
        ? `${options.userAgent} ${defaultUserAgent}`
        : `${defaultUserAgent}`,
    });

    this.requestContentType = "application/json; charset=utf-8";
    this.baseUri = options.endpoint ?? "https://api.encharge.io/v1";

    // Assigning values to Constant parameters
    this.$host = options.$host || "https://api.encharge.io/v1";
  }

  /**
   * Get information about your account.
   * @param options The options parameters.
   */
  getInfo(options?: GetInfoOptionalParams): Promise<GetInfoResponse> {
    const operationArguments: coreHttp.OperationArguments = {
      options: coreHttp.operationOptionsToRequestOptionsBase(options || {}),
    };
    return this.sendOperationRequest(
      operationArguments,
      getInfoOperationSpec
    ) as Promise<GetInfoResponse>;
  }

  /**
   * Get non-sensitive account information by form Id without authentication
   * @param formId
   * @param options The options parameters.
   */
  getAccountInfoByFormId(
    formId: string,
    options?: GetAccountInfoByFormIdOptionalParams
  ): Promise<GetAccountInfoByFormIdResponse> {
    const operationArguments: coreHttp.OperationArguments = {
      formId,
      options: coreHttp.operationOptionsToRequestOptionsBase(options || {}),
    };
    return this.sendOperationRequest(
      operationArguments,
      getAccountInfoByFormIdOperationSpec
    ) as Promise<GetAccountInfoByFormIdResponse>;
  }

  /**
   * Get multiple custom objects/companies.
   * @param objectName
   * @param options The options parameters.
   */
  getCustomObjects(
    objectName: string,
    options?: GetCustomObjectsOptionalParams
  ): Promise<GetCustomObjectsResponse> {
    const operationArguments: coreHttp.OperationArguments = {
      objectName,
      options: coreHttp.operationOptionsToRequestOptionsBase(options || {}),
    };
    return this.sendOperationRequest(
      operationArguments,
      getCustomObjectsOperationSpec
    ) as Promise<GetCustomObjectsResponse>;
  }

  /**
   * Create new custom objects/companies.
   * @param objectName
   * @param body Objects to create.
   * @param options The options parameters.
   */
  createCustomObjects(
    objectName: string,
    body: ICustomObjectCreatable[],
    options?: CreateCustomObjectsOptionalParams
  ): Promise<CreateCustomObjectsResponse> {
    const operationArguments: coreHttp.OperationArguments = {
      objectName,
      body,
      options: coreHttp.operationOptionsToRequestOptionsBase(options || {}),
    };
    return this.sendOperationRequest(
      operationArguments,
      createCustomObjectsOperationSpec
    ) as Promise<CreateCustomObjectsResponse>;
  }

  /**
   * Create or Update (Upsert) a custom object/company.
   * @param objectName
   * @param body Objects to create.
   * @param options The options parameters.
   */
  createOrUpdateCustomObjects(
    objectName: string,
    body: ICustomObjectCreatable,
    options?: CreateOrUpdateCustomObjectsOptionalParams
  ): Promise<CreateOrUpdateCustomObjectsResponse> {
    const operationArguments: coreHttp.OperationArguments = {
      objectName,
      body,
      options: coreHttp.operationOptionsToRequestOptionsBase(options || {}),
    };
    return this.sendOperationRequest(
      operationArguments,
      createOrUpdateCustomObjectsOperationSpec
    ) as Promise<CreateOrUpdateCustomObjectsResponse>;
  }

  /**
   * Get custom objects/companies in a segment.
   * @param objectName
   * @param segmentId
   * @param options The options parameters.
   */
  getCustomObjectsInSegment(
    objectName: string,
    segmentId: number,
    options?: GetCustomObjectsInSegmentOptionalParams
  ): Promise<GetCustomObjectsInSegmentResponse> {
    const operationArguments: coreHttp.OperationArguments = {
      objectName,
      segmentId,
      options: coreHttp.operationOptionsToRequestOptionsBase(options || {}),
    };
    return this.sendOperationRequest(
      operationArguments,
      getCustomObjectsInSegmentOperationSpec
    ) as Promise<GetCustomObjectsInSegmentResponse>;
  }

  /**
   * Get custom objects/companies count.
   * @param objectName
   * @param options The options parameters.
   */
  getCustomObjectsCount(
    objectName: string,
    options?: GetCustomObjectsCountOptionalParams
  ): Promise<GetCustomObjectsCountResponse> {
    const operationArguments: coreHttp.OperationArguments = {
      objectName,
      options: coreHttp.operationOptionsToRequestOptionsBase(options || {}),
    };
    return this.sendOperationRequest(
      operationArguments,
      getCustomObjectsCountOperationSpec
    ) as Promise<GetCustomObjectsCountResponse>;
  }

  /**
   * Get count of custom objects/companies in a segment.
   * @param objectName
   * @param segmentId
   * @param options The options parameters.
   */
  getCustomObjectsInSegmentCount(
    objectName: string,
    segmentId: number,
    options?: GetCustomObjectsInSegmentCountOptionalParams
  ): Promise<GetCustomObjectsInSegmentCountResponse> {
    const operationArguments: coreHttp.OperationArguments = {
      objectName,
      segmentId,
      options: coreHttp.operationOptionsToRequestOptionsBase(options || {}),
    };
    return this.sendOperationRequest(
      operationArguments,
      getCustomObjectsInSegmentCountOperationSpec
    ) as Promise<GetCustomObjectsInSegmentCountResponse>;
  }

  /**
   * Search custom objects/companies by their searchable fields
   * @param objectName
   * @param query
   * @param options The options parameters.
   */
  searchCustomObjects(
    objectName: string,
    query: string,
    options?: SearchCustomObjectsOptionalParams
  ): Promise<SearchCustomObjectsResponse> {
    const operationArguments: coreHttp.OperationArguments = {
      objectName,
      query,
      options: coreHttp.operationOptionsToRequestOptionsBase(options || {}),
    };
    return this.sendOperationRequest(
      operationArguments,
      searchCustomObjectsOperationSpec
    ) as Promise<SearchCustomObjectsResponse>;
  }

  /**
   * Get custom object/company by id
   * @param objectName
   * @param id
   * @param options The options parameters.
   */
  getCustomObjectById(
    objectName: string,
    id: number,
    options?: GetCustomObjectByIdOptionalParams
  ): Promise<GetCustomObjectByIdResponse> {
    const operationArguments: coreHttp.OperationArguments = {
      objectName,
      id,
      options: coreHttp.operationOptionsToRequestOptionsBase(options || {}),
    };
    return this.sendOperationRequest(
      operationArguments,
      getCustomObjectByIdOperationSpec
    ) as Promise<GetCustomObjectByIdResponse>;
  }

  /**
   * Update custom object/company.
   * @param objectName
   * @param id
   * @param body Custom object. Must have an id or externalId.
   * @param options The options parameters.
   */
  updateCustomObject(
    objectName: string,
    id: number,
    body: Paths1L82MhObjectsObjectnameIdPatchRequestbodyContentApplicationJsonSchema,
    options?: UpdateCustomObjectOptionalParams
  ): Promise<UpdateCustomObjectResponse> {
    const operationArguments: coreHttp.OperationArguments = {
      objectName,
      id,
      body,
      options: coreHttp.operationOptionsToRequestOptionsBase(options || {}),
    };
    return this.sendOperationRequest(
      operationArguments,
      updateCustomObjectOperationSpec
    ) as Promise<UpdateCustomObjectResponse>;
  }

  /**
   * Remove custom object/company by Id
   * @param objectName
   * @param id
   * @param options The options parameters.
   */
  deleteCustomObject(
    objectName: string,
    id: number,
    options?: DeleteCustomObjectOptionalParams
  ): Promise<coreHttp.RestResponse> {
    const operationArguments: coreHttp.OperationArguments = {
      objectName,
      id,
      options: coreHttp.operationOptionsToRequestOptionsBase(options || {}),
    };
    return this.sendOperationRequest(
      operationArguments,
      deleteCustomObjectOperationSpec
    ) as Promise<coreHttp.RestResponse>;
  }

  /**
   * Get custom object/company by external ID
   * @param objectName
   * @param externalId
   * @param options The options parameters.
   */
  getCustomObjectByExternalId(
    objectName: string,
    externalId: string,
    options?: GetCustomObjectByExternalIdOptionalParams
  ): Promise<GetCustomObjectByExternalIdResponse> {
    const operationArguments: coreHttp.OperationArguments = {
      objectName,
      externalId,
      options: coreHttp.operationOptionsToRequestOptionsBase(options || {}),
    };
    return this.sendOperationRequest(
      operationArguments,
      getCustomObjectByExternalIdOperationSpec
    ) as Promise<GetCustomObjectByExternalIdResponse>;
  }

  /**
   * Update custom object/company by external ID
   * @param objectName
   * @param externalId
   * @param body Custom object. Must have an id or externalId.
   * @param options The options parameters.
   */
  updateCustomObjectByExternalId(
    objectName: string,
    externalId: string,
    body: ICustomObjectCreatable,
    options?: UpdateCustomObjectByExternalIdOptionalParams
  ): Promise<UpdateCustomObjectByExternalIdResponse> {
    const operationArguments: coreHttp.OperationArguments = {
      objectName,
      externalId,
      body,
      options: coreHttp.operationOptionsToRequestOptionsBase(options || {}),
    };
    return this.sendOperationRequest(
      operationArguments,
      updateCustomObjectByExternalIdOperationSpec
    ) as Promise<UpdateCustomObjectByExternalIdResponse>;
  }

  /**
   * Remove custom object/company by external Id
   * @param objectName
   * @param externalId
   * @param options The options parameters.
   */
  deleteCustomObjectByExternalId(
    objectName: string,
    externalId: string,
    options?: DeleteCustomObjectByExternalIdOptionalParams
  ): Promise<coreHttp.RestResponse> {
    const operationArguments: coreHttp.OperationArguments = {
      objectName,
      externalId,
      options: coreHttp.operationOptionsToRequestOptionsBase(options || {}),
    };
    return this.sendOperationRequest(
      operationArguments,
      deleteCustomObjectByExternalIdOperationSpec
    ) as Promise<coreHttp.RestResponse>;
  }

  /**
   * Get objects by association with given custom object
   * @param objectName Name of the custom object to get associated objects for, e.g. "person" or
   *                   "company".
   * @param id Id of the custom object to get associated objects for.
   * @param associationId Id of the association schema.
   * @param options The options parameters.
   */
  getObjectsByAssociation(
    objectName: string,
    id: any,
    associationId: number,
    options?: GetObjectsByAssociationOptionalParams
  ): Promise<GetObjectsByAssociationResponse> {
    const operationArguments: coreHttp.OperationArguments = {
      objectName,
      id,
      associationId,
      options: coreHttp.operationOptionsToRequestOptionsBase(options || {}),
    };
    return this.sendOperationRequest(
      operationArguments,
      getObjectsByAssociationOperationSpec
    ) as Promise<GetObjectsByAssociationResponse>;
  }

  /**
   * Retrieve all objects associated in any way with a given custom object
   * @param objectName
   * @param id Anything
   * @param options The options parameters.
   */
  getAllAssociatedObjects(
    objectName: string,
    id: any,
    options?: GetAllAssociatedObjectsOptionalParams
  ): Promise<GetAllAssociatedObjectsResponse> {
    const operationArguments: coreHttp.OperationArguments = {
      objectName,
      id,
      options: coreHttp.operationOptionsToRequestOptionsBase(options || {}),
    };
    return this.sendOperationRequest(
      operationArguments,
      getAllAssociatedObjectsOperationSpec
    ) as Promise<GetAllAssociatedObjectsResponse>;
  }

  /**
   * Associate a custom object with another object, using the default association.
   *
   * If there is only one association between the objects (e.g. a contact and a company),
   * you might use this endpoint to associate them without passing the association id.
   * @param objectName Name of the custom object on one side of the association, e.g. "person" or
   *                   "company".
   * @param id Id of the custom object on one side of the association.
   * @param targetObjectName Name of the custom object on the other side of the association.
   * @param targetId Id of the custom object on the other side of the association.
   * @param options The options parameters.
   */
  associateObjectsByDefaultAssociation(
    objectName: string,
    id: any,
    targetObjectName: string,
    targetId: any,
    options?: AssociateObjectsByDefaultAssociationOptionalParams
  ): Promise<coreHttp.RestResponse> {
    const operationArguments: coreHttp.OperationArguments = {
      objectName,
      id,
      targetObjectName,
      targetId,
      options: coreHttp.operationOptionsToRequestOptionsBase(options || {}),
    };
    return this.sendOperationRequest(
      operationArguments,
      associateObjectsByDefaultAssociationOperationSpec
    ) as Promise<coreHttp.RestResponse>;
  }

  /**
   * Associate an object with another object
   * @param objectName Name of the custom object on one side of the association, e.g. "person" or
   *                   "company".
   * @param id Id of the custom object on one side of the association.
   * @param associationId Id of the association schema.
   * @param targetId Id of the custom object on the other side of the association.
   * @param options The options parameters.
   */
  associateCustomObjects(
    objectName: string,
    id: any,
    associationId: number,
    targetId: any,
    options?: AssociateCustomObjectsOptionalParams
  ): Promise<coreHttp.RestResponse> {
    const operationArguments: coreHttp.OperationArguments = {
      objectName,
      id,
      associationId,
      targetId,
      options: coreHttp.operationOptionsToRequestOptionsBase(options || {}),
    };
    return this.sendOperationRequest(
      operationArguments,
      associateCustomObjectsOperationSpec
    ) as Promise<coreHttp.RestResponse>;
  }

  /**
   * Remove association between objects
   * @param objectName Name of the custom object on one side of the association, e.g. "person" or
   *                   "company".
   * @param id Id of the custom object on one side of the association.
   * @param associationId Id of the association schema.
   * @param targetId Id of the custom object on the other side of the association.
   * @param options The options parameters.
   */
  removeCustomObjectsAssociation(
    objectName: string,
    id: any,
    associationId: number,
    targetId: any,
    options?: RemoveCustomObjectsAssociationOptionalParams
  ): Promise<coreHttp.RestResponse> {
    const operationArguments: coreHttp.OperationArguments = {
      objectName,
      id,
      associationId,
      targetId,
      options: coreHttp.operationOptionsToRequestOptionsBase(options || {}),
    };
    return this.sendOperationRequest(
      operationArguments,
      removeCustomObjectsAssociationOperationSpec
    ) as Promise<coreHttp.RestResponse>;
  }

  /**
   * Get all custom objects.
   * @param options The options parameters.
   */
  getCustomObjectsSchema(
    options?: GetCustomObjectsSchemaOptionalParams
  ): Promise<GetCustomObjectsSchemaResponse> {
    const operationArguments: coreHttp.OperationArguments = {
      options: coreHttp.operationOptionsToRequestOptionsBase(options || {}),
    };
    return this.sendOperationRequest(
      operationArguments,
      getCustomObjectsSchemaOperationSpec
    ) as Promise<GetCustomObjectsSchemaResponse>;
  }

  /**
   * Get custom objects counts.
   * @param body Objects to add.
   * @param options The options parameters.
   */
  createCustomObjectSchema(
    body: PickCustomObjectSchemaCreatableExcludeKeyofCustomObjectSchemaCreatableSearchableFields,
    options?: CreateCustomObjectSchemaOptionalParams
  ): Promise<CreateCustomObjectSchemaResponse> {
    const operationArguments: coreHttp.OperationArguments = {
      body,
      options: coreHttp.operationOptionsToRequestOptionsBase(options || {}),
    };
    return this.sendOperationRequest(
      operationArguments,
      createCustomObjectSchemaOperationSpec
    ) as Promise<CreateCustomObjectSchemaResponse>;
  }

  /**
   * Get custom object by name.
   * @param objectName
   * @param options The options parameters.
   */
  getCustomObjectSchema(
    objectName: string,
    options?: GetCustomObjectSchemaOptionalParams
  ): Promise<GetCustomObjectSchemaResponse> {
    const operationArguments: coreHttp.OperationArguments = {
      objectName,
      options: coreHttp.operationOptionsToRequestOptionsBase(options || {}),
    };
    return this.sendOperationRequest(
      operationArguments,
      getCustomObjectSchemaOperationSpec
    ) as Promise<GetCustomObjectSchemaResponse>;
  }

  /**
   * Update custom object.
   * @param objectName
   * @param body Update data
   * @param options The options parameters.
   */
  updateCustomObjectSchema(
    objectName: string,
    body: PartialOmitCustomObjectSchemaFieldsOrAssociationsOrName,
    options?: UpdateCustomObjectSchemaOptionalParams
  ): Promise<UpdateCustomObjectSchemaResponse> {
    const operationArguments: coreHttp.OperationArguments = {
      objectName,
      body,
      options: coreHttp.operationOptionsToRequestOptionsBase(options || {}),
    };
    return this.sendOperationRequest(
      operationArguments,
      updateCustomObjectSchemaOperationSpec
    ) as Promise<UpdateCustomObjectSchemaResponse>;
  }

  /**
   * Remove custom object definition.
   *
   * Note that this will delete all data stored in these custom objects.
   * @param objectName
   * @param options The options parameters.
   */
  deleteCustomObjectSchema(
    objectName: string,
    options?: DeleteCustomObjectSchemaOptionalParams
  ): Promise<coreHttp.RestResponse> {
    const operationArguments: coreHttp.OperationArguments = {
      objectName,
      options: coreHttp.operationOptionsToRequestOptionsBase(options || {}),
    };
    return this.sendOperationRequest(
      operationArguments,
      deleteCustomObjectSchemaOperationSpec
    ) as Promise<coreHttp.RestResponse>;
  }

  /**
   * Create fields for Custom Object
   * @param objectName
   * @param body Array of IPersonField
   * @param options The options parameters.
   */
  createObjectFields(
    objectName: string,
    body: IPersonField[],
    options?: CreateObjectFieldsOptionalParams
  ): Promise<CreateObjectFieldsResponse> {
    const operationArguments: coreHttp.OperationArguments = {
      objectName,
      body,
      options: coreHttp.operationOptionsToRequestOptionsBase(options || {}),
    };
    return this.sendOperationRequest(
      operationArguments,
      createObjectFieldsOperationSpec
    ) as Promise<CreateObjectFieldsResponse>;
  }

  /**
   * Modify field for Custom Object.
   *
   * Note: Field type and format can only be changed for People fields.
   * @param fieldName
   * @param objectName
   * @param body Make all properties in T optional
   * @param options The options parameters.
   */
  editObjectField(
    fieldName: string,
    objectName: string,
    body: PartialPickCustomObjectFieldTitleOrTooltipOrDisplayTypeOrIconOrEnumNamesOrEnumOrTypeOrFormat,
    options?: EditObjectFieldOptionalParams
  ): Promise<EditObjectFieldResponse> {
    const operationArguments: coreHttp.OperationArguments = {
      fieldName,
      objectName,
      body,
      options: coreHttp.operationOptionsToRequestOptionsBase(options || {}),
    };
    return this.sendOperationRequest(
      operationArguments,
      editObjectFieldOperationSpec
    ) as Promise<EditObjectFieldResponse>;
  }

  /**
   * Delete Person Field
   * @param fieldName
   * @param objectName
   * @param options The options parameters.
   */
  deleteObjectField(
    fieldName: string,
    objectName: string,
    options?: DeleteObjectFieldOptionalParams
  ): Promise<coreHttp.RestResponse> {
    const operationArguments: coreHttp.OperationArguments = {
      fieldName,
      objectName,
      options: coreHttp.operationOptionsToRequestOptionsBase(options || {}),
    };
    return this.sendOperationRequest(
      operationArguments,
      deleteObjectFieldOperationSpec
    ) as Promise<coreHttp.RestResponse>;
  }

  /**
   * Define association between custom objects.
   * @param body Association Data
   * @param options The options parameters.
   */
  defineCustomObjectsAssociationSchema(
    body: PickCustomObjectSchemaAssociationExcludeKeyofCustomObjectSchemaAssociationId,
    options?: DefineCustomObjectsAssociationSchemaOptionalParams
  ): Promise<DefineCustomObjectsAssociationSchemaResponse> {
    const operationArguments: coreHttp.OperationArguments = {
      body,
      options: coreHttp.operationOptionsToRequestOptionsBase(options || {}),
    };
    return this.sendOperationRequest(
      operationArguments,
      defineCustomObjectsAssociationSchemaOperationSpec
    ) as Promise<DefineCustomObjectsAssociationSchemaResponse>;
  }

  /**
   * Remove a defined association between custom objects.
   * @param id
   * @param options The options parameters.
   */
  removeCustomObjectsAssociationSchema(
    id: number,
    options?: RemoveCustomObjectsAssociationSchemaOptionalParams
  ): Promise<coreHttp.RestResponse> {
    const operationArguments: coreHttp.OperationArguments = {
      id,
      options: coreHttp.operationOptionsToRequestOptionsBase(options || {}),
    };
    return this.sendOperationRequest(
      operationArguments,
      removeCustomObjectsAssociationSchemaOperationSpec
    ) as Promise<coreHttp.RestResponse>;
  }

  /**
   * Get email template
   * @param id
   * @param options The options parameters.
   */
  getEmail(
    id: number,
    options?: GetEmailOptionalParams
  ): Promise<GetEmailResponse> {
    const operationArguments: coreHttp.OperationArguments = {
      id,
      options: coreHttp.operationOptionsToRequestOptionsBase(options || {}),
    };
    return this.sendOperationRequest(
      operationArguments,
      getEmailOperationSpec
    ) as Promise<GetEmailResponse>;
  }

  /**
   * Modify email template
   * @param id
   * @param body Make all properties in T optional
   * @param options The options parameters.
   */
  modifyEmail(
    id: number,
    body: PartialIemailContent,
    options?: ModifyEmailOptionalParams
  ): Promise<ModifyEmailResponse> {
    const operationArguments: coreHttp.OperationArguments = {
      id,
      body,
      options: coreHttp.operationOptionsToRequestOptionsBase(options || {}),
    };
    return this.sendOperationRequest(
      operationArguments,
      modifyEmailOperationSpec
    ) as Promise<ModifyEmailResponse>;
  }

  /**
   * Delete email template
   * @param id
   * @param options The options parameters.
   */
  deleteEmail(
    id: number,
    options?: DeleteEmailOptionalParams
  ): Promise<coreHttp.RestResponse> {
    const operationArguments: coreHttp.OperationArguments = {
      id,
      options: coreHttp.operationOptionsToRequestOptionsBase(options || {}),
    };
    return this.sendOperationRequest(
      operationArguments,
      deleteEmailOperationSpec
    ) as Promise<coreHttp.RestResponse>;
  }

  /**
   * Create email template
   * @param body
   * @param options The options parameters.
   */
  createEmail(
    body: EmailContentCreate,
    options?: CreateEmailOptionalParams
  ): Promise<CreateEmailResponse> {
    const operationArguments: coreHttp.OperationArguments = {
      body,
      options: coreHttp.operationOptionsToRequestOptionsBase(options || {}),
    };
    return this.sendOperationRequest(
      operationArguments,
      createEmailOperationSpec
    ) as Promise<CreateEmailResponse>;
  }

  /**
   * Subscribe to events happening in Encharge.
   * @param body
   * @param options The options parameters.
   */
  createWebhook(
    body: Paths1X3Kl1JEventSubscriptionsPostRequestbodyContentApplicationJsonSchema,
    options?: CreateWebhookOptionalParams
  ): Promise<CreateWebhookResponse> {
    const operationArguments: coreHttp.OperationArguments = {
      body,
      options: coreHttp.operationOptionsToRequestOptionsBase(options || {}),
    };
    return this.sendOperationRequest(
      operationArguments,
      createWebhookOperationSpec
    ) as Promise<CreateWebhookResponse>;
  }

  /**
   * Delete an existing event subscription.
   * @param id Id of the event subscription.
   * @param options The options parameters.
   */
  deleteWebhook(
    id: number,
    options?: DeleteWebhookOptionalParams
  ): Promise<coreHttp.RestResponse> {
    const operationArguments: coreHttp.OperationArguments = {
      id,
      options: coreHttp.operationOptionsToRequestOptionsBase(options || {}),
    };
    return this.sendOperationRequest(
      operationArguments,
      deleteWebhookOperationSpec
    ) as Promise<coreHttp.RestResponse>;
  }

  /**
   * Get all Person Fields.
   * @param options The options parameters.
   */
  getFields(options?: GetFieldsOptionalParams): Promise<GetFieldsResponse> {
    const operationArguments: coreHttp.OperationArguments = {
      options: coreHttp.operationOptionsToRequestOptionsBase(options || {}),
    };
    return this.sendOperationRequest(
      operationArguments,
      getFieldsOperationSpec
    ) as Promise<GetFieldsResponse>;
  }

  /**
   * Create Person Fields
   * @param body Array of IPersonField
   * @param options The options parameters.
   */
  createFields(
    body: IPersonField[],
    options?: CreateFieldsOptionalParams
  ): Promise<CreateFieldsResponse> {
    const operationArguments: coreHttp.OperationArguments = {
      body,
      options: coreHttp.operationOptionsToRequestOptionsBase(options || {}),
    };
    return this.sendOperationRequest(
      operationArguments,
      createFieldsOperationSpec
    ) as Promise<CreateFieldsResponse>;
  }

  /**
   * Modify Person Field
   * @param fieldName
   * @param body Represents a field (i.e. property) of a person in Encharge.
   * @param options The options parameters.
   */
  editField(
    fieldName: string,
    body: IPersonField,
    options?: EditFieldOptionalParams
  ): Promise<EditFieldResponse> {
    const operationArguments: coreHttp.OperationArguments = {
      fieldName,
      body,
      options: coreHttp.operationOptionsToRequestOptionsBase(options || {}),
    };
    return this.sendOperationRequest(
      operationArguments,
      editFieldOperationSpec
    ) as Promise<EditFieldResponse>;
  }

  /**
   * Delete Person Field
   * @param fieldName
   * @param options The options parameters.
   */
  deleteField(
    fieldName: string,
    options?: DeleteFieldOptionalParams
  ): Promise<coreHttp.RestResponse> {
    const operationArguments: coreHttp.OperationArguments = {
      fieldName,
      options: coreHttp.operationOptionsToRequestOptionsBase(options || {}),
    };
    return this.sendOperationRequest(
      operationArguments,
      deleteFieldOperationSpec
    ) as Promise<coreHttp.RestResponse>;
  }

  /**
   * Get all dynamic Segments in your account.
   * @param options The options parameters.
   */
  getSegments(
    options?: GetSegmentsOptionalParams
  ): Promise<GetSegmentsResponse> {
    const operationArguments: coreHttp.OperationArguments = {
      options: coreHttp.operationOptionsToRequestOptionsBase(options || {}),
    };
    return this.sendOperationRequest(
      operationArguments,
      getSegmentsOperationSpec
    ) as Promise<GetSegmentsResponse>;
  }

  /**
   * Create a dynamic segment.
   * @param body Make all properties in T optional
   * @param options The options parameters.
   */
  createSegment(
    body: PartialOmitIsegmentIdOrAccountIdOrVersion,
    options?: CreateSegmentOptionalParams
  ): Promise<CreateSegmentResponse> {
    const operationArguments: coreHttp.OperationArguments = {
      body,
      options: coreHttp.operationOptionsToRequestOptionsBase(options || {}),
    };
    return this.sendOperationRequest(
      operationArguments,
      createSegmentOperationSpec
    ) as Promise<CreateSegmentResponse>;
  }

  /**
   * Get people in Segment.
   * @param segmentId Id of the segment.
   * @param options The options parameters.
   */
  getPeopleInSegment(
    segmentId: number,
    options?: GetPeopleInSegmentOptionalParams
  ): Promise<GetPeopleInSegmentResponse> {
    const operationArguments: coreHttp.OperationArguments = {
      segmentId,
      options: coreHttp.operationOptionsToRequestOptionsBase(options || {}),
    };
    return this.sendOperationRequest(
      operationArguments,
      getPeopleInSegmentOperationSpec
    ) as Promise<GetPeopleInSegmentResponse>;
  }

  /**
   * Add tag(s) to an existing user.
   * @param body
   * @param options The options parameters.
   */
  addTag(
    body: Paths3I1ZetTagsPostRequestbodyContentApplicationJsonSchema,
    options?: AddTagOptionalParams
  ): Promise<coreHttp.RestResponse> {
    const operationArguments: coreHttp.OperationArguments = {
      body,
      options: coreHttp.operationOptionsToRequestOptionsBase(options || {}),
    };
    return this.sendOperationRequest(
      operationArguments,
      addTagOperationSpec
    ) as Promise<coreHttp.RestResponse>;
  }

  /**
   * Remove tag(s) from existing user.
   * @param body
   * @param options The options parameters.
   */
  removeTag(
    body: Paths1488F72TagsDeleteRequestbodyContentApplicationJsonSchema,
    options?: RemoveTagOptionalParams
  ): Promise<coreHttp.RestResponse> {
    const operationArguments: coreHttp.OperationArguments = {
      body,
      options: coreHttp.operationOptionsToRequestOptionsBase(options || {}),
    };
    return this.sendOperationRequest(
      operationArguments,
      removeTagOperationSpec
    ) as Promise<coreHttp.RestResponse>;
  }

  /**
   * Get all tags.
   * @param options The options parameters.
   */
  getAccountTags(
    options?: GetAccountTagsOptionalParams
  ): Promise<GetAccountTagsResponse> {
    const operationArguments: coreHttp.OperationArguments = {
      options: coreHttp.operationOptionsToRequestOptionsBase(options || {}),
    };
    return this.sendOperationRequest(
      operationArguments,
      getAccountTagsOperationSpec
    ) as Promise<GetAccountTagsResponse>;
  }

  /**
   * Create new tags.
   * @param body
   * @param options The options parameters.
   */
  createAccountTags(
    body: Paths5Gr823TagsManagementPostRequestbodyContentApplicationJsonSchema,
    options?: CreateAccountTagsOptionalParams
  ): Promise<CreateAccountTagsResponse> {
    const operationArguments: coreHttp.OperationArguments = {
      body,
      options: coreHttp.operationOptionsToRequestOptionsBase(options || {}),
    };
    return this.sendOperationRequest(
      operationArguments,
      createAccountTagsOperationSpec
    ) as Promise<CreateAccountTagsResponse>;
  }

  /**
   * Get all tags (with count of people tagged).
   * @param options The options parameters.
   */
  getTagCounts(
    options?: GetTagCountsOptionalParams
  ): Promise<GetTagCountsResponse> {
    const operationArguments: coreHttp.OperationArguments = {
      options: coreHttp.operationOptionsToRequestOptionsBase(options || {}),
    };
    return this.sendOperationRequest(
      operationArguments,
      getTagCountsOperationSpec
    ) as Promise<GetTagCountsResponse>;
  }

  /**
   * Update tag. If changing the name of the tag, it will be updated across all flows, segments and
   * people.
   * @param tag
   * @param body Update existing tag
   * @param options The options parameters.
   */
  updateAccountTag(
    tag: string,
    body: Tag,
    options?: UpdateAccountTagOptionalParams
  ): Promise<UpdateAccountTagResponse> {
    const operationArguments: coreHttp.OperationArguments = {
      tag,
      body,
      options: coreHttp.operationOptionsToRequestOptionsBase(options || {}),
    };
    return this.sendOperationRequest(
      operationArguments,
      updateAccountTagOperationSpec
    ) as Promise<UpdateAccountTagResponse>;
  }

  /**
   * Delete tag from current account. Deleting a tag removes it from all people in the account.
   * @param tag
   * @param options The options parameters.
   */
  deleteAccountTag(
    tag: string,
    options?: DeleteAccountTagOptionalParams
  ): Promise<coreHttp.RestResponse> {
    const operationArguments: coreHttp.OperationArguments = {
      tag,
      options: coreHttp.operationOptionsToRequestOptionsBase(options || {}),
    };
    return this.sendOperationRequest(
      operationArguments,
      deleteAccountTagOperationSpec
    ) as Promise<coreHttp.RestResponse>;
  }

  /**
   * Get all people in the account.
   * @param options The options parameters.
   */
  getAllPeople(
    options?: GetAllPeopleOptionalParams
  ): Promise<GetAllPeopleResponse> {
    const operationArguments: coreHttp.OperationArguments = {
      options: coreHttp.operationOptionsToRequestOptionsBase(options || {}),
    };
    return this.sendOperationRequest(
      operationArguments,
      getAllPeopleOperationSpec
    ) as Promise<GetAllPeopleResponse>;
  }

  /**
   * Unsubscribe a person to prevent them from receiving any more email.
   * @param options The options parameters.
   */
  unsubscribePerson(
    options?: UnsubscribePersonOptionalParams
  ): Promise<coreHttp.RestResponse> {
    const operationArguments: coreHttp.OperationArguments = {
      options: coreHttp.operationOptionsToRequestOptionsBase(options || {}),
    };
    return this.sendOperationRequest(
      operationArguments,
      unsubscribePersonOperationSpec
    ) as Promise<coreHttp.RestResponse>;
  }

  /**
   * Generate content suggestions
   * @param body
   * @param options The options parameters.
   */
  generateContentSuggestions(
    body: AIContentSuggestionGenerateParameters,
    options?: GenerateContentSuggestionsOptionalParams
  ): Promise<GenerateContentSuggestionsResponse> {
    const operationArguments: coreHttp.OperationArguments = {
      body,
      options: coreHttp.operationOptionsToRequestOptionsBase(options || {}),
    };
    return this.sendOperationRequest(
      operationArguments,
      generateContentSuggestionsOperationSpec
    ) as Promise<GenerateContentSuggestionsResponse>;
  }

  /**
   * Generate content suggestions
   * @param body
   * @param options The options parameters.
   */
  generateContentSuggestionsPublic(
    body: AIContentSuggestionGenerateParameters,
    options?: GenerateContentSuggestionsPublicOptionalParams
  ): Promise<GenerateContentSuggestionsPublicResponse> {
    const operationArguments: coreHttp.OperationArguments = {
      body,
      options: coreHttp.operationOptionsToRequestOptionsBase(options || {}),
    };
    return this.sendOperationRequest(
      operationArguments,
      generateContentSuggestionsPublicOperationSpec
    ) as Promise<GenerateContentSuggestionsPublicResponse>;
  }

  /**
   * Get favorited content suggestions
   * @param options The options parameters.
   */
  getFavoritedContentSuggestions(
    options?: GetFavoritedContentSuggestionsOptionalParams
  ): Promise<GetFavoritedContentSuggestionsResponse> {
    const operationArguments: coreHttp.OperationArguments = {
      options: coreHttp.operationOptionsToRequestOptionsBase(options || {}),
    };
    return this.sendOperationRequest(
      operationArguments,
      getFavoritedContentSuggestionsOperationSpec
    ) as Promise<GetFavoritedContentSuggestionsResponse>;
  }

  /**
   * Get favorited content suggestions
   * @param id
   * @param options The options parameters.
   */
  favoriteContentSuggestions(
    id: string,
    options?: FavoriteContentSuggestionsOptionalParams
  ): Promise<coreHttp.RestResponse> {
    const operationArguments: coreHttp.OperationArguments = {
      id,
      options: coreHttp.operationOptionsToRequestOptionsBase(options || {}),
    };
    return this.sendOperationRequest(
      operationArguments,
      favoriteContentSuggestionsOperationSpec
    ) as Promise<coreHttp.RestResponse>;
  }

  /**
   * Get all broadcasts
   * @param options The options parameters.
   */
  getBroadcasts(
    options?: GetBroadcastsOptionalParams
  ): Promise<GetBroadcastsResponse> {
    const operationArguments: coreHttp.OperationArguments = {
      options: coreHttp.operationOptionsToRequestOptionsBase(options || {}),
    };
    return this.sendOperationRequest(
      operationArguments,
      getBroadcastsOperationSpec
    ) as Promise<GetBroadcastsResponse>;
  }

  /**
   * Create broadcast
   * @param body Make all properties in T optional
   * @param options The options parameters.
   */
  createBroadcast(
    body: PartialBroadcast,
    options?: CreateBroadcastOptionalParams
  ): Promise<CreateBroadcastResponse> {
    const operationArguments: coreHttp.OperationArguments = {
      body,
      options: coreHttp.operationOptionsToRequestOptionsBase(options || {}),
    };
    return this.sendOperationRequest(
      operationArguments,
      createBroadcastOperationSpec
    ) as Promise<CreateBroadcastResponse>;
  }

  /**
   * Modify broadcast
   * @param id
   * @param body
   * @param options The options parameters.
   */
  editBroadcast(
    id: number,
    body: Broadcast,
    options?: EditBroadcastOptionalParams
  ): Promise<EditBroadcastResponse> {
    const operationArguments: coreHttp.OperationArguments = {
      id,
      body,
      options: coreHttp.operationOptionsToRequestOptionsBase(options || {}),
    };
    return this.sendOperationRequest(
      operationArguments,
      editBroadcastOperationSpec
    ) as Promise<EditBroadcastResponse>;
  }

  /**
   * Trigger a check to update the broadcast status.
   * @param id
   * @param options The options parameters.
   */
  deleteBroadcast(
    id: number,
    options?: DeleteBroadcastOptionalParams
  ): Promise<coreHttp.RestResponse> {
    const operationArguments: coreHttp.OperationArguments = {
      id,
      options: coreHttp.operationOptionsToRequestOptionsBase(options || {}),
    };
    return this.sendOperationRequest(
      operationArguments,
      deleteBroadcastOperationSpec
    ) as Promise<coreHttp.RestResponse>;
  }

  /**
   * Confirm that broadcast is ready for sending.
   * Note: If modified, a confirmed broadcast will need to be confirmed again.
   * @param id
   * @param options The options parameters.
   */
  sendBroadcast(
    id: number,
    options?: SendBroadcastOptionalParams
  ): Promise<SendBroadcastResponse> {
    const operationArguments: coreHttp.OperationArguments = {
      id,
      options: coreHttp.operationOptionsToRequestOptionsBase(options || {}),
    };
    return this.sendOperationRequest(
      operationArguments,
      sendBroadcastOperationSpec
    ) as Promise<SendBroadcastResponse>;
  }

  /**
   * Cancel a broadcast that was previously marked to be sent.
   * @param id
   * @param options The options parameters.
   */
  cancelBroadcast(
    id: number,
    options?: CancelBroadcastOptionalParams
  ): Promise<CancelBroadcastResponse> {
    const operationArguments: coreHttp.OperationArguments = {
      id,
      options: coreHttp.operationOptionsToRequestOptionsBase(options || {}),
    };
    return this.sendOperationRequest(
      operationArguments,
      cancelBroadcastOperationSpec
    ) as Promise<CancelBroadcastResponse>;
  }

  /**
   * Trigger a check to update the broadcast status.
   * @param id
   * @param options The options parameters.
   */
  updateBroadcastStatus(
    id: number,
    options?: UpdateBroadcastStatusOptionalParams
  ): Promise<UpdateBroadcastStatusResponse> {
    const operationArguments: coreHttp.OperationArguments = {
      id,
      options: coreHttp.operationOptionsToRequestOptionsBase(options || {}),
    };
    return this.sendOperationRequest(
      operationArguments,
      updateBroadcastStatusOperationSpec
    ) as Promise<UpdateBroadcastStatusResponse>;
  }

  /**
   * Get all broadcasts
   * @param id
   * @param options The options parameters.
   */
  getBroadcastEnteredCount(
    id: number,
    options?: GetBroadcastEnteredCountOptionalParams
  ): Promise<GetBroadcastEnteredCountResponse> {
    const operationArguments: coreHttp.OperationArguments = {
      id,
      options: coreHttp.operationOptionsToRequestOptionsBase(options || {}),
    };
    return this.sendOperationRequest(
      operationArguments,
      getBroadcastEnteredCountOperationSpec
    ) as Promise<GetBroadcastEnteredCountResponse>;
  }

  /**
   * Get all communication categories.
   * @param options The options parameters.
   */
  getCommunicationCategories(
    options?: GetCommunicationCategoriesOptionalParams
  ): Promise<GetCommunicationCategoriesResponse> {
    const operationArguments: coreHttp.OperationArguments = {
      options: coreHttp.operationOptionsToRequestOptionsBase(options || {}),
    };
    return this.sendOperationRequest(
      operationArguments,
      getCommunicationCategoriesOperationSpec
    ) as Promise<GetCommunicationCategoriesResponse>;
  }

  /**
   * Get people counts in communication categories.
   * @param body From T, pick a set of properties whose keys are in the union K
   * @param options The options parameters.
   */
  createCommunicationCategory(
    body: PickIcommunicationCategoryNameOrDescription,
    options?: CreateCommunicationCategoryOptionalParams
  ): Promise<CreateCommunicationCategoryResponse> {
    const operationArguments: coreHttp.OperationArguments = {
      body,
      options: coreHttp.operationOptionsToRequestOptionsBase(options || {}),
    };
    return this.sendOperationRequest(
      operationArguments,
      createCommunicationCategoryOperationSpec
    ) as Promise<CreateCommunicationCategoryResponse>;
  }

  /**
   * Modify Communication Category
   * @param id
   * @param body From T, pick a set of properties whose keys are in the union K
   * @param options The options parameters.
   */
  editCommunicationCategory(
    id: number,
    body: PickIcommunicationCategoryNameOrDescriptionOrIsArchived,
    options?: EditCommunicationCategoryOptionalParams
  ): Promise<EditCommunicationCategoryResponse> {
    const operationArguments: coreHttp.OperationArguments = {
      id,
      body,
      options: coreHttp.operationOptionsToRequestOptionsBase(options || {}),
    };
    return this.sendOperationRequest(
      operationArguments,
      editCommunicationCategoryOperationSpec
    ) as Promise<EditCommunicationCategoryResponse>;
  }

  /**
   * Get communication preferences data.
   * @param token
   * @param options The options parameters.
   */
  getCommunicationPreferencesData(
    token: string,
    options?: GetCommunicationPreferencesDataOptionalParams
  ): Promise<GetCommunicationPreferencesDataResponse> {
    const operationArguments: coreHttp.OperationArguments = {
      token,
      options: coreHttp.operationOptionsToRequestOptionsBase(options || {}),
    };
    return this.sendOperationRequest(
      operationArguments,
      getCommunicationPreferencesDataOperationSpec
    ) as Promise<GetCommunicationPreferencesDataResponse>;
  }

  /**
   * Save communication preferences for user.
   * @param token
   * @param body Dictionary of <CommunicationCategoryOptInValues>
   * @param options The options parameters.
   */
  saveCommunicationPreferences(
    token: string,
    body: { [propertyName: string]: CommunicationCategoryOptInValues },
    options?: SaveCommunicationPreferencesOptionalParams
  ): Promise<coreHttp.RestResponse> {
    const operationArguments: coreHttp.OperationArguments = {
      token,
      body,
      options: coreHttp.operationOptionsToRequestOptionsBase(options || {}),
    };
    return this.sendOperationRequest(
      operationArguments,
      saveCommunicationPreferencesOperationSpec
    ) as Promise<coreHttp.RestResponse>;
  }

  /**
   * Unsubscribe user from all communications.
   * @param token
   * @param options The options parameters.
   */
  unsubscribeFromAllCommunications(
    token: string,
    options?: UnsubscribeFromAllCommunicationsOptionalParams
  ): Promise<coreHttp.RestResponse> {
    const operationArguments: coreHttp.OperationArguments = {
      token,
      options: coreHttp.operationOptionsToRequestOptionsBase(options || {}),
    };
    return this.sendOperationRequest(
      operationArguments,
      unsubscribeFromAllCommunicationsOperationSpec
    ) as Promise<coreHttp.RestResponse>;
  }

  /**
   * Get communication preferences sample.
   * @param mode
   * @param options The options parameters.
   */
  getCommunicationPreferencesPagePreview(
    mode: Schema5,
    options?: GetCommunicationPreferencesPagePreviewOptionalParams
  ): Promise<coreHttp.RestResponse> {
    const operationArguments: coreHttp.OperationArguments = {
      mode,
      options: coreHttp.operationOptionsToRequestOptionsBase(options || {}),
    };
    return this.sendOperationRequest(
      operationArguments,
      getCommunicationPreferencesPagePreviewOperationSpec
    ) as Promise<coreHttp.RestResponse>;
  }

  /**
   * Get email addresses being verified
   * @param options The options parameters.
   */
  getEmailVerifications(
    options?: GetEmailVerificationsOptionalParams
  ): Promise<GetEmailVerificationsResponse> {
    const operationArguments: coreHttp.OperationArguments = {
      options: coreHttp.operationOptionsToRequestOptionsBase(options || {}),
    };
    return this.sendOperationRequest(
      operationArguments,
      getEmailVerificationsOperationSpec
    ) as Promise<GetEmailVerificationsResponse>;
  }

  /**
   * Start verification of email address.
   * @param body
   * @param options The options parameters.
   */
  createEmailVerification(
    body: Paths1Aso2SlSettingsEmailAddressesPostRequestbodyContentApplicationJsonSchema,
    options?: CreateEmailVerificationOptionalParams
  ): Promise<CreateEmailVerificationResponse> {
    const operationArguments: coreHttp.OperationArguments = {
      body,
      options: coreHttp.operationOptionsToRequestOptionsBase(options || {}),
    };
    return this.sendOperationRequest(
      operationArguments,
      createEmailVerificationOperationSpec
    ) as Promise<CreateEmailVerificationResponse>;
  }

  /**
   * Remove verification of email address.
   * @param email
   * @param options The options parameters.
   */
  deleteEmailVerification(
    email: string,
    options?: DeleteEmailVerificationOptionalParams
  ): Promise<coreHttp.RestResponse> {
    const operationArguments: coreHttp.OperationArguments = {
      email,
      options: coreHttp.operationOptionsToRequestOptionsBase(options || {}),
    };
    return this.sendOperationRequest(
      operationArguments,
      deleteEmailVerificationOperationSpec
    ) as Promise<coreHttp.RestResponse>;
  }

  /**
   * @param ids Array of Get0ItemsItem
   * @param options The options parameters.
   */
  getListEventSchemaMetric(
    ids: number[],
    options?: GetListEventSchemaMetricOptionalParams
  ): Promise<GetListEventSchemaMetricResponse> {
    const operationArguments: coreHttp.OperationArguments = {
      ids,
      options: coreHttp.operationOptionsToRequestOptionsBase(options || {}),
    };
    return this.sendOperationRequest(
      operationArguments,
      getListEventSchemaMetricOperationSpec
    ) as Promise<GetListEventSchemaMetricResponse>;
  }

  /**
   * @param eventSchemaId
   * @param options The options parameters.
   */
  getEventSchemaMetric(
    eventSchemaId: number,
    options?: GetEventSchemaMetricOptionalParams
  ): Promise<GetEventSchemaMetricResponse> {
    const operationArguments: coreHttp.OperationArguments = {
      eventSchemaId,
      options: coreHttp.operationOptionsToRequestOptionsBase(options || {}),
    };
    return this.sendOperationRequest(
      operationArguments,
      getEventSchemaMetricOperationSpec
    ) as Promise<GetEventSchemaMetricResponse>;
  }

  /**
   * Get list of event schemas
   * @param options The options parameters.
   */
  getListEventSchema(
    options?: GetListEventSchemaOptionalParams
  ): Promise<GetListEventSchemaResponse> {
    const operationArguments: coreHttp.OperationArguments = {
      options: coreHttp.operationOptionsToRequestOptionsBase(options || {}),
    };
    return this.sendOperationRequest(
      operationArguments,
      getListEventSchemaOperationSpec
    ) as Promise<GetListEventSchemaResponse>;
  }

  /**
   * Create new event schema
   * @param body Make all properties in T optional
   * @param options The options parameters.
   */
  createEventSchema(
    body: PartialIeventSchema,
    options?: CreateEventSchemaOptionalParams
  ): Promise<CreateEventSchemaResponse> {
    const operationArguments: coreHttp.OperationArguments = {
      body,
      options: coreHttp.operationOptionsToRequestOptionsBase(options || {}),
    };
    return this.sendOperationRequest(
      operationArguments,
      createEventSchemaOperationSpec
    ) as Promise<CreateEventSchemaResponse>;
  }

  /**
   * Edit event schema property
   * @param id
   * @param body Make all properties in T optional
   * @param options The options parameters.
   */
  editEventSchemaProperty(
    id: number,
    body: PartialIeventSchemaProperty,
    options?: EditEventSchemaPropertyOptionalParams
  ): Promise<EditEventSchemaPropertyResponse> {
    const operationArguments: coreHttp.OperationArguments = {
      id,
      body,
      options: coreHttp.operationOptionsToRequestOptionsBase(options || {}),
    };
    return this.sendOperationRequest(
      operationArguments,
      editEventSchemaPropertyOperationSpec
    ) as Promise<EditEventSchemaPropertyResponse>;
  }

  /**
   * Delete event schema property by id
   * @param id
   * @param options The options parameters.
   */
  deleteEventSchemaProperty(
    id: number,
    options?: DeleteEventSchemaPropertyOptionalParams
  ): Promise<DeleteEventSchemaPropertyResponse> {
    const operationArguments: coreHttp.OperationArguments = {
      id,
      options: coreHttp.operationOptionsToRequestOptionsBase(options || {}),
    };
    return this.sendOperationRequest(
      operationArguments,
      deleteEventSchemaPropertyOperationSpec
    ) as Promise<DeleteEventSchemaPropertyResponse>;
  }

  /**
   * Create event schema property
   * @param body Make all properties in T optional
   * @param options The options parameters.
   */
  createEventProperty(
    body: PartialIeventSchemaProperty,
    options?: CreateEventPropertyOptionalParams
  ): Promise<CreateEventPropertyResponse> {
    const operationArguments: coreHttp.OperationArguments = {
      body,
      options: coreHttp.operationOptionsToRequestOptionsBase(options || {}),
    };
    return this.sendOperationRequest(
      operationArguments,
      createEventPropertyOperationSpec
    ) as Promise<CreateEventPropertyResponse>;
  }

  /**
   * Get event schema by id
   * @param eventSchemaId
   * @param options The options parameters.
   */
  getEndUserEvent(
    eventSchemaId: number,
    options?: GetEndUserEventOptionalParams
  ): Promise<GetEndUserEventResponse> {
    const operationArguments: coreHttp.OperationArguments = {
      eventSchemaId,
      options: coreHttp.operationOptionsToRequestOptionsBase(options || {}),
    };
    return this.sendOperationRequest(
      operationArguments,
      getEndUserEventOperationSpec
    ) as Promise<GetEndUserEventResponse>;
  }

  /**
   * Delete event schema by id
   * @param id
   * @param options The options parameters.
   */
  deleteEventSchema(
    id: number,
    options?: DeleteEventSchemaOptionalParams
  ): Promise<coreHttp.RestResponse> {
    const operationArguments: coreHttp.OperationArguments = {
      id,
      options: coreHttp.operationOptionsToRequestOptionsBase(options || {}),
    };
    return this.sendOperationRequest(
      operationArguments,
      deleteEventSchemaOperationSpec
    ) as Promise<coreHttp.RestResponse>;
  }

  /**
   * Update event schema by id
   * @param id
   * @param body Make all properties in T optional
   * @param options The options parameters.
   */
  updateEventSchema(
    id: number,
    body: PartialIeventSchema,
    options?: UpdateEventSchemaOptionalParams
  ): Promise<UpdateEventSchemaResponse> {
    const operationArguments: coreHttp.OperationArguments = {
      id,
      body,
      options: coreHttp.operationOptionsToRequestOptionsBase(options || {}),
    };
    return this.sendOperationRequest(
      operationArguments,
      updateEventSchemaOperationSpec
    ) as Promise<UpdateEventSchemaResponse>;
  }

  /**
   * Get event schema by id
   * @param eventSchemaId
   * @param options The options parameters.
   */
  getEventSchema(
    eventSchemaId: number,
    options?: GetEventSchemaOptionalParams
  ): Promise<GetEventSchemaResponse> {
    const operationArguments: coreHttp.OperationArguments = {
      eventSchemaId,
      options: coreHttp.operationOptionsToRequestOptionsBase(options || {}),
    };
    return this.sendOperationRequest(
      operationArguments,
      getEventSchemaOperationSpec
    ) as Promise<GetEventSchemaResponse>;
  }

  /**
   * Get form by id
   * @param id
   * @param options The options parameters.
   */
  getForm(
    id: string,
    options?: GetFormOptionalParams
  ): Promise<GetFormResponse> {
    const operationArguments: coreHttp.OperationArguments = {
      id,
      options: coreHttp.operationOptionsToRequestOptionsBase(options || {}),
    };
    return this.sendOperationRequest(
      operationArguments,
      getFormOperationSpec
    ) as Promise<GetFormResponse>;
  }

  /**
   * Update form
   * @param id
   * @param body Make all properties in T optional
   * @param options The options parameters.
   */
  updateForm(
    id: string,
    body: PartialIform,
    options?: UpdateFormOptionalParams
  ): Promise<UpdateFormResponse> {
    const operationArguments: coreHttp.OperationArguments = {
      id,
      body,
      options: coreHttp.operationOptionsToRequestOptionsBase(options || {}),
    };
    return this.sendOperationRequest(
      operationArguments,
      updateFormOperationSpec
    ) as Promise<UpdateFormResponse>;
  }

  /**
   * delete form
   * @param id
   * @param options The options parameters.
   */
  archiveForm(
    id: string,
    options?: ArchiveFormOptionalParams
  ): Promise<ArchiveFormResponse> {
    const operationArguments: coreHttp.OperationArguments = {
      id,
      options: coreHttp.operationOptionsToRequestOptionsBase(options || {}),
    };
    return this.sendOperationRequest(
      operationArguments,
      archiveFormOperationSpec
    ) as Promise<ArchiveFormResponse>;
  }

  /**
   * Get form and non-sensitive account information by form Id without authentication
   * @param id
   * @param options The options parameters.
   */
  getFormShared(
    id: string,
    options?: GetFormSharedOptionalParams
  ): Promise<GetFormSharedResponse> {
    const operationArguments: coreHttp.OperationArguments = {
      id,
      options: coreHttp.operationOptionsToRequestOptionsBase(options || {}),
    };
    return this.sendOperationRequest(
      operationArguments,
      getFormSharedOperationSpec
    ) as Promise<GetFormSharedResponse>;
  }

  /**
   * Get form of account
   * @param options The options parameters.
   */
  getForms(options?: GetFormsOptionalParams): Promise<GetFormsResponse> {
    const operationArguments: coreHttp.OperationArguments = {
      options: coreHttp.operationOptionsToRequestOptionsBase(options || {}),
    };
    return this.sendOperationRequest(
      operationArguments,
      getFormsOperationSpec
    ) as Promise<GetFormsResponse>;
  }

  /**
   * Create Form
   * @param body Make all properties in T optional
   * @param options The options parameters.
   */
  createForm(
    body: PartialIform,
    options?: CreateFormOptionalParams
  ): Promise<CreateFormResponse> {
    const operationArguments: coreHttp.OperationArguments = {
      body,
      options: coreHttp.operationOptionsToRequestOptionsBase(options || {}),
    };
    return this.sendOperationRequest(
      operationArguments,
      createFormOperationSpec
    ) as Promise<CreateFormResponse>;
  }

  /**
   * Submit form
   * @param id
   * @param body Anything
   * @param options The options parameters.
   */
  submitForm(
    id: string,
    body: any,
    options?: SubmitFormOptionalParams
  ): Promise<SubmitFormResponse> {
    const operationArguments: coreHttp.OperationArguments = {
      id,
      body,
      options: coreHttp.operationOptionsToRequestOptionsBase(options || {}),
    };
    return this.sendOperationRequest(
      operationArguments,
      submitFormOperationSpec
    ) as Promise<SubmitFormResponse>;
  }

  /**
   * Submit form
   * @param id
   * @param options The options parameters.
   */
  plainHtmlSubmission(
    id: string,
    options?: PlainHtmlSubmissionOptionalParams
  ): Promise<PlainHtmlSubmissionResponse> {
    const operationArguments: coreHttp.OperationArguments = {
      id,
      options: coreHttp.operationOptionsToRequestOptionsBase(options || {}),
    };
    return this.sendOperationRequest(
      operationArguments,
      plainHtmlSubmissionOperationSpec
    ) as Promise<PlainHtmlSubmissionResponse>;
  }

  /**
   * Duplicated form
   * @param id
   * @param options The options parameters.
   */
  duplicateForm(
    id: string,
    options?: DuplicateFormOptionalParams
  ): Promise<DuplicateFormResponse> {
    const operationArguments: coreHttp.OperationArguments = {
      id,
      options: coreHttp.operationOptionsToRequestOptionsBase(options || {}),
    };
    return this.sendOperationRequest(
      operationArguments,
      duplicateFormOperationSpec
    ) as Promise<DuplicateFormResponse>;
  }

  /**
   * Rest form order
   * @param id
   * @param options The options parameters.
   */
  resetOrder(
    id: string,
    options?: ResetOrderOptionalParams
  ): Promise<ResetOrderResponse> {
    const operationArguments: coreHttp.OperationArguments = {
      id,
      options: coreHttp.operationOptionsToRequestOptionsBase(options || {}),
    };
    return this.sendOperationRequest(
      operationArguments,
      resetOrderOperationSpec
    ) as Promise<ResetOrderResponse>;
  }

  /**
   * Update draft form
   * @param id
   * @param body Make all properties in T optional
   * @param options The options parameters.
   */
  updateFormDraft(
    id: string,
    body: PartialIform,
    options?: UpdateFormDraftOptionalParams
  ): Promise<UpdateFormDraftResponse> {
    const operationArguments: coreHttp.OperationArguments = {
      id,
      body,
      options: coreHttp.operationOptionsToRequestOptionsBase(options || {}),
    };
    return this.sendOperationRequest(
      operationArguments,
      updateFormDraftOperationSpec
    ) as Promise<UpdateFormDraftResponse>;
  }

  /**
   * Create form object
   * @param body Make all properties in T optional
   * @param options The options parameters.
   */
  createFormObject(
    body: PartialIformObject,
    options?: CreateFormObjectOptionalParams
  ): Promise<CreateFormObjectResponse> {
    const operationArguments: coreHttp.OperationArguments = {
      body,
      options: coreHttp.operationOptionsToRequestOptionsBase(options || {}),
    };
    return this.sendOperationRequest(
      operationArguments,
      createFormObjectOperationSpec
    ) as Promise<CreateFormObjectResponse>;
  }

  /**
   * Update form object
   * @param id
   * @param body Make all properties in T optional
   * @param options The options parameters.
   */
  updateFormObject(
    id: string,
    body: PartialIformObject,
    options?: UpdateFormObjectOptionalParams
  ): Promise<UpdateFormObjectResponse> {
    const operationArguments: coreHttp.OperationArguments = {
      id,
      body,
      options: coreHttp.operationOptionsToRequestOptionsBase(options || {}),
    };
    return this.sendOperationRequest(
      operationArguments,
      updateFormObjectOperationSpec
    ) as Promise<UpdateFormObjectResponse>;
  }

  /**
   * Archive form object
   * We use soft delete to prevent unexpected error when user uses stale form
   * @param id
   * @param options The options parameters.
   */
  archiveFormObject(
    id: string,
    options?: ArchiveFormObjectOptionalParams
  ): Promise<ArchiveFormObjectResponse> {
    const operationArguments: coreHttp.OperationArguments = {
      id,
      options: coreHttp.operationOptionsToRequestOptionsBase(options || {}),
    };
    return this.sendOperationRequest(
      operationArguments,
      archiveFormObjectOperationSpec
    ) as Promise<ArchiveFormObjectResponse>;
  }

  /**
   * Update draft form object
   * @param id
   * @param body Make all properties in T optional
   * @param options The options parameters.
   */
  updateDraftFormObject(
    id: string,
    body: PartialIformObject,
    options?: UpdateDraftFormObjectOptionalParams
  ): Promise<UpdateDraftFormObjectResponse> {
    const operationArguments: coreHttp.OperationArguments = {
      id,
      body,
      options: coreHttp.operationOptionsToRequestOptionsBase(options || {}),
    };
    return this.sendOperationRequest(
      operationArguments,
      updateDraftFormObjectOperationSpec
    ) as Promise<UpdateDraftFormObjectResponse>;
  }

  /**
   * Get form submissions
   * @param id
   * @param options The options parameters.
   */
  getFormSubmissions(
    id: string,
    options?: GetFormSubmissionsOptionalParams
  ): Promise<GetFormSubmissionsResponse> {
    const operationArguments: coreHttp.OperationArguments = {
      id,
      options: coreHttp.operationOptionsToRequestOptionsBase(options || {}),
    };
    return this.sendOperationRequest(
      operationArguments,
      getFormSubmissionsOperationSpec
    ) as Promise<GetFormSubmissionsResponse>;
  }

  /**
   * Publish draft form
   * @param id
   * @param options The options parameters.
   */
  publishDraftForm(
    id: string,
    options?: PublishDraftFormOptionalParams
  ): Promise<PublishDraftFormResponse> {
    const operationArguments: coreHttp.OperationArguments = {
      id,
      options: coreHttp.operationOptionsToRequestOptionsBase(options || {}),
    };
    return this.sendOperationRequest(
      operationArguments,
      publishDraftFormOperationSpec
    ) as Promise<PublishDraftFormResponse>;
  }

  /**
   * Discard draft form changes
   * @param id
   * @param options The options parameters.
   */
  cancelDraftForm(
    id: string,
    options?: CancelDraftFormOptionalParams
  ): Promise<CancelDraftFormResponse> {
    const operationArguments: coreHttp.OperationArguments = {
      id,
      options: coreHttp.operationOptionsToRequestOptionsBase(options || {}),
    };
    return this.sendOperationRequest(
      operationArguments,
      cancelDraftFormOperationSpec
    ) as Promise<CancelDraftFormResponse>;
  }

  /**
   * Create new decoration
   * @param body
   * @param options The options parameters.
   */
  createDecoration(
    body: IntegrationDecoration,
    options?: CreateDecorationOptionalParams
  ): Promise<CreateDecorationResponse> {
    const operationArguments: coreHttp.OperationArguments = {
      body,
      options: coreHttp.operationOptionsToRequestOptionsBase(options || {}),
    };
    return this.sendOperationRequest(
      operationArguments,
      createDecorationOperationSpec
    ) as Promise<CreateDecorationResponse>;
  }

  /**
   * Modify decoration
   * @param decorationId
   * @param body
   * @param options The options parameters.
   */
  updateDecoration(
    decorationId: string,
    body: IntegrationDecoration,
    options?: UpdateDecorationOptionalParams
  ): Promise<UpdateDecorationResponse> {
    const operationArguments: coreHttp.OperationArguments = {
      decorationId,
      body,
      options: coreHttp.operationOptionsToRequestOptionsBase(options || {}),
    };
    return this.sendOperationRequest(
      operationArguments,
      updateDecorationOperationSpec
    ) as Promise<UpdateDecorationResponse>;
  }

  /**
   * Delete decoration
   * @param decorationId
   * @param options The options parameters.
   */
  deleteDecoration(
    decorationId: string,
    options?: DeleteDecorationOptionalParams
  ): Promise<coreHttp.RestResponse> {
    const operationArguments: coreHttp.OperationArguments = {
      decorationId,
      options: coreHttp.operationOptionsToRequestOptionsBase(options || {}),
    };
    return this.sendOperationRequest(
      operationArguments,
      deleteDecorationOperationSpec
    ) as Promise<coreHttp.RestResponse>;
  }

  /**
   * Delete a segment.
   * @param id
   * @param options The options parameters.
   */
  deleteSegment(
    id: number,
    options?: DeleteSegmentOptionalParams
  ): Promise<coreHttp.RestResponse> {
    const operationArguments: coreHttp.OperationArguments = {
      id,
      options: coreHttp.operationOptionsToRequestOptionsBase(options || {}),
    };
    return this.sendOperationRequest(
      operationArguments,
      deleteSegmentOperationSpec
    ) as Promise<coreHttp.RestResponse>;
  }

  /**
   * Modify a dynamic segment.
   * @param id
   * @param body Make all properties in T optional
   * @param options The options parameters.
   */
  modifySegment(
    id: number,
    body: PartialIsegment,
    options?: ModifySegmentOptionalParams
  ): Promise<ModifySegmentResponse> {
    const operationArguments: coreHttp.OperationArguments = {
      id,
      body,
      options: coreHttp.operationOptionsToRequestOptionsBase(options || {}),
    };
    return this.sendOperationRequest(
      operationArguments,
      modifySegmentOperationSpec
    ) as Promise<ModifySegmentResponse>;
  }

  /**
   * Get the health of a syncing service
   * @param serviceId Name of the sync service.
   * @param options The options parameters.
   */
  getSyncHealth(
    serviceId: string,
    options?: GetSyncHealthOptionalParams
  ): Promise<GetSyncHealthResponse> {
    const operationArguments: coreHttp.OperationArguments = {
      serviceId,
      options: coreHttp.operationOptionsToRequestOptionsBase(options || {}),
    };
    return this.sendOperationRequest(
      operationArguments,
      getSyncHealthOperationSpec
    ) as Promise<GetSyncHealthResponse>;
  }

  /**
   * @param serviceId Name of the sync service.
   * @param options The options parameters.
   */
  setupSync(
    serviceId: string,
    options?: SetupSyncOptionalParams
  ): Promise<SetupSyncResponse> {
    const operationArguments: coreHttp.OperationArguments = {
      serviceId,
      options: coreHttp.operationOptionsToRequestOptionsBase(options || {}),
    };
    return this.sendOperationRequest(
      operationArguments,
      setupSyncOperationSpec
    ) as Promise<SetupSyncResponse>;
  }

  /**
   * @param serviceId Name of the sync service.
   * @param options The options parameters.
   */
  teardownSync(
    serviceId: string,
    options?: TeardownSyncOptionalParams
  ): Promise<TeardownSyncResponse> {
    const operationArguments: coreHttp.OperationArguments = {
      serviceId,
      options: coreHttp.operationOptionsToRequestOptionsBase(options || {}),
    };
    return this.sendOperationRequest(
      operationArguments,
      teardownSyncOperationSpec
    ) as Promise<TeardownSyncResponse>;
  }

  /**
   * Get all team members per account
   * @param options The options parameters.
   */
  getTeamMembers(
    options?: GetTeamMembersOptionalParams
  ): Promise<GetTeamMembersResponse> {
    const operationArguments: coreHttp.OperationArguments = {
      options: coreHttp.operationOptionsToRequestOptionsBase(options || {}),
    };
    return this.sendOperationRequest(
      operationArguments,
      getTeamMembersOperationSpec
    ) as Promise<GetTeamMembersResponse>;
  }

  /**
   * Create new team member
   * @param body
   * @param options The options parameters.
   */
  createTeamMember(
    body: Paths1Yfp89NTeamMembersPostRequestbodyContentApplicationJsonSchema,
    options?: CreateTeamMemberOptionalParams
  ): Promise<CreateTeamMemberResponse> {
    const operationArguments: coreHttp.OperationArguments = {
      body,
      options: coreHttp.operationOptionsToRequestOptionsBase(options || {}),
    };
    return this.sendOperationRequest(
      operationArguments,
      createTeamMemberOperationSpec
    ) as Promise<CreateTeamMemberResponse>;
  }

  /**
   * Edit new team member
   * @param id Anything
   * @param body
   * @param options The options parameters.
   */
  updateTeamMember(
    id: any,
    body: Paths1P1Ub3PTeamMembersIdPatchRequestbodyContentApplicationJsonSchema,
    options?: UpdateTeamMemberOptionalParams
  ): Promise<UpdateTeamMemberResponse> {
    const operationArguments: coreHttp.OperationArguments = {
      id,
      body,
      options: coreHttp.operationOptionsToRequestOptionsBase(options || {}),
    };
    return this.sendOperationRequest(
      operationArguments,
      updateTeamMemberOperationSpec
    ) as Promise<UpdateTeamMemberResponse>;
  }

  /**
   * Delete team member
   * @param id
   * @param options The options parameters.
   */
  deleteTeamMember(
    id: number,
    options?: DeleteTeamMemberOptionalParams
  ): Promise<coreHttp.RestResponse> {
    const operationArguments: coreHttp.OperationArguments = {
      id,
      options: coreHttp.operationOptionsToRequestOptionsBase(options || {}),
    };
    return this.sendOperationRequest(
      operationArguments,
      deleteTeamMemberOperationSpec
    ) as Promise<coreHttp.RestResponse>;
  }

  /**
   * Get subaccounts connected to current account (siblings, children or parents).
   * @param options The options parameters.
   */
  getTeamMemberAccounts(
    options?: GetTeamMemberAccountsOptionalParams
  ): Promise<GetTeamMemberAccountsResponse> {
    const operationArguments: coreHttp.OperationArguments = {
      options: coreHttp.operationOptionsToRequestOptionsBase(options || {}),
    };
    return this.sendOperationRequest(
      operationArguments,
      getTeamMemberAccountsOperationSpec
    ) as Promise<GetTeamMemberAccountsResponse>;
  }

  /**
   * Create another account for this team member
   * @param body
   * @param options The options parameters.
   */
  createAccountForTeamMember(
    body: PathsNkvme4TeamMembersAccountsPostRequestbodyContentApplicationJsonSchema,
    options?: CreateAccountForTeamMemberOptionalParams
  ): Promise<CreateAccountForTeamMemberResponse> {
    const operationArguments: coreHttp.OperationArguments = {
      body,
      options: coreHttp.operationOptionsToRequestOptionsBase(options || {}),
    };
    return this.sendOperationRequest(
      operationArguments,
      createAccountForTeamMemberOperationSpec
    ) as Promise<CreateAccountForTeamMemberResponse>;
  }
}
// Operation Specifications
const serializer = new coreHttp.Serializer(Mappers, /* isXml */ false);

const getInfoOperationSpec: coreHttp.OperationSpec = {
  path: "/accounts/info",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper:
        Mappers.Paths1No26BnAccountsInfoGetResponses200ContentApplicationJsonSchema,
    },
  },
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer,
};
const getAccountInfoByFormIdOperationSpec: coreHttp.OperationSpec = {
  path: "/accounts/form/{formId}",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper:
        Mappers.PathsYhuyfxAccountsFormFormidGetResponses200ContentApplicationJsonSchema,
    },
  },
  urlParameters: [Parameters.$host, Parameters.formId],
  headerParameters: [Parameters.accept],
  serializer,
};
const getCustomObjectsOperationSpec: coreHttp.OperationSpec = {
  path: "/objects/{objectName}",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper:
        Mappers.Paths1O8Yf3GObjectsObjectnameGetResponses200ContentApplicationJsonSchema,
    },
  },
  queryParameters: [
    Parameters.attributes,
    Parameters.sort,
    Parameters.order,
    Parameters.offset,
    Parameters.limit,
  ],
  urlParameters: [Parameters.$host, Parameters.objectName],
  headerParameters: [Parameters.accept],
  serializer,
};
const createCustomObjectsOperationSpec: coreHttp.OperationSpec = {
  path: "/objects/{objectName}",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper:
        Mappers.Paths12Ynui3ObjectsObjectnamePostResponses200ContentApplicationJsonSchema,
    },
  },
  requestBody: Parameters.body,
  urlParameters: [Parameters.$host, Parameters.objectName],
  headerParameters: [Parameters.accept, Parameters.contentType],
  mediaType: "json",
  serializer,
};
const createOrUpdateCustomObjectsOperationSpec: coreHttp.OperationSpec = {
  path: "/objects/{objectName}",
  httpMethod: "PUT",
  responses: {
    200: {
      bodyMapper:
        Mappers.PathsOzwy89ObjectsObjectnamePutResponses200ContentApplicationJsonSchema,
    },
  },
  requestBody: Parameters.body1,
  urlParameters: [Parameters.$host, Parameters.objectName],
  headerParameters: [Parameters.accept, Parameters.contentType],
  mediaType: "json",
  serializer,
};
const getCustomObjectsInSegmentOperationSpec: coreHttp.OperationSpec = {
  path: "/objects/{objectName}/segments/{segmentId}",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper:
        Mappers.Paths44QwncObjectsObjectnameSegmentsSegmentidGetResponses200ContentApplicationJsonSchema,
    },
  },
  queryParameters: [
    Parameters.attributes,
    Parameters.sort,
    Parameters.offset,
    Parameters.limit,
    Parameters.order1,
  ],
  urlParameters: [
    Parameters.$host,
    Parameters.objectName,
    Parameters.segmentId,
  ],
  headerParameters: [Parameters.accept],
  serializer,
};
const getCustomObjectsCountOperationSpec: coreHttp.OperationSpec = {
  path: "/objects/{objectName}/count",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper:
        Mappers.PathsT2XhptObjectsObjectnameCountGetResponses200ContentApplicationJsonSchema,
    },
  },
  urlParameters: [Parameters.$host, Parameters.objectName],
  headerParameters: [Parameters.accept],
  serializer,
};
const getCustomObjectsInSegmentCountOperationSpec: coreHttp.OperationSpec = {
  path: "/objects/{objectName}/segments/{segmentId}/count",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper:
        Mappers.Paths977R58ObjectsObjectnameSegmentsSegmentidCountGetResponses200ContentApplicationJsonSchema,
    },
  },
  urlParameters: [
    Parameters.$host,
    Parameters.objectName,
    Parameters.segmentId,
  ],
  headerParameters: [Parameters.accept],
  serializer,
};
const searchCustomObjectsOperationSpec: coreHttp.OperationSpec = {
  path: "/objects/{objectName}/search",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper:
        Mappers.Paths1Yws1VzObjectsObjectnameSearchGetResponses200ContentApplicationJsonSchema,
    },
  },
  queryParameters: [
    Parameters.offset,
    Parameters.limit,
    Parameters.query,
    Parameters.segmentId1,
  ],
  urlParameters: [Parameters.$host, Parameters.objectName],
  headerParameters: [Parameters.accept],
  serializer,
};
const getCustomObjectByIdOperationSpec: coreHttp.OperationSpec = {
  path: "/objects/{objectName}/{id}",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper:
        Mappers.Paths14Z9G5IObjectsObjectnameIdGetResponses200ContentApplicationJsonSchema,
    },
  },
  urlParameters: [Parameters.$host, Parameters.objectName, Parameters.id],
  headerParameters: [Parameters.accept],
  serializer,
};
const updateCustomObjectOperationSpec: coreHttp.OperationSpec = {
  path: "/objects/{objectName}/{id}",
  httpMethod: "PATCH",
  responses: {
    200: {
      bodyMapper:
        Mappers.Paths4Q4Gg9ObjectsObjectnameIdPatchResponses200ContentApplicationJsonSchema,
    },
  },
  requestBody: Parameters.body2,
  urlParameters: [Parameters.$host, Parameters.objectName, Parameters.id],
  headerParameters: [Parameters.accept, Parameters.contentType],
  mediaType: "json",
  serializer,
};
const deleteCustomObjectOperationSpec: coreHttp.OperationSpec = {
  path: "/objects/{objectName}/{id}",
  httpMethod: "DELETE",
  responses: { 204: {} },
  urlParameters: [Parameters.$host, Parameters.objectName, Parameters.id],
  serializer,
};
const getCustomObjectByExternalIdOperationSpec: coreHttp.OperationSpec = {
  path: "/objects/{objectName}/externalId/{externalId}",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper:
        Mappers.Paths9B48PyObjectsObjectnameExternalidGetResponses200ContentApplicationJsonSchema,
    },
  },
  urlParameters: [
    Parameters.$host,
    Parameters.objectName,
    Parameters.externalId,
  ],
  headerParameters: [Parameters.accept],
  serializer,
};
const updateCustomObjectByExternalIdOperationSpec: coreHttp.OperationSpec = {
  path: "/objects/{objectName}/externalId/{externalId}",
  httpMethod: "PATCH",
  responses: {
    200: {
      bodyMapper:
        Mappers.PathsXknmnkObjectsObjectnameExternalidPatchResponses200ContentApplicationJsonSchema,
    },
  },
  requestBody: Parameters.body1,
  urlParameters: [
    Parameters.$host,
    Parameters.objectName,
    Parameters.externalId,
  ],
  headerParameters: [Parameters.accept, Parameters.contentType],
  mediaType: "json",
  serializer,
};
const deleteCustomObjectByExternalIdOperationSpec: coreHttp.OperationSpec = {
  path: "/objects/{objectName}/externalId/{externalId}",
  httpMethod: "DELETE",
  responses: { 204: {} },
  urlParameters: [
    Parameters.$host,
    Parameters.objectName,
    Parameters.externalId,
  ],
  serializer,
};
const getObjectsByAssociationOperationSpec: coreHttp.OperationSpec = {
  path: "/objects/{objectName}/{id}/associations/{associationId}",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper:
        Mappers.PathsCn7LqdObjectsObjectnameIdAssociationsAssociationidGetResponses200ContentApplicationJsonSchema,
    },
  },
  queryParameters: [
    Parameters.attributes,
    Parameters.sort,
    Parameters.offset,
    Parameters.limit,
    Parameters.order2,
  ],
  urlParameters: [
    Parameters.$host,
    Parameters.objectName,
    Parameters.id1,
    Parameters.associationId,
  ],
  headerParameters: [Parameters.accept],
  serializer,
};
const getAllAssociatedObjectsOperationSpec: coreHttp.OperationSpec = {
  path: "/objects/{objectName}/{id}/associations",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper:
        Mappers.PathsWpz3OpObjectsObjectnameIdAssociationsGetResponses200ContentApplicationJsonSchema,
    },
  },
  queryParameters: [Parameters.limit],
  urlParameters: [Parameters.$host, Parameters.objectName, Parameters.id1],
  headerParameters: [Parameters.accept],
  serializer,
};
const associateObjectsByDefaultAssociationOperationSpec: coreHttp.OperationSpec = {
  path:
    "/objects/{objectName}/{id}/associations/default/{targetObjectName}/{targetId}",
  httpMethod: "POST",
  responses: { 201: {} },
  urlParameters: [
    Parameters.$host,
    Parameters.objectName,
    Parameters.id1,
    Parameters.targetObjectName,
    Parameters.targetId,
  ],
  serializer,
};
const associateCustomObjectsOperationSpec: coreHttp.OperationSpec = {
  path: "/objects/{objectName}/{id}/associations/{associationId}/{targetId}",
  httpMethod: "POST",
  responses: { 201: {} },
  urlParameters: [
    Parameters.$host,
    Parameters.objectName,
    Parameters.id1,
    Parameters.associationId,
    Parameters.targetId,
  ],
  serializer,
};
const removeCustomObjectsAssociationOperationSpec: coreHttp.OperationSpec = {
  path: "/objects/{objectName}/{id}/associations/{associationId}/{targetId}",
  httpMethod: "DELETE",
  responses: { 204: {} },
  urlParameters: [
    Parameters.$host,
    Parameters.objectName,
    Parameters.id1,
    Parameters.associationId,
    Parameters.targetId,
  ],
  serializer,
};
const getCustomObjectsSchemaOperationSpec: coreHttp.OperationSpec = {
  path: "/schemas",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper:
        Mappers.Paths146I63ISchemasGetResponses200ContentApplicationJsonSchema,
    },
  },
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer,
};
const createCustomObjectSchemaOperationSpec: coreHttp.OperationSpec = {
  path: "/schemas",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper:
        Mappers.PathsCuxwh4SchemasPostResponses200ContentApplicationJsonSchema,
    },
  },
  requestBody: Parameters.body3,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept, Parameters.contentType],
  mediaType: "json",
  serializer,
};
const getCustomObjectSchemaOperationSpec: coreHttp.OperationSpec = {
  path: "/schemas/{objectName}",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper:
        Mappers.Paths1Riv1ElSchemasObjectnameGetResponses200ContentApplicationJsonSchema,
    },
  },
  urlParameters: [Parameters.$host, Parameters.objectName],
  headerParameters: [Parameters.accept],
  serializer,
};
const updateCustomObjectSchemaOperationSpec: coreHttp.OperationSpec = {
  path: "/schemas/{objectName}",
  httpMethod: "PATCH",
  responses: {
    200: {
      bodyMapper:
        Mappers.PathsV1Bg0USchemasObjectnamePatchResponses200ContentApplicationJsonSchema,
    },
  },
  requestBody: Parameters.body4,
  urlParameters: [Parameters.$host, Parameters.objectName],
  headerParameters: [Parameters.accept, Parameters.contentType],
  mediaType: "json",
  serializer,
};
const deleteCustomObjectSchemaOperationSpec: coreHttp.OperationSpec = {
  path: "/schemas/{objectName}",
  httpMethod: "DELETE",
  responses: { 204: {} },
  urlParameters: [Parameters.$host, Parameters.objectName],
  serializer,
};
const createObjectFieldsOperationSpec: coreHttp.OperationSpec = {
  path: "/schemas/{objectName}/fields",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper:
        Mappers.PathsNnb827SchemasObjectnameFieldsPostResponses200ContentApplicationJsonSchema,
    },
  },
  requestBody: Parameters.body5,
  urlParameters: [Parameters.$host, Parameters.objectName],
  headerParameters: [Parameters.accept, Parameters.contentType],
  mediaType: "json",
  serializer,
};
const editObjectFieldOperationSpec: coreHttp.OperationSpec = {
  path: "/schemas/{objectName}/fields/{fieldName}",
  httpMethod: "PATCH",
  responses: {
    200: {
      bodyMapper:
        Mappers.Paths1M0HafgSchemasObjectnameFieldsFieldnamePatchResponses200ContentApplicationJsonSchema,
    },
  },
  requestBody: Parameters.body6,
  urlParameters: [
    Parameters.$host,
    Parameters.objectName,
    Parameters.fieldName,
  ],
  headerParameters: [Parameters.accept, Parameters.contentType],
  mediaType: "json",
  serializer,
};
const deleteObjectFieldOperationSpec: coreHttp.OperationSpec = {
  path: "/schemas/{objectName}/fields/{fieldName}",
  httpMethod: "DELETE",
  responses: { 204: {} },
  urlParameters: [
    Parameters.$host,
    Parameters.objectName,
    Parameters.fieldName,
  ],
  serializer,
};
const defineCustomObjectsAssociationSchemaOperationSpec: coreHttp.OperationSpec = {
  path: "/schemas/associations",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper:
        Mappers.Paths1AqqxrsSchemasAssociationsPostResponses200ContentApplicationJsonSchema,
    },
  },
  requestBody: Parameters.body7,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept, Parameters.contentType],
  mediaType: "json",
  serializer,
};
const removeCustomObjectsAssociationSchemaOperationSpec: coreHttp.OperationSpec = {
  path: "/schemas/associations/{id}",
  httpMethod: "DELETE",
  responses: { 204: {} },
  urlParameters: [Parameters.$host, Parameters.id],
  serializer,
};
const getEmailOperationSpec: coreHttp.OperationSpec = {
  path: "/emails/{id}",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper:
        Mappers.Paths14Sy62FEmailsIdGetResponses200ContentApplicationJsonSchema,
    },
  },
  urlParameters: [Parameters.$host, Parameters.id],
  headerParameters: [Parameters.accept],
  serializer,
};
const modifyEmailOperationSpec: coreHttp.OperationSpec = {
  path: "/emails/{id}",
  httpMethod: "PATCH",
  responses: {
    200: {
      bodyMapper:
        Mappers.Paths110Q3SsEmailsIdPatchResponses200ContentApplicationJsonSchema,
    },
  },
  requestBody: Parameters.body8,
  urlParameters: [Parameters.$host, Parameters.id],
  headerParameters: [Parameters.accept, Parameters.contentType],
  mediaType: "json",
  serializer,
};
const deleteEmailOperationSpec: coreHttp.OperationSpec = {
  path: "/emails/{id}",
  httpMethod: "DELETE",
  responses: { 204: {} },
  urlParameters: [Parameters.$host, Parameters.id],
  serializer,
};
const createEmailOperationSpec: coreHttp.OperationSpec = {
  path: "/emails",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper:
        Mappers.Paths1P5RrhbEmailsPostResponses200ContentApplicationJsonSchema,
    },
  },
  requestBody: Parameters.body9,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept, Parameters.contentType],
  mediaType: "json",
  serializer,
};
const createWebhookOperationSpec: coreHttp.OperationSpec = {
  path: "/event-subscriptions",
  httpMethod: "POST",
  responses: {
    201: {
      bodyMapper:
        Mappers.PathsS40Fw6EventSubscriptionsPostResponses201ContentApplicationJsonSchema,
    },
  },
  requestBody: Parameters.body10,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept, Parameters.contentType],
  mediaType: "json",
  serializer,
};
const deleteWebhookOperationSpec: coreHttp.OperationSpec = {
  path: "/event-subscriptions/{id}",
  httpMethod: "DELETE",
  responses: { 204: {} },
  urlParameters: [Parameters.$host, Parameters.id],
  serializer,
};
const getFieldsOperationSpec: coreHttp.OperationSpec = {
  path: "/fields",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper:
        Mappers.Paths1Wwtb3VFieldsGetResponses200ContentApplicationJsonSchema,
    },
  },
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer,
};
const createFieldsOperationSpec: coreHttp.OperationSpec = {
  path: "/fields",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper:
        Mappers.PathsGrbnpqFieldsPostResponses200ContentApplicationJsonSchema,
    },
  },
  requestBody: Parameters.body5,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept, Parameters.contentType],
  mediaType: "json",
  serializer,
};
const editFieldOperationSpec: coreHttp.OperationSpec = {
  path: "/fields/{fieldName}",
  httpMethod: "PATCH",
  responses: {
    200: {
      bodyMapper:
        Mappers.Paths19Z8IbcFieldsFieldnamePatchResponses200ContentApplicationJsonSchema,
    },
  },
  requestBody: Parameters.body11,
  urlParameters: [Parameters.$host, Parameters.fieldName],
  headerParameters: [Parameters.accept, Parameters.contentType],
  mediaType: "json",
  serializer,
};
const deleteFieldOperationSpec: coreHttp.OperationSpec = {
  path: "/fields/{fieldName}",
  httpMethod: "DELETE",
  responses: { 204: {} },
  urlParameters: [Parameters.$host, Parameters.fieldName],
  serializer,
};
const getSegmentsOperationSpec: coreHttp.OperationSpec = {
  path: "/segments",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper:
        Mappers.Paths12Fu16GSegmentsGetResponses200ContentApplicationJsonSchema,
    },
  },
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer,
};
const createSegmentOperationSpec: coreHttp.OperationSpec = {
  path: "/segments",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper:
        Mappers.Paths14Y7EzpSegmentsPostResponses200ContentApplicationJsonSchema,
    },
  },
  requestBody: Parameters.body12,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept, Parameters.contentType],
  mediaType: "json",
  serializer,
};
const getPeopleInSegmentOperationSpec: coreHttp.OperationSpec = {
  path: "/segments/{segmentId}/people",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper:
        Mappers.Paths6Py3Y3SegmentsSegmentidPeopleGetResponses200ContentApplicationJsonSchema,
    },
  },
  queryParameters: [
    Parameters.attributes,
    Parameters.sort,
    Parameters.limit1,
    Parameters.offset1,
    Parameters.order3,
    Parameters.ignoreAnonymous,
  ],
  urlParameters: [Parameters.$host, Parameters.segmentId],
  headerParameters: [Parameters.accept],
  serializer,
};
const addTagOperationSpec: coreHttp.OperationSpec = {
  path: "/tags",
  httpMethod: "POST",
  responses: { 201: {} },
  requestBody: Parameters.body13,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType],
  mediaType: "json",
  serializer,
};
const removeTagOperationSpec: coreHttp.OperationSpec = {
  path: "/tags",
  httpMethod: "DELETE",
  responses: { 204: {} },
  requestBody: Parameters.body14,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType],
  mediaType: "json",
  serializer,
};
const getAccountTagsOperationSpec: coreHttp.OperationSpec = {
  path: "/tags-management",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper:
        Mappers.PathsJh2Cb5TagsManagementGetResponses200ContentApplicationJsonSchema,
    },
  },
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer,
};
const createAccountTagsOperationSpec: coreHttp.OperationSpec = {
  path: "/tags-management",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper:
        Mappers.PathsQovxsvTagsManagementPostResponses200ContentApplicationJsonSchema,
    },
  },
  requestBody: Parameters.body15,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept, Parameters.contentType],
  mediaType: "json",
  serializer,
};
const getTagCountsOperationSpec: coreHttp.OperationSpec = {
  path: "/tags-management/counts",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper:
        Mappers.PathsSnksa5TagsManagementCountsGetResponses200ContentApplicationJsonSchema,
    },
  },
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer,
};
const updateAccountTagOperationSpec: coreHttp.OperationSpec = {
  path: "/tags-management/{tag}",
  httpMethod: "PATCH",
  responses: {
    200: {
      bodyMapper:
        Mappers.PathsVpki7FTagsManagementTagPatchResponses200ContentApplicationJsonSchema,
    },
  },
  requestBody: Parameters.body16,
  urlParameters: [Parameters.$host, Parameters.tag],
  headerParameters: [Parameters.accept, Parameters.contentType],
  mediaType: "json",
  serializer,
};
const deleteAccountTagOperationSpec: coreHttp.OperationSpec = {
  path: "/tags-management/{tag}",
  httpMethod: "DELETE",
  responses: { 204: {} },
  urlParameters: [Parameters.$host, Parameters.tag],
  serializer,
};
const getAllPeopleOperationSpec: coreHttp.OperationSpec = {
  path: "/people/all",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper:
        Mappers.PathsIn61X1PeopleAllGetResponses200ContentApplicationJsonSchema,
    },
  },
  queryParameters: [
    Parameters.attributes,
    Parameters.sort,
    Parameters.limit1,
    Parameters.offset1,
    Parameters.ignoreAnonymous,
    Parameters.order4,
  ],
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer,
};
const unsubscribePersonOperationSpec: coreHttp.OperationSpec = {
  path: "/people/unsubscribe",
  httpMethod: "POST",
  responses: { 204: {} },
  queryParameters: [Parameters.email, Parameters.userId, Parameters.id2],
  urlParameters: [Parameters.$host],
  serializer,
};
const generateContentSuggestionsOperationSpec: coreHttp.OperationSpec = {
  path: "/ai-writer",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper:
        Mappers.Paths18I7KqlAiWriterPostResponses200ContentApplicationJsonSchema,
    },
  },
  requestBody: Parameters.body17,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept, Parameters.contentType],
  mediaType: "json",
  serializer,
};
const generateContentSuggestionsPublicOperationSpec: coreHttp.OperationSpec = {
  path: "/ai-writer/public",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper:
        Mappers.Paths1Vqj9J9AiWriterPublicPostResponses200ContentApplicationJsonSchema,
    },
  },
  requestBody: Parameters.body17,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept, Parameters.contentType],
  mediaType: "json",
  serializer,
};
const getFavoritedContentSuggestionsOperationSpec: coreHttp.OperationSpec = {
  path: "/ai-writer/favorites",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper:
        Mappers.Paths1FongjsAiWriterFavoritesGetResponses200ContentApplicationJsonSchema,
    },
  },
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer,
};
const favoriteContentSuggestionsOperationSpec: coreHttp.OperationSpec = {
  path: "/ai-writer/{id}/favorite",
  httpMethod: "POST",
  responses: { 200: {} },
  urlParameters: [Parameters.$host, Parameters.id3],
  serializer,
};
const getBroadcastsOperationSpec: coreHttp.OperationSpec = {
  path: "/broadcasts",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper:
        Mappers.PathsEq6JoaBroadcastsGetResponses200ContentApplicationJsonSchema,
    },
  },
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer,
};
const createBroadcastOperationSpec: coreHttp.OperationSpec = {
  path: "/broadcasts",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper:
        Mappers.Paths148Z6QBroadcastsPostResponses200ContentApplicationJsonSchema,
    },
  },
  requestBody: Parameters.body18,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept, Parameters.contentType],
  mediaType: "json",
  serializer,
};
const editBroadcastOperationSpec: coreHttp.OperationSpec = {
  path: "/broadcasts/{id}",
  httpMethod: "PATCH",
  responses: {
    200: {
      bodyMapper:
        Mappers.Paths1Nxrm9ZBroadcastsIdPatchResponses200ContentApplicationJsonSchema,
    },
  },
  requestBody: Parameters.body19,
  urlParameters: [Parameters.$host, Parameters.id],
  headerParameters: [Parameters.accept, Parameters.contentType],
  mediaType: "json",
  serializer,
};
const deleteBroadcastOperationSpec: coreHttp.OperationSpec = {
  path: "/broadcasts/{id}",
  httpMethod: "DELETE",
  responses: { 204: {} },
  urlParameters: [Parameters.$host, Parameters.id],
  serializer,
};
const sendBroadcastOperationSpec: coreHttp.OperationSpec = {
  path: "/broadcasts/{id}/send",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper:
        Mappers.Paths189N016BroadcastsIdSendPostResponses200ContentApplicationJsonSchema,
    },
  },
  urlParameters: [Parameters.$host, Parameters.id],
  headerParameters: [Parameters.accept],
  serializer,
};
const cancelBroadcastOperationSpec: coreHttp.OperationSpec = {
  path: "/broadcasts/{id}/cancel",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper:
        Mappers.Paths6Sw4P7BroadcastsIdCancelPostResponses200ContentApplicationJsonSchema,
    },
  },
  urlParameters: [Parameters.$host, Parameters.id],
  headerParameters: [Parameters.accept],
  serializer,
};
const updateBroadcastStatusOperationSpec: coreHttp.OperationSpec = {
  path: "/broadcasts/{id}/status",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper:
        Mappers.PathsRinvztBroadcastsIdStatusPostResponses200ContentApplicationJsonSchema,
    },
  },
  urlParameters: [Parameters.$host, Parameters.id],
  headerParameters: [Parameters.accept],
  serializer,
};
const getBroadcastEnteredCountOperationSpec: coreHttp.OperationSpec = {
  path: "/broadcasts/{id}/entered",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper:
        Mappers.PathsQjciwlBroadcastsIdEnteredGetResponses200ContentApplicationJsonSchema,
    },
  },
  urlParameters: [Parameters.$host, Parameters.id],
  headerParameters: [Parameters.accept],
  serializer,
};
const getCommunicationCategoriesOperationSpec: coreHttp.OperationSpec = {
  path: "/settings/communication-categories",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper:
        Mappers.Paths7Kf2KySettingsCommunicationCategoriesGetResponses200ContentApplicationJsonSchema,
    },
  },
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer,
};
const createCommunicationCategoryOperationSpec: coreHttp.OperationSpec = {
  path: "/settings/communication-categories",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper:
        Mappers.PathsZb8RjpSettingsCommunicationCategoriesPostResponses200ContentApplicationJsonSchema,
    },
  },
  requestBody: Parameters.body20,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept, Parameters.contentType],
  mediaType: "json",
  serializer,
};
const editCommunicationCategoryOperationSpec: coreHttp.OperationSpec = {
  path: "/settings/communication-categories/{id}",
  httpMethod: "PATCH",
  responses: {
    200: {
      bodyMapper:
        Mappers.Paths17Coml5SettingsCommunicationCategoriesIdPatchResponses200ContentApplicationJsonSchema,
    },
  },
  requestBody: Parameters.body21,
  urlParameters: [Parameters.$host, Parameters.id],
  headerParameters: [Parameters.accept, Parameters.contentType],
  mediaType: "json",
  serializer,
};
const getCommunicationPreferencesDataOperationSpec: coreHttp.OperationSpec = {
  path: "/communication-preferences",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper:
        Mappers.Paths7A3UwnCommunicationPreferencesGetResponses200ContentApplicationJsonSchema,
    },
  },
  queryParameters: [Parameters.token],
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer,
};
const saveCommunicationPreferencesOperationSpec: coreHttp.OperationSpec = {
  path: "/communication-preferences",
  httpMethod: "POST",
  responses: { 204: {} },
  requestBody: Parameters.body22,
  queryParameters: [Parameters.token],
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType],
  mediaType: "json",
  serializer,
};
const unsubscribeFromAllCommunicationsOperationSpec: coreHttp.OperationSpec = {
  path: "/communication-preferences/all",
  httpMethod: "POST",
  responses: { 204: {} },
  queryParameters: [Parameters.token],
  urlParameters: [Parameters.$host],
  serializer,
};
const getCommunicationPreferencesPagePreviewOperationSpec: coreHttp.OperationSpec = {
  path: "/communication-preferences/preview/{mode}",
  httpMethod: "GET",
  responses: { 302: {} },
  urlParameters: [Parameters.$host, Parameters.mode],
  serializer,
};
const getEmailVerificationsOperationSpec: coreHttp.OperationSpec = {
  path: "/settings/email/addresses",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper:
        Mappers.PathsVn8UerSettingsEmailAddressesGetResponses200ContentApplicationJsonSchema,
    },
  },
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer,
};
const createEmailVerificationOperationSpec: coreHttp.OperationSpec = {
  path: "/settings/email/addresses",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper:
        Mappers.Paths1Ehqlq6SettingsEmailAddressesPostResponses200ContentApplicationJsonSchema,
    },
  },
  requestBody: Parameters.body23,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept, Parameters.contentType],
  mediaType: "json",
  serializer,
};
const deleteEmailVerificationOperationSpec: coreHttp.OperationSpec = {
  path: "/settings/email/addresses/{email}",
  httpMethod: "DELETE",
  responses: { 204: {} },
  urlParameters: [Parameters.$host, Parameters.email1],
  serializer,
};
const getListEventSchemaMetricOperationSpec: coreHttp.OperationSpec = {
  path: "/event-schemas/metrics",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper:
        Mappers.Paths1Ytfn4OEventSchemasMetricsGetResponses200ContentApplicationJsonSchema,
    },
  },
  queryParameters: [Parameters.ids],
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer,
};
const getEventSchemaMetricOperationSpec: coreHttp.OperationSpec = {
  path: "/event-schemas/metric",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper:
        Mappers.PathsXkjs0WEventSchemasMetricGetResponses200ContentApplicationJsonSchema,
    },
  },
  queryParameters: [Parameters.eventSchemaId],
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer,
};
const getListEventSchemaOperationSpec: coreHttp.OperationSpec = {
  path: "/event-schemas",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper:
        Mappers.Paths1Iie06EventSchemasGetResponses200ContentApplicationJsonSchema,
    },
  },
  queryParameters: [Parameters.offset, Parameters.limit],
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer,
};
const createEventSchemaOperationSpec: coreHttp.OperationSpec = {
  path: "/event-schemas",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper:
        Mappers.PathsTatrw3EventSchemasPostResponses200ContentApplicationJsonSchema,
    },
  },
  requestBody: Parameters.body24,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept, Parameters.contentType],
  mediaType: "json",
  serializer,
};
const editEventSchemaPropertyOperationSpec: coreHttp.OperationSpec = {
  path: "/event-schemas/property/{id}",
  httpMethod: "PUT",
  responses: {
    200: {
      bodyMapper:
        Mappers.Paths14Yt020EventSchemasPropertyIdPutResponses200ContentApplicationJsonSchema,
    },
  },
  requestBody: Parameters.body25,
  urlParameters: [Parameters.$host, Parameters.id],
  headerParameters: [Parameters.accept, Parameters.contentType],
  mediaType: "json",
  serializer,
};
const deleteEventSchemaPropertyOperationSpec: coreHttp.OperationSpec = {
  path: "/event-schemas/property/{id}",
  httpMethod: "DELETE",
  responses: {
    200: {
      bodyMapper:
        Mappers.Paths1Fnr0Q8EventSchemasPropertyIdDeleteResponses200ContentApplicationJsonSchema,
    },
  },
  urlParameters: [Parameters.$host, Parameters.id],
  headerParameters: [Parameters.accept],
  serializer,
};
const createEventPropertyOperationSpec: coreHttp.OperationSpec = {
  path: "/event-schemas/property",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper:
        Mappers.PathsLxuiteEventSchemasPropertyPostResponses200ContentApplicationJsonSchema,
    },
  },
  requestBody: Parameters.body25,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept, Parameters.contentType],
  mediaType: "json",
  serializer,
};
const getEndUserEventOperationSpec: coreHttp.OperationSpec = {
  path: "/event-schemas/{eventSchemaId}/end-user-events",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper:
        Mappers.Paths11Sw186EventSchemasEventschemaidEndUserEventsGetResponses200ContentApplicationJsonSchema,
    },
  },
  queryParameters: [Parameters.offset, Parameters.limit],
  urlParameters: [Parameters.$host, Parameters.eventSchemaId1],
  headerParameters: [Parameters.accept],
  serializer,
};
const deleteEventSchemaOperationSpec: coreHttp.OperationSpec = {
  path: "/event-schemas/{id}",
  httpMethod: "DELETE",
  responses: { 204: {} },
  urlParameters: [Parameters.$host, Parameters.id],
  serializer,
};
const updateEventSchemaOperationSpec: coreHttp.OperationSpec = {
  path: "/event-schemas/{id}",
  httpMethod: "PUT",
  responses: {
    200: {
      bodyMapper:
        Mappers.Paths1SrtqjlEventSchemasIdPutResponses200ContentApplicationJsonSchema,
    },
  },
  requestBody: Parameters.body24,
  urlParameters: [Parameters.$host, Parameters.id],
  headerParameters: [Parameters.accept, Parameters.contentType],
  mediaType: "json",
  serializer,
};
const getEventSchemaOperationSpec: coreHttp.OperationSpec = {
  path: "/event-schemas/{eventSchemaId}",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper:
        Mappers.Paths17LvrntEventSchemasEventschemaidGetResponses200ContentApplicationJsonSchema,
    },
  },
  urlParameters: [Parameters.$host, Parameters.eventSchemaId1],
  headerParameters: [Parameters.accept],
  serializer,
};
const getFormOperationSpec: coreHttp.OperationSpec = {
  path: "/forms/{id}",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper:
        Mappers.PathsK1JqbfFormsIdGetResponses200ContentApplicationJsonSchema,
    },
  },
  urlParameters: [Parameters.$host, Parameters.id3],
  headerParameters: [Parameters.accept],
  serializer,
};
const updateFormOperationSpec: coreHttp.OperationSpec = {
  path: "/forms/{id}",
  httpMethod: "PUT",
  responses: {
    200: {
      bodyMapper:
        Mappers.PathsYjrkutFormsIdPutResponses200ContentApplicationJsonSchema,
    },
  },
  requestBody: Parameters.body26,
  urlParameters: [Parameters.$host, Parameters.id3],
  headerParameters: [Parameters.accept, Parameters.contentType],
  mediaType: "json",
  serializer,
};
const archiveFormOperationSpec: coreHttp.OperationSpec = {
  path: "/forms/{id}",
  httpMethod: "DELETE",
  responses: {
    200: {
      bodyMapper:
        Mappers.Paths1Or4EltFormsIdDeleteResponses200ContentApplicationJsonSchema,
    },
  },
  urlParameters: [Parameters.$host, Parameters.id3],
  headerParameters: [Parameters.accept],
  serializer,
};
const getFormSharedOperationSpec: coreHttp.OperationSpec = {
  path: "/forms/{id}/shared",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper:
        Mappers.PathsFnybiwFormsIdSharedGetResponses200ContentApplicationJsonSchema,
    },
  },
  urlParameters: [Parameters.$host, Parameters.id3],
  headerParameters: [Parameters.accept],
  serializer,
};
const getFormsOperationSpec: coreHttp.OperationSpec = {
  path: "/forms",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper:
        Mappers.PathsH03OvyFormsGetResponses200ContentApplicationJsonSchema,
    },
  },
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer,
};
const createFormOperationSpec: coreHttp.OperationSpec = {
  path: "/forms",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper:
        Mappers.PathsEbkoi1FormsPostResponses200ContentApplicationJsonSchema,
    },
  },
  requestBody: Parameters.body26,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept, Parameters.contentType],
  mediaType: "json",
  serializer,
};
const submitFormOperationSpec: coreHttp.OperationSpec = {
  path: "/forms/{id}/submission",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper:
        Mappers.Paths1Spl9TcFormsIdSubmissionPostResponses200ContentApplicationJsonSchema,
    },
  },
  requestBody: Parameters.body27,
  urlParameters: [Parameters.$host, Parameters.id3],
  headerParameters: [Parameters.accept, Parameters.contentType],
  mediaType: "json",
  serializer,
};
const plainHtmlSubmissionOperationSpec: coreHttp.OperationSpec = {
  path: "/forms/{id}/submission/plain",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper:
        Mappers.Paths11Ffj3RFormsIdSubmissionPlainPostResponses200ContentApplicationJsonSchema,
    },
    302: {},
  },
  urlParameters: [Parameters.$host, Parameters.id3],
  headerParameters: [Parameters.accept],
  serializer,
};
const duplicateFormOperationSpec: coreHttp.OperationSpec = {
  path: "/forms/{id}/duplicate",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper:
        Mappers.PathsH1Oy21FormsIdDuplicatePostResponses200ContentApplicationJsonSchema,
    },
  },
  urlParameters: [Parameters.$host, Parameters.id3],
  headerParameters: [Parameters.accept],
  serializer,
};
const resetOrderOperationSpec: coreHttp.OperationSpec = {
  path: "/forms/{id}/reset-order",
  httpMethod: "PUT",
  responses: {
    200: {
      bodyMapper:
        Mappers.Paths1Y3M2N2FormsIdResetOrderPutResponses200ContentApplicationJsonSchema,
    },
  },
  urlParameters: [Parameters.$host, Parameters.id3],
  headerParameters: [Parameters.accept],
  serializer,
};
const updateFormDraftOperationSpec: coreHttp.OperationSpec = {
  path: "/forms/{id}/draft",
  httpMethod: "PUT",
  responses: {
    200: {
      bodyMapper:
        Mappers.Paths1Ylu4AdFormsIdDraftPutResponses200ContentApplicationJsonSchema,
    },
  },
  requestBody: Parameters.body26,
  urlParameters: [Parameters.$host, Parameters.id3],
  headerParameters: [Parameters.accept, Parameters.contentType],
  mediaType: "json",
  serializer,
};
const createFormObjectOperationSpec: coreHttp.OperationSpec = {
  path: "/forms/form-object",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper:
        Mappers.Paths17Tbz5UFormsFormObjectPostResponses200ContentApplicationJsonSchema,
    },
  },
  requestBody: Parameters.body28,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept, Parameters.contentType],
  mediaType: "json",
  serializer,
};
const updateFormObjectOperationSpec: coreHttp.OperationSpec = {
  path: "/forms/form-object/{id}",
  httpMethod: "PUT",
  responses: {
    200: {
      bodyMapper:
        Mappers.Paths1Lsex58FormsFormObjectIdPutResponses200ContentApplicationJsonSchema,
    },
  },
  requestBody: Parameters.body28,
  urlParameters: [Parameters.$host, Parameters.id3],
  headerParameters: [Parameters.accept, Parameters.contentType],
  mediaType: "json",
  serializer,
};
const archiveFormObjectOperationSpec: coreHttp.OperationSpec = {
  path: "/forms/form-object/{id}",
  httpMethod: "DELETE",
  responses: {
    200: {
      bodyMapper:
        Mappers.PathsV04CouFormsFormObjectIdDeleteResponses200ContentApplicationJsonSchema,
    },
  },
  urlParameters: [Parameters.$host, Parameters.id3],
  headerParameters: [Parameters.accept],
  serializer,
};
const updateDraftFormObjectOperationSpec: coreHttp.OperationSpec = {
  path: "/forms/form-object/{id}/draft",
  httpMethod: "PUT",
  responses: {
    200: {
      bodyMapper:
        Mappers.Paths5G35VrFormsFormObjectIdDraftPutResponses200ContentApplicationJsonSchema,
    },
  },
  requestBody: Parameters.body28,
  urlParameters: [Parameters.$host, Parameters.id3],
  headerParameters: [Parameters.accept, Parameters.contentType],
  mediaType: "json",
  serializer,
};
const getFormSubmissionsOperationSpec: coreHttp.OperationSpec = {
  path: "/forms/{id}/submissions",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper:
        Mappers.PathsFvkyr8FormsIdSubmissionsGetResponses200ContentApplicationJsonSchema,
    },
  },
  queryParameters: [Parameters.offset, Parameters.limit],
  urlParameters: [Parameters.$host, Parameters.id3],
  headerParameters: [Parameters.accept],
  serializer,
};
const publishDraftFormOperationSpec: coreHttp.OperationSpec = {
  path: "/forms/{id}/draft/publish",
  httpMethod: "PUT",
  responses: {
    200: {
      bodyMapper:
        Mappers.Paths1HhuezpFormsIdDraftPublishPutResponses200ContentApplicationJsonSchema,
    },
  },
  urlParameters: [Parameters.$host, Parameters.id3],
  headerParameters: [Parameters.accept],
  serializer,
};
const cancelDraftFormOperationSpec: coreHttp.OperationSpec = {
  path: "/forms/{id}/draft/cancel",
  httpMethod: "PUT",
  responses: {
    200: {
      bodyMapper:
        Mappers.Paths14KddvwFormsIdDraftCancelPutResponses200ContentApplicationJsonSchema,
    },
  },
  urlParameters: [Parameters.$host, Parameters.id3],
  headerParameters: [Parameters.accept],
  serializer,
};
const createDecorationOperationSpec: coreHttp.OperationSpec = {
  path: "/decorations",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper:
        Mappers.Paths1Vmkpn7DecorationsPostResponses200ContentApplicationJsonSchema,
    },
  },
  requestBody: Parameters.body29,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept, Parameters.contentType],
  mediaType: "json",
  serializer,
};
const updateDecorationOperationSpec: coreHttp.OperationSpec = {
  path: "/decorations/{decorationId}",
  httpMethod: "PATCH",
  responses: {
    200: {
      bodyMapper:
        Mappers.PathsHufrllDecorationsDecorationidPatchResponses200ContentApplicationJsonSchema,
    },
  },
  requestBody: Parameters.body29,
  urlParameters: [Parameters.$host, Parameters.decorationId],
  headerParameters: [Parameters.accept, Parameters.contentType],
  mediaType: "json",
  serializer,
};
const deleteDecorationOperationSpec: coreHttp.OperationSpec = {
  path: "/decorations/{decorationId}",
  httpMethod: "DELETE",
  responses: { 204: {} },
  urlParameters: [Parameters.$host, Parameters.decorationId],
  serializer,
};
const deleteSegmentOperationSpec: coreHttp.OperationSpec = {
  path: "/segments/{id}",
  httpMethod: "DELETE",
  responses: { 204: {} },
  urlParameters: [Parameters.$host, Parameters.id],
  serializer,
};
const modifySegmentOperationSpec: coreHttp.OperationSpec = {
  path: "/segments/{id}",
  httpMethod: "PATCH",
  responses: {
    200: {
      bodyMapper:
        Mappers.Paths1KmgxujSegmentsIdPatchResponses200ContentApplicationJsonSchema,
    },
  },
  requestBody: Parameters.body30,
  urlParameters: [Parameters.$host, Parameters.id],
  headerParameters: [Parameters.accept, Parameters.contentType],
  mediaType: "json",
  serializer,
};
const getSyncHealthOperationSpec: coreHttp.OperationSpec = {
  path: "/sync/status/{serviceId}",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper:
        Mappers.Paths16DnuduSyncStatusServiceidGetResponses200ContentApplicationJsonSchema,
    },
  },
  urlParameters: [Parameters.$host, Parameters.serviceId],
  headerParameters: [Parameters.accept],
  serializer,
};
const setupSyncOperationSpec: coreHttp.OperationSpec = {
  path: "/sync/setup/{serviceId}",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper:
        Mappers.PathsCnkspaSyncSetupServiceidPostResponses200ContentApplicationJsonSchema,
    },
  },
  urlParameters: [Parameters.$host, Parameters.serviceId],
  headerParameters: [Parameters.accept],
  serializer,
};
const teardownSyncOperationSpec: coreHttp.OperationSpec = {
  path: "/sync/teardown/{serviceId}",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper:
        Mappers.Paths18BftvjSyncTeardownServiceidPostResponses200ContentApplicationJsonSchema,
    },
  },
  urlParameters: [Parameters.$host, Parameters.serviceId],
  headerParameters: [Parameters.accept],
  serializer,
};
const getTeamMembersOperationSpec: coreHttp.OperationSpec = {
  path: "/team-members",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper:
        Mappers.PathsH2Wj9HTeamMembersGetResponses200ContentApplicationJsonSchema,
    },
  },
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer,
};
const createTeamMemberOperationSpec: coreHttp.OperationSpec = {
  path: "/team-members",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper:
        Mappers.Paths1P98FvkTeamMembersPostResponses200ContentApplicationJsonSchema,
    },
  },
  requestBody: Parameters.body31,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept, Parameters.contentType],
  mediaType: "json",
  serializer,
};
const updateTeamMemberOperationSpec: coreHttp.OperationSpec = {
  path: "/team-members/{id}",
  httpMethod: "PATCH",
  responses: {
    200: {
      bodyMapper:
        Mappers.Paths1809Np1TeamMembersIdPatchResponses200ContentApplicationJsonSchema,
    },
  },
  requestBody: Parameters.body32,
  urlParameters: [Parameters.$host, Parameters.id1],
  headerParameters: [Parameters.accept, Parameters.contentType],
  mediaType: "json",
  serializer,
};
const deleteTeamMemberOperationSpec: coreHttp.OperationSpec = {
  path: "/team-members/{id}",
  httpMethod: "DELETE",
  responses: { 204: {} },
  urlParameters: [Parameters.$host, Parameters.id],
  serializer,
};
const getTeamMemberAccountsOperationSpec: coreHttp.OperationSpec = {
  path: "/team-members/accounts",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper:
        Mappers.Paths1XngsapTeamMembersAccountsGetResponses200ContentApplicationJsonSchema,
    },
  },
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer,
};
const createAccountForTeamMemberOperationSpec: coreHttp.OperationSpec = {
  path: "/team-members/accounts",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper:
        Mappers.Paths13Rl502TeamMembersAccountsPostResponses200ContentApplicationJsonSchema,
    },
  },
  requestBody: Parameters.body33,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept, Parameters.contentType],
  mediaType: "json",
  serializer,
};
